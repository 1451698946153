import { Component } from "@angular/core";
import { FormsModule  } from "@angular/forms";
import { DropDownOption } from "@app/core/models/dropDownOption";
import { ICellRendererAngularComp } from 'ag-grid-angular';


@Component({
  selector: "app-dropdown-renderer",
  templateUrl: "./dropDownRenderer.component.html",
  styleUrls: ["./dropDownRenderer.component.css"]
})
// template: `
// <button type="button" *ngIf="unitLocked" (click)="onClick($event)"><i class="fa fa-lock" aria-hidden="true"></i></button>
// `
export class DropDownRendererComponent implements ICellRendererAngularComp {

  public selectedValue: number; 
  public options: DropDownOption[]; 
  public params: any;
  public editable:boolean = true;

  agInit(params: any): void {
    this.params = params;
    this.selectedValue = params.value; 
    this.options = params.options; 
    if(params.disabled)
    this.editable = false;
  }

  getValue(): any { 
    return this.selectedValue; 
  } 

  isPopup(): boolean { 
    return true; 
  } 

  refresh(_params?: any): boolean {
    return true;
    
  }
  onChange(newValue) {
    this.selectedValue = newValue;
    this.params.node.data[this.params.colDef.field] = newValue;
    this.params.node.setData(this.params.node.data);
  }
 
}