import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePassword } from "@app/authentication/change-password/models/change-password";
import { passwordValidator, MustMatch } from '@app/shared/validators';
import { ChangePasswordService } from '@app/authentication/change-password/services/change-password.service';

@Component({
  selector: 'password-change-dialog',
  templateUrl: './password-change-dialog.component.html',
  styleUrls: ['./password-change-dialog.component.css']
})
export class PasswordChangeDialogComponent implements OnInit {
    form: UntypedFormGroup;
    @Input() 
    changePassword!: ChangePassword;
    public isFailedForm:boolean = false;
    
    constructor(
        private fb: UntypedFormBuilder,
        public activeModal: NgbActiveModal,
        private changePasswordService: ChangePasswordService) {
      
        this.form = this.fb.group({
            oldPassword: ['', Validators.required],
            newPassword: ['', Validators.compose([Validators.required, passwordValidator])],
            confirmPassword: ['', Validators.required]
        }, {
            validator: MustMatch('newPassword', 'confirmPassword')
          });
    }

    ngOnInit() {
        this.form.patchValue(this.changePassword);
    }

    save() {
        if (this.form.invalid) {
            this.isFailedForm = true;
        } else {
            const formValue = this.form.getRawValue();
            const changePassword = Object.assign({}, this.changePassword, formValue);

            this.changePasswordService.changePassword(changePassword).subscribe(
                () => {
                    this.activeModal.close(changePassword);
                },
                (res) => {
                    if(res.status === 400 || res.status === 402) {
                        this.form.get('oldPassword')!.setErrors({'incorrectOldPass': true})
                    }
                }
            );
        }
    }

    close() {
        this.activeModal.dismiss();
    }
}
