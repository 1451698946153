<div class="modal-header">
  <h4 class="modal-title">{{ dialogTitle }}</h4>
  <button type="button" class="close close-margin" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="form">

    <div class="form-row">
      <div class="form-group required col-md-6">
        <label class="control-label">Name:</label>
        <input class="form-control" formControlName="displayName"
               [class.error]="(isFailedForm || form.get('displayName')!.touched) && form.get('displayName')!.invalid" />
        <span class="error-text" *ngIf="(isFailedForm || form.get('displayName')!.touched) && form.get('displayName')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm || form.get('displayName')!.touched) && form.get('displayName')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>

      <div class="form-group required col-md-6">
        <label class="control-label">Username:</label>
        <input class="form-control" formControlName="username"
               [class.error]="(isFailedForm || form.get('username')!.touched) && form.get('username')!.invalid" />
        <span class="error-text" *ngIf="(isFailedForm || form.get('username')!.touched) && form.get('username')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm || form.get('username')!.touched) && form.get('username')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
        <span class="error-text" *ngIf="form.get('username')!.hasError('userExist')">
          Username already exists
        </span>
      </div>

      <div class="form-group required col-md-6">
        <label class="control-label">Email:</label>
        <input class="form-control" formControlName="email"
               [class.error]="(isFailedForm || form.get('email')!.touched) && form.get('email')!.invalid" />
        <span class="error-text" *ngIf="(isFailedForm || form.get('email')!.touched) && form.get('email')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm || form.get('email')!.touched) && form.get('email')!.hasError('email')">
          Please, type valid email
        </span>
      </div>

      <div class="form-group col-md-6">
        <label class="control-label">Phone Number:</label>
        <input class="form-control" formControlName="phoneNumber"
               [textMask]="{ mask: phoneMask }" />
      </div>

      <!-- <div class="form-group col-md-6 required">
        <label class="control-label">Management Company:</label>
        <select class="form-control" formControlName="companyId"
                [class.error]="(isFailedForm || form.get('companyId')!.touched) && form.get('companyId')!.invalid">
          <option value="" disabled>-</option>
          <option *ngFor="let company of companies" [ngValue]="company.companyId">{{company.name}}</option>
        </select>
        <span class="error-text" *ngIf="(isFailedForm || form.get('companyId')!.touched) && form.get('companyId')!.hasError('required')">
          This field is required
        </span>
      </div> -->

      <div class="form-group col-md-6 required">
        <mat-form-field class="full-width">
          <input type="text" placeholder="Management Company:" class="form-control matautocompleteDisable" formControlName="companyId" 
                 matInput [matAutocomplete]="autoCompany" [class.error]="(isFailedForm ||form.get('companyId')!.touched) && form.get('companyId')!.invalid" />
          <mat-autocomplete #autoCompany="matAutocomplete" [displayWith]="displayFnCompany(filteredCompanyList | async)">
            <mat-option *ngFor="let company of filteredCompanyList | async" [value]="company.companyId">
              {{company.name}}
            </mat-option>
          </mat-autocomplete>
          <span class="error-text" *ngIf="(isFailedForm ||form.get('companyId')!.touched) && form.get('companyId')!.hasError('required')">
            This field is required
          </span>
          <span class="error-text" *ngIf="(isFailedForm ||form.get('companyId')!.touched) && form.get('companyId')!.hasError('minlength')">
            Should be from the list
          </span>
        </mat-form-field>
      </div>

      <div class="form-group col-md-6 row" *ngIf="!isAdmin">
        <div class="control-label col-md-4">
          Entity:<i class="question-space1 fa fa-question-circle hover-pointer" [ngbPopover]="entityPopover" triggers="mouseenter:mouseleave" placement="right"></i>
        </div>
        <div class="col-md-9">

        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="isWNC" type="checkbox" formControlName="isWNC" />
            <label class="custom-control-label d-block" for="isWNC">WNC</label>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="isCPP" type="checkbox" formControlName="isCPP" />
            <label class="custom-control-label d-block" for="isCPP">CPP</label>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="isPEF" type="checkbox" formControlName="isPEF" />
            <label class="custom-control-label d-block" for="isPEF">PEF</label>
          </div>
        </div>
        <!-- <label class="control-label">Entity:</label>
        <select class="form-control" formControlName="entity" [class.error]="(isFailedForm || form.get('entity')!.touched) && form.get('entity')!.invalid">
          <option value="" disabled></option>
          <option value="WNC">WNC</option>
          <option value="CPP">CPP</option>
          <option value="PEF">PEF</option>
        </select>
        <span class="error-text" *ngIf="(isFailedForm || form.get('entity')!.touched) && form.get('entity')!.hasError('required')">
          This field is required
        </span> -->

      </div>

      <div class="form-group col-md-6">
        <label class="control-label">Lockout End:</label>
        <div class="input-group">
          <input class="form-control" formControlName="lockoutEnd" name="dp"
                 ngbDatepicker #d="ngbDatepicker"
                 [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}">
                 
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
          </div>
        </div>
      </div>

      <div class="form-group col-md-6">
        <label class="control-label">Active/Inactive:</label>
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" formControlName="isActive"
                 id="isActive">
          <label class="custom-control-label" for="isActive"></label>
        </div>
      </div>

      <div class="form-group col-md-6">
        <label class="control-label">Lockout Enabled:</label>
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" formControlName="lockoutEnabled"
                 id="lockoutEnabled">
          <label class="custom-control-label" for="lockoutEnabled"></label>
        </div>
      </div>
      
      <div class="form-group col-md-6">
        <label class="control-label">Two Factor Enabled:</label>
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" formControlName="twoFactorEnabled"
                 id="twoFactorEnabled">
          <label class="custom-control-label" for="twoFactorEnabled"></label>
        </div>
      </div>

      <div *ngIf="isAdd" class="form-group required col-md-6">
        <label class="control-label">Password:</label>
        <input type="password" class="form-control" formControlName="newPassword"
               autocomplete="new-password"
               [class.error]="(isFailedForm || form.get('newPassword')!.touched) && form.get('newPassword')!.invalid || ''" />
        <span class="error-text" *ngIf="(isFailedForm || form.get('newPassword')!.touched) && form.get('newPassword')!.hasError('passLength')">
          The new password requires 6 characters
        </span>
        <span class="error-text" *ngIf="(isFailedForm || form.get('newPassword')!.touched) && form.get('newPassword')!.hasError('passNotSpecial')">
          The special character is required for the new password
        </span>
        <span class="error-text" *ngIf="(isFailedForm || form.get('newPassword')!.touched) && form.get('newPassword')!.hasError('passNotUpper')">
          The Upper case is required for the new password
        </span>
        <span class="error-text" *ngIf="(isFailedForm || form.get('newPassword')!.touched) && form.get('newPassword')!.hasError('passNotNumber')">
          The number is required for the new password
        </span>
      </div>


      <!-- <div class="form-group col-md-6 row" [hidden]="isAdmin">
        <div class="control-label col-md-4">
          Pages:<i class="question-space1 fa fa-question-circle hover-pointer" [ngbPopover]="entityPopover" triggers="mouseenter:mouseleave" placement="top"></i>
        </div>
        <div class="col-md-9">

        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="isWNC" type="checkbox" formControlName="isWNC" />
            <label class="custom-control-label d-block" for="isWNC">Units</label>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="isCPP" type="checkbox" formControlName="isCPP" />
            <label class="custom-control-label d-block" for="isCPP">Reports</label>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="isPEF" type="checkbox" formControlName="isPEF" />
            <label class="custom-control-label d-block" for="isPEF">PEF</label>
          </div>
        </div>
      </div> -->

      <!-- <div isVisibleForAuthUser [isVisibleForRoles]="['Admin']" class="form-group col-md-6" *ngIf="isAdd">
        <label class="control-label">Is Admin:</label>
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" formControlName="isAdmin" id="isAdmin">
          <label class="custom-control-label" for="isAdmin"></label>
        </div>
      </div> -->
    </div>

  </form>
  <button *ngIf="!isAdd && !isActiveDirectory" class="btn btn-outline-dark" (click)="resetPassword()">
    Reset
    Password
  </button>
</div>

<div class="modal-footer">
  <div *ngIf="!isAdd" class="w-100">
    <div class="row">
      <div class="col-3">Created:</div>
      <div class="col">
        <small>
          {{form.get("createdByName")!.value}},
          {{ form.get("createdDate")!.value | date : "short" }}
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Modified:</div>
      <div class="col">
        <small>
          {{form.get("updatedByName")!.value}},
          {{ form.get("updatedDate")!.value | date : "short" }}
        </small>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  <button type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>
