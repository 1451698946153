import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@app/core/models/user';
import { ChangePassword } from "@app/authentication/change-password/models/change-password";
import { emailValidator } from '@app/shared/validators';
import { DialogsService } from '@app/core/services/dialogs.service';
import {UserService } from '@app/users/user.service'
import { AuthService } from "@app/core/services/auth.service";
import { PasswordChangeDialogComponent } from '../password-change-dialog/password-change-dialog.component';

@Component({
  selector: 'profile-add-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.css']
})
export class ProfileDialogComponent implements OnInit {
    form: UntypedFormGroup;
    @Input() 
    user!: User;
    model: ChangePassword = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      };
    public isFailedForm:boolean = false;
    public isAD:boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private dialogsService: DialogsService,
        private authService: AuthService, 
        private userService: UserService) {
      
        this.form = this.fb.group({
            displayName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
            username: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
            email: ['', Validators.compose([Validators.required, emailValidator])],
            phoneNumber: ['', []],
        });
    }

    ngOnInit() {
        this.form.patchValue(this.user);
        this.isAD = this.authService.getIsActiveDirectory();
    }

    save() {
        if (this.form.invalid) {
            this.isFailedForm = true;
        } else {
        const formValue = this.form.getRawValue();
        const user = Object.assign({}, this.user, formValue);

        this.activeModal.close(user);
        }
    }

    public openChangePassword() {
        this.user.needToChangePassword = false;
        this.openChangePasswordDialog(this.model as ChangePassword).then(()=>{
            //refreshes data so when they hit save on user profile it doesnt override the new password
            this.userService.getMe().subscribe((data) => {
                this.user = data;
                this.user.needToChangePassword = false;
              })
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    private openChangePasswordDialog(model: ChangePassword) {
        const modalRef = this.modalService.open(PasswordChangeDialogComponent,
            {
              backdrop:true,
              beforeDismiss: ():any => {
                if (modalRef.componentInstance.form.dirty) {
                  return this.showConfirmationMessage()
                }
                
                return true;
              }
            });
        modalRef.componentInstance.changePassword = model;
        return modalRef.result;
    }

    showConfirmationMessage() {
        const title = 'Confirmation';
        const message = 'This form will not be saved, are you sure you want to exit this form?';
        return this.dialogsService.confirmation(title, message)
    }
}
