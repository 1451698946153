import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, User } from '@app/core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll(isActive?: boolean) {
      var active = isActive == null ? '' : '/' + (isActive ? "true" : "false");
      return this.http.get<{ list: User[] }>(`${AppConfig.apiEndpoint}/users/1/10000${active}`)
        .pipe(                map(response => {
                    response.list.forEach(user => {
                        if (user.lockoutEnd) user.lockoutEnd = new Date(user.lockoutEnd);
                        if (user.createdDate) user.createdDate = new Date(user.createdDate);
                        if (user.updatedDate) user.updatedDate = new Date(user.updatedDate);
                    });
                    return response;
                })
            );
    }

    getById(id: number) {
        return this.http.get<any[]>(`${AppConfig.apiEndpoint}/users/${id}`);
    }

    register(user: User) {
        return this.http.post(`${AppConfig.apiEndpoint}/users/register`, user);
    }

    add(user: User) {
        return this.http.post(`${AppConfig.apiEndpoint}/users`, user);
    }

    update(user: User) {
        return this.http.put(`${AppConfig.apiEndpoint}/users`, user);
    }

    delete(id: number) {
        return this.http.delete(`${AppConfig.apiEndpoint}/users/${id}`);
    }

    resetPassword(user: User) {
        return this.http.post<User>(`${AppConfig.apiEndpoint}/users/resetPassword`, user);
    }

    changePassword(uid: number, token: string, currentPassword: string, newPassword: string) {
        return this.http.post(`${AppConfig.apiEndpoint}/users/changePassword`, { uid, token, currentPassword, newPassword });
    }

    getMe() {
        return this.http.get<User>(`${AppConfig.apiEndpoint}/users/me`);
    }

    updateMe(user: User) {
        return this.http.put(`${AppConfig.apiEndpoint}/users/me`, user);
    }

    getAllCompanies() {
        return this.http.get<any>(`${AppConfig.apiEndpoint}/companies/all`);
        //return this.http.get<any>(`${AppConfig.apiEndpoint}/companies/getEPIQCompnayDropDownList`);
    }
}
