import { Component, TemplateRef, Output, EventEmitter, OnInit, Input, ViewChild } from '@angular/core';
import { AgGridEvent, GridApi, ColumnApi, ColDef, RowClickedEvent, FilterChangedEvent, GridOptions, RowNode, RowSelectedEvent, GetRowIdFunc, GetRowIdParams, GridReadyEvent, IRowNode } from "ag-grid-community";
import { FormGroup, UntypedFormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DialogsService } from '@app/core/services/dialogs.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationManagementDialogService } from '../notification-management-dialog/notification-management-dialog.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreventiveMaintenanceService } from '@app/preventiveMaintenance/preventiveMaintenance.service';
import { FrequencyType } from '@app/core/models/frequencyType';
import {ButtonRendererComponent} from "@app/core/component/buttonRenderer/buttonRenderer.component"
import { FilesDialogComponent } from '../files-dialog/files-dialog.component';
import { NotificationType } from '@app/core/models/notificationType';
import { RoleManagementService } from '@app/roleManagement/roleManagement.service';
import { Role } from '@app/core/models/role';
import { NotificationSchedule } from '@app/core/models/notificationSchedule';
import { DropDownRendererComponent } from '@app/core/component/dropDownRenderer/dropDownRenderer.component';
import { DropDownOption } from '@app/core/models/dropDownOption';
import { GridActionComponent } from '@app/shared/components/grid-action/grid-action.component';
import { ToastrService } from 'ngx-toastr';
import { NotificationLog } from '@app/core/models/notificationLog';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'notification-management-dialog',
  templateUrl: './notification-management-dialog.component.html',
  styleUrls: ['./notification-management-dialog.component.css'],
  providers: [DatePipe]
})
export class NotificationManagementDialogComponent implements OnInit { 

  @Input()
  selectedPropertyId: number = 0;
  @Output() closeModalEvent: EventEmitter<any> = new EventEmitter();
 
  public eventCloseModal: EventEmitter<any> = new EventEmitter();
  
  private gridApi!: GridApi;
  public newTrackingItemShowDiv: boolean = false;
  public notificationIntervalOptions: DropDownOption[] = [];
  public notificationTypeOptions: DropDownOption[] = [];
  public rolesOptions: DropDownOption[] = [];
  public notificationScheduleList: NotificationSchedule[] = [];
  public notificationLogList:NotificationLog[]=[];
  public repeatedForms: any;
  public params:any;
  public gridOptions: GridOptions;
  public hasFilters: boolean = false;
  
  
  public defaultColDef = {
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true
  }

 
   public frameworkComponents: any;
  

   public notificationSchedulerColumns: ColDef[] = [    
    { headerName: 'Notification Role', field: 'roleId', cellRenderer: 'dropDownEditor', cellRendererParams: (params) =>( { options: this.rolesOptions,selectedValue:params.data } )},
    { headerName: 'Notification Name', field: 'notificationTypeName'},
    { headerName: 'Notification Interval', field: 'notificationIntervalId' , cellRenderer: 'dropDownEditor', cellRendererParams: (params) =>( { options: this.notificationIntervalOptions,selectedValue:params.data, disabled:true } ) },
    { headerName: 'Actions', cellRenderer: 'actions', cellRendererParams: { onClick: this.saveNotificationSchedule.bind(this),  isSaveVisible: true }, width: 75  }
    //{ headerName: '', cellRenderer: 'actions', pinned: 'right', width: 90, suppressSizeToFit: true }
  ];

  public notificationLogColumns: ColDef[] = [   
    { headerName: 'Due Date', field: 'dueDate', cellRenderer: (params) => this.datePipe.transform(params.data.dueDate, 'MM/dd/yyyy') || ''}, 
    { headerName: 'Tracking Item', field: 'trackingItemName'},
    { headerName: 'Notification 1 Sent', field: 'notification1SentTime'},
    { headerName: 'Due Date Sent', field: 'dueDateSentTime'},
    { headerName: 'Notification 2 Sent', field: 'notification2SentTime'},
    { headerName: 'Notification 3 Sent', field: 'notification3SentTime'},
    { headerName: 'Notification 4 Sent', field: 'notification4SentTime'},
   
  ];
  
  public context: any;

  constructor(
    private modalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    public activeModal: NgbActiveModal,
    public ngbModal: NgbModal,
    private notificationManagementService: NotificationManagementDialogService,
    private dialogsService: DialogsService,
    private preventiveMaintenanceService: PreventiveMaintenanceService,
    private roleManagementService: RoleManagementService,
    public filesDialogComponent: FilesDialogComponent,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    ) { 

      this.gridOptions = {
        paginationAutoPageSize: true,
        
      }
      this.frameworkComponents = {
        actions: GridActionComponent,
        dropDownEditor: DropDownRendererComponent, 
      }
      this.context = {
        componentParent: this
      }

  }
 
  
  ngOnInit() {
    this.getNotificationIntervals();
    this.getNotificationTypes();
    this.getRoles();
   
  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => params.data.id;

  public onHeaderChange(){

  }


  //TRACKING ITEM METHODS//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  
  public closeModal(){
    
    this.activeModal.dismiss();
    this.eventCloseModal.emit({});
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  //FREQUENCY TYPE METHODS//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private getNotificationIntervals(){
    this.notificationManagementService.getNotificationIntervals().subscribe(res=>{
      this.notificationIntervalOptions = [];
      res.forEach(e => {
        this.notificationIntervalOptions.push({
          id: e.notificationIntervalId,
          name: e.notificationName
        })
      })
      
    });
  }


  
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private getNotificationTypes(){
    this.notificationManagementService.getNotificationTypes().subscribe(res=>{
      this.notificationTypeOptions = [];
      res.forEach(e => {
        this.notificationTypeOptions.push({
          id: e.notificationTypeId,
          name: e.name
        })
    })
  });
}


  private getRoles() {
    this.notificationManagementService.getNotificationRoles(this.selectedPropertyId)
    .subscribe((data) => {
      this.rolesOptions =  [];
      data.forEach(e => {
        this.rolesOptions.push({
          id: e.id,
          name: e.name
        })
    });
    if(this.selectedPropertyId>0){
      this.getNotificationScheduleForProperty(this.selectedPropertyId);
      this.getNotificationLogsForProperty(this.selectedPropertyId);
    }
    });
  }

  private getNotificationScheduleForProperty(propertyId:number) {
    this.notificationManagementService.getNotificationScheduleForProperty(propertyId)
    .subscribe((data) => {
      this.notificationScheduleList = data;
     
    });
  }

  private getNotificationLogsForProperty(propertyId:number) {
    this.notificationManagementService.getNotificationLogsForProperty(propertyId)
    .subscribe((data) => {
      this.notificationLogList = data;
     
    });
  }


  


  onColumnVisible(e: any) {
    console.log(e);
  }

  onGridReady(params) { 
   
    params.api.sizeColumnsToFit(); 
  } 

  onLogGridReady(params) { 
    this.gridApi = params.api;
    params.api.sizeColumnsToFit(); 
  } 

  public saveNotificationSchedule(event: any){
  
    if(event.rowData.roleId <=0 || event.rowData.frequencyTypeId<=0){
      this.toastr.error('Error saving notification schedule, please select role and frequency', '');
      return;
    }
   
    this.notificationManagementService.saveNotificationSchedule(event.rowData).subscribe(() => {
            this.toastr.success("Notification Schedule Saved Successfully", '');
         
      
    },(error)=>{ this.toastr.error('Unexpected error while saving notification schedule', 'Failed to save notification');
    console.log(error);
  });
  }

  
  


  onFilterChanged(params: FilterChangedEvent) {
    const filterModel = params.api.getFilterModel();
    this.hasFilters = Object.keys(filterModel).length > 0;
  }

  clearFilters() {
    this.gridApi.setFilterModel({});
  }

}