<div class="container">
  <div class="row">
      <div class="col-md-12">
          <div class="text-center">
              <h1>Oops!</h1>
              <h2>404 Not Found</h2>
              <p>
                  Sorry, an error has occured, Requested page not found!
              </p>
              <div class="mt-3">
                  <a [routerLink]="['/']" class="btn btn-primary">
                    Take Me Home 
                  </a>
              </div>
          </div>
      </div>
  </div>
</div>