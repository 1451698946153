<div class=" row">
  <input class="left-pad" type="file" name="" ng2FileSelect [uploader]="fileUploader" style="width: 290px;"/>
  <div *ngIf="url" class="reportFileTitle">
    File:
  </div>
  <a class="filenamePosition" *ngIf="url" (click)="getFileFromServer(url)" href="javascript:void(0)">{{fileName}}</a>

  <!-- <div class="col-md-8">
    <ul *ngFor="let item of fileUploader.queue">
      <li>
        {{item.file.name}}
        comment this out
        <button type="button" (click)="item.upload()" class="btn btn-primary btn-xs">Upload</button>
        <button type="button" (click)="item.remove()" class="btn btn-danger btn-xs">Remove</button>
      </li>
    </ul>
  </div> -->

  <!-- *ngIf="fileUploader.queue < 1" -->
  <!-- comment this
  <div class="progress">
    <div class="progress-bar" role="progressbar" [ngStyle]="{width: uploader.progress}"></div>
  </div> -->
  
  <!-- <ng-content></ng-content> -->
</div>



































<!-- <div class="file-uploader">
  <div class="file-uploader-body" ng2FileDrop [uploader]="fileUploaders">
    <div>
      <i class="fa fa-upload"></i>
    </div>
    <span>Drag file to upload, or</span>
    <label for="file-uploader" class="btn btn-success">Choose file</label>
    <input id="file-uploader" type="file" ng2FileSelect [uploader]="fileUploaders" multiple />
  </div>
  <div class="file-uploader-header">
    Files
  </div>
  <div class="file-uploader-list">
    
    <div class="file-uploader-box" *ngIf="fileUploaders.queue.length">
      <div class="file-uploader-file" *ngFor="let item of fileUploaders.queue">
        <div class="name-file">
          <span>{{ item.file.name }}</span>
        </div>
        <div class="progress-box">
          <div class="progress">
            <div class="progress-bar" role="progressbar" [style.width.%]="item?.progress" aria-valuenow="0"
              aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div class="amount">{{ item.progress }}%</div>
          <button class="close" (click)="item.remove()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
    comment from here
    <div class="file-uploader-box" *ngIf="files.length">
      <div class="file-uploader-file" *ngFor="let item of files">
          <div class="name-file">
              <span>{{ item.name }}</span>
          </div>
          <div>
              <a class="btn btn-light btn-sm" title="Download" [href]="getDownloadURL(item.id)" target="_blank">
                  <i class="fa fa-download"></i>
              </a>
              <button class="btn btn-danger btn-sm" title="Delete" (click)="deleteFile(item.id)">
                  <i class="fa fa-trash"></i>
              </button>
          </div>
      </div>
    </div>
    to here
    <ng-content></ng-content>
  </div>
</div> -->