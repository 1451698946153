import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '@app/core';
import { NotificationSchedule } from '@app/core/models/notificationSchedule';
import { UnitFile } from '@app/core/models/unitFile';

@Injectable({ providedIn: 'root' })
export class UnitFilePortalDialogService {
 
    constructor(private http: HttpClient) { }


    getUnitFiles(unitId:number) {
        return this.http.get<any>(`${AppConfig.apiEndpoint}/unitFile/1/10000/${unitId}`);
      }


      getUnitFileTypes() {
        return this.http.get<any>(`${AppConfig.apiEndpoint}/unitFile/getUnitFileTypes`);
      }

      downloadUnitFile(filePath: string) {
        return this.http.get(`${AppConfig.apiEndpoint}/unitFile/getUnitFile?filePath=${encodeURIComponent(filePath)}`, {responseType:'blob', observe: 'response'});
      }
    
      updateUnitFile(unitFile:UnitFile) {
        return this.http.put(`${AppConfig.apiEndpoint}/unitFile/updateUnitFile`, unitFile);
      }

      removeUnitFile(unitFileId:number) {
        return this.http.delete(`${AppConfig.apiEndpoint}/unitFile/removeUnitFile/${unitFileId}`);
      }
      removeAllUnitFiles(propertyId:number) {
        return this.http.delete(`${AppConfig.apiEndpoint}/unitFile/removeAllUnitFiles/${propertyId}`);
      }

      
      downloadAllUnitFiles(propertyId:number) {
        return this.http.get(`${AppConfig.apiEndpoint}/unitFile/getAllUnitFiles/${propertyId}`, {responseType:'blob', observe: 'response'});
      }


}
