<div class="modal-header">
    <h4 class="modal-title">Change password</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-row">
            <div class="form-group col-md-12">
                <label class="control-label">Current Password:</label>
                <input class="form-control" formControlName="oldPassword" type="password" 
                [class.error]="((isFailedForm ||form.get('oldPassword')!.touched) && form.get('oldPassword')!.invalid) || form.get('oldPassword')!.hasError('incorrectOldPass')" />
                <span class="error-text" *ngIf="(isFailedForm ||form.get('oldPassword')!.touched) && form.get('oldPassword')!.hasError('required')">
                    This field is required</span>
                <span class="error-text" *ngIf="form.get('oldPassword')!.hasError('incorrectOldPass')">
                    Invalid current password</span>
            </div>

            <ng-template #popContent>
                The password should contain:
                <ul>
                    <li>At least 6 characters</li>
                    <li>At least one special character</li>
                    <li>At least one uppercase symbol</li>
                    <li>At least one number</li>
                </ul>
            </ng-template>

            <div class="form-group col-md-12">
                <label class="control-label">New Password: <span [ngbPopover]="popContent" triggers="mouseenter:mouseleave" placement="right"><i class="fa fa-question-circle" aria-hidden="true"></i></span></label>
                <input class="form-control" formControlName="newPassword" type="password"
                [class.error]="(isFailedForm ||form.get('newPassword')!.touched) && form.get('newPassword')!.invalid" />
                <span class="error-text" *ngIf="(isFailedForm ||form.get('newPassword')!.touched) && form.get('newPassword')!.hasError('passLength')">
                    The password should contain at least 6 characters</span>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('newPassword')!.touched) && form.get('newPassword')!.hasError('passNotSpecial')">
                    At least one special character is required for the password</span>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('newPassword')!.touched) && form.get('newPassword')!.hasError('passNotUpper')">
                    At least one uppercase symbol is required for the password</span>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('newPassword')!.touched) && form.get('newPassword')!.hasError('passNotNumber')">
                    At least one number is required for the password</span>
            </div>

            <div class="form-group col-md-12">
                <label class="control-label">Confirm New Password:</label>
                <input class="form-control" formControlName="confirmPassword" type="password"
                [class.error]="(isFailedForm ||form.get('confirmPassword')!.touched) && (form.get('confirmPassword')!.invalid || (form.get('confirmPassword')!.value != form.get('newPassword')!.value))" />
                <span class="error-text" *ngIf="(isFailedForm ||form.get('confirmPassword')!.touched) && form.get('confirmPassword')!.hasError('required')">
                    This field is required</span>
                <span class="error-text"  *ngIf="(isFailedForm || form.get('confirmPassword')!.touched) && (form.get('confirmPassword')!.value != form.get('newPassword')!.value)">
                    New Password & Confirm New Password do not match.</span>
            </div>

        </div>
    </form>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    <button type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>