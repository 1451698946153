import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { NgForm, UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { ChangePasswordService } from "./services/change-password.service";
import { passwordValidator, MustMatch } from "@app/shared/validators";
import { DialogsService } from "@app/core/services/dialogs.service";
import { ChangePasswordWidthToken } from "./models/change-password";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"]
})
export class ChangePasswordComponent implements OnInit {
  
  form: UntypedFormGroup;
  public isFailedForm: boolean = false;
  public changePassword: ChangePasswordWidthToken = {};
  uid: number = NaN;
  token: string = '';
 
  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialogsService: DialogsService,
    private changePasswordService: ChangePasswordService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = this.fb.group({
      newPassword: ['', Validators.compose([Validators.required, passwordValidator])],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.uid = Number(params["uid"]);
      this.token = params["token"];
      if(!this.token) {
        this.showErrorMessage('Bad token!');
      }
    });
  }

  submitForm() {
    if (this.form.invalid) {
      this.isFailedForm = true;
    } else {
      const changePassword = {
        uid: this.uid,
        token: this.token,
        newPassword: this.form.get('newPassword')!.value,
      }
      this.changePasswordService.changePasswordWidthToken(changePassword).subscribe(
        () => {
          this.router.navigate(["/login"]);
        },
        (res) => {
          this.showErrorMessage(res.error.Msg);
        });
    }
  }
  showErrorMessage(message:string) {
    return this.dialogsService.error(message)
  }
}
