import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators,FormControl ,ValidationErrors} from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Company } from '@app/core/models/company';
import { DialogsService } from '@app/core/services/dialogs.service';
import { existValidator } from '@app/shared/validators';
import { AuthService, Credentials } from "@app/core";
//BFS: 1.04 - for searchable drop down you need these 2
import { Observable, zip } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CompaniesService } from '@app/companies/companies.service';


@Component({
  selector: 'app-company-add-dialog',
  templateUrl: './company-add-dialog.component.html',
  styleUrls: ['./company-add-dialog.component.css'],
  providers: [ { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, DatePipe ]
})
export class CompanyAddDialogComponent implements OnInit {
    dialogTitle = '';
    isAdd = true;
    form: UntypedFormGroup;
    @Input() 
    company!: Company;
    companies!: any;
    model: any;
    public states: any;
    //public states = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'];
    public userEntity: string | null | undefined;
    public isFailedForm:boolean = false;
    public entityPopover: string = "At least one of the entity checkboxes need to be checked."
    public notInListPopover: string = "If the company you are looking for is not in this current list, please check the checkbox below."
    public companyDropDownList: any[] = [];
    public filteredCompanyList: Observable<Company[]> | undefined;
    public onSelectCompanyID: number = 0;
    public companyOnCompanySelect: Company[] = [];


    constructor(
        private authService: AuthService,
        private fb: UntypedFormBuilder,
        public activeModal: NgbActiveModal,
        private datePipe: DatePipe,
        private companiesService: CompaniesService,
        private dialogsService: DialogsService) {
      
        this.form = this.fb.group({
            organizationID: ['', []],
            name: ['', Validators.compose([Validators.required, Validators.minLength(2),this.selectionRequireMatch.bind(this)])],
            address: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
            city: ['', Validators.compose([Validators.required,  Validators.minLength(2)])],
            state: ['', Validators.compose([Validators.required])],
            zip: ['', Validators.compose([Validators.required,  Validators.minLength(2)])],
            wncFlag: ['', []],
            cppFlag: ['', []],
            pefFlag: ['', []],
            createdById: ['', []],
            createdByName: ['', []],
            createdDate: [null, []],
            updatedById: ['', []],
            updatedByName: ['', []],
            updatedDate: [null, []],
        });
    }

    ngOnInit() {
        this.form.patchValue(this.company);
        this.isAdd = !(this.company && this.company.id);

        this.dialogTitle = this.isAdd
            ? 'Add Company'
            : 'Update Company';
            this.form.controls['state'].disable();
            if(!this.isAdd) {
                this.companies = this.companies.filter((e:string) => e !== this.company.name);
                if(this.company.organizationID > 0){
                    this.form.controls['name'].disable();
                    this.form.controls['address'].disable();
                    this.form.controls['city'].disable();
                    this.form.controls['state'].disable();
                    this.form.controls['zip'].disable();
                }
            }

            this.form.get('name')!.setValidators(Validators.compose([Validators.required, Validators.minLength(2), this.selectionRequireMatch.bind(this)]));
            this.form.get('name')!.updateValueAndValidity();
            this.getEPIQCompanyDrowDownListAll();
    }

    public getEPIQCompanyDrowDownListAll() {
        this.companiesService.getEPIQCompanyDropDownListAll()
          .subscribe((data) => {
            this.companyDropDownList = data;
            this.filters();
        });
    }
    
    public filters(){
        this.filteredCompanyList = this.form.controls['name'].valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._companyFilter(name) : this.companyDropDownList.slice())
        );
    }
        
    private _companyFilter(name: string): Company[] {
        const filterValue = name.toLowerCase();
        return this.companyDropDownList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    
    public onCompanyChange(event: string) {
        for (let i = 0; i < this.companyDropDownList.length; i++) {
          if(this.companyDropDownList[i].name === event){
            this.onSelectCompanyID = this.companyDropDownList[i].organizationID
            this.getCompanyOnCompanySelect(this.onSelectCompanyID);
          }
        }
    }
    
    public getCompanyOnCompanySelect (id: number) {
        this.companiesService.getCompanyDataOnCompanySelect(id)
          .subscribe((data) => {
            this.companyOnCompanySelect = data;
            if (this.companyOnCompanySelect === null) {
              this.form.controls['wncFlag'].enable();
              this.form.controls['wncFlag'].patchValue(false);
              this.form.controls['cppFlag'].enable();
              this.form.controls['cppFlag'].patchValue(false);
              this.form.controls['pefFlag'].enable();
              this.form.controls['pefFlag'].patchValue(false);
              this.form.controls['name'].enable();
              this.form.controls['name'].patchValue(null);
              this.form.controls['address'].enable();
              this.form.controls['address'].patchValue(null);
              this.form.controls['city'].enable();
              this.form.controls['city'].patchValue(null);
              this.form.controls['state'].disable();
              this.form.controls['state'].patchValue(null);
              this.form.controls['zip'].enable();
              this.form.controls['zip'].patchValue(null);
            } else {
              if(this.form.touched){
                this.populateNewCompanyForm(this.companyOnCompanySelect);
              }
            }
        })
      }
    
    public populateNewCompanyForm (companyObject: object) {
        this.form.patchValue(companyObject);
        this.form.controls['name'].disable();
        this.form.controls['address'].disable();
        this.form.controls['city'].disable();
        this.form.controls['state'].disable();
        this.form.controls['zip'].disable();
    }

    private selectionRequireMatch(control: FormControl): ValidationErrors | null {
      const selection: any = control.value;
      if (selection!=null && selection!="" &&this.companyDropDownList && this.companyDropDownList.indexOf(companyInfo=>companyInfo.name === selection) < 0) {
        return { requireMatch: true };
      }
      return null;
    } 

    save() {
        if (this.form.invalid) {
            this.isFailedForm = true;
        } else {
            const formValue = this.form.getRawValue();
            const company = Object.assign({}, this.company, formValue);
            this.activeModal.close(company);
        }        
    }

    close() {
        this.activeModal.dismiss();
    }
}
