import { Component, TemplateRef, Output, EventEmitter, OnInit, Input, ViewChild } from '@angular/core';
import { AgGridEvent, GridApi, ColumnApi, ColDef, RowClickedEvent, FilterChangedEvent, GridOptions, RowNode, RowSelectedEvent, GetRowIdFunc, GetRowIdParams, GridReadyEvent, IRowNode } from "ag-grid-community";
import { FormGroup, UntypedFormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DialogsService } from '@app/core/services/dialogs.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationManagementDialogService } from '../notification-management-dialog/notification-management-dialog.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreventiveMaintenanceService } from '@app/preventiveMaintenance/preventiveMaintenance.service';
import { FrequencyType } from '@app/core/models/frequencyType';
import {ButtonRendererComponent} from "@app/core/component/buttonRenderer/buttonRenderer.component"
import { FilesDialogComponent } from '../files-dialog/files-dialog.component';
import { NotificationType } from '@app/core/models/notificationType';
import { RoleManagementService } from '@app/roleManagement/roleManagement.service';
import { Role } from '@app/core/models/role';
import { NotificationSchedule } from '@app/core/models/notificationSchedule';
import { DropDownRendererComponent } from '@app/core/component/dropDownRenderer/dropDownRenderer.component';
import { DropDownOption } from '@app/core/models/dropDownOption';
import { GridActionComponent } from '@app/shared/components/grid-action/grid-action.component';
import { ToastrService } from 'ngx-toastr';
import { NotificationLog } from '@app/core/models/notificationLog';
import { DatePipe } from '@angular/common';
import { RentRollDialogService } from './rentRoll-dialog.service';
import { RentRollUploadDialogComponent } from '../rentRoll-upload-dialog/rentRoll-upload-dialog.component';
import { RentRoll } from '@app/core/models/RentRoll';



@Component({
  selector: 'rentRoll-dialog',
  templateUrl: './rentRoll-dialog.component.html',
  styleUrls: ['./rentRoll-dialog.component.css'],
  providers: [DatePipe]
})
export class RentRollDialogComponent implements OnInit { 

  @Input()
  propertyId!: number;
  @Output() closeModalEvent: EventEmitter<any> = new EventEmitter();
 
  public eventCloseModal: EventEmitter<any> = new EventEmitter();
  
  private gridApi!: GridApi;
  public newTrackingItemShowDiv: boolean = false;
  public rolesOptions: DropDownOption[] = [];
  public rentRollList: RentRoll[] = [];
  public notificationLogList:NotificationLog[]=[];
  public repeatedForms: any;
  public params:any;
  public gridOptions: GridOptions;
  public hasFilters: boolean = false;
 
  
  
  public defaultColDef = {
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true
  }

 
   public frameworkComponents: any;
  

   public rentRollListColumns: ColDef[] = [    
    { headerName: 'File Name', field: 'name'},
    { headerName: 'Uploaded Date', field: 'uploadedOn' },
    { headerName: 'Upload User', field: 'uploadedByName'},
    { headerName: 'Actions', cellRenderer: 'actions', cellRendererParams: { onClick: this.onGridAction.bind(this),  isSaveVisible: false, isDownloadVisible:true , isDeleteVisible:true}, width: 120  }
    //{ headerName: '', cellRenderer: 'actions', pinned: 'right', width: 90, suppressSizeToFit: true }
  ];

 
  
  public context: any;

  constructor(
    private modalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    public activeModal: NgbActiveModal,
    public ngbModal: NgbModal,
    private rentRollService: RentRollDialogService,
    private dialogsService: DialogsService,
    public filesDialogComponent: FilesDialogComponent,
    private toastr: ToastrService,
    
    ) { 

      this.gridOptions = {
        paginationAutoPageSize: true,
        
      }
      this.frameworkComponents = {
        actions: GridActionComponent,
        dropDownEditor: DropDownRendererComponent, 
      }
      this.context = {
        componentParent: this
      }

  }
 
  
  ngOnInit() {
    if(this.propertyId>0 )
    {
    this.getRentRolls(this.propertyId);
    
    }
  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => params.data.id;

  public onHeaderChange(){

  }


  //TRACKING ITEM METHODS//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  
  public closeModal(){
    
    this.activeModal.dismiss();
    this.eventCloseModal.emit({});
  }

  public removeAllUnitFiles()
  {
    const title = 'Remove All Unit Files';
    const message = `Are you sure you want to remove all unit files for this property?`;
    this.dialogsService.confirmation(title, message)
      .then(() => {
        return this.rentRollService.removeAllRentRolls(this.propertyId).subscribe(() => {
          this.getRentRolls(this.propertyId);
        },(err)=>{
          this.showErrorMessage(err.error.msg);
        });
      }, () => { });



    
  }

  public openUploadFileModal()
  {
    const modalRef = this.ngbModal.open(RentRollUploadDialogComponent,
      {
        backdrop : 'static',
        keyboard : false,
        size: 'lg',
        windowClass: 'modal-xl',
        beforeDismiss: (): any => {
         
          return true;
        }
      });

    modalRef.componentInstance.propertyId = this.propertyId;
   
    modalRef.result.then((_data: any) => {
      this.getRentRolls(this.propertyId);
    }, (_reason: any) => {
      this.getRentRolls(this.propertyId);
    });
    return modalRef.result;
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  //FREQUENCY TYPE METHODS//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


 

  private getRentRolls(propertyId:number) {
    this.rentRollList = [];
    this.rentRollService.getRentRolls(propertyId)
    .subscribe((data) => {
      this.rentRollList = data.list;
     
    });
  }



  


  onColumnVisible(e: any) {
    console.log(e);
  }

  onGridReady(params) { 
   
    params.api.sizeColumnsToFit(); 
  } 

  onLogGridReady(params) { 
    this.gridApi = params.api;
    params.api.sizeColumnsToFit(); 
  } 

  public onGridAction(event: any){
  
    switch (event.actionType) {
    
      case 'download': {        
        const rentRoll = event.rowData as RentRoll;
        this.downloadRentRoll(rentRoll);
        break;
      }
      case 'delete': {        
        const rentRoll = event.rowData as RentRoll;
        this.removeRentRoll(rentRoll);
        break;
      }
    }
    
   
 
  }

  
 

  private downloadRentRoll(rentRoll:RentRoll)
  {
   
     this.rentRollService.downloadRentRoll(rentRoll.filePath).subscribe((res: any) => {
       const fileType = res.headers.get('Content-Type')
       const blob = new Blob([res.body], { type: fileType});
       const fileURL = window.URL.createObjectURL(blob);
       const a = document.createElement("a");
       document.body.appendChild(a);
       a.style.cssText = "display:none";
       a.href = fileURL;
       a.setAttribute('download', rentRoll.name);
      
       a.click();
       window.URL.revokeObjectURL(fileURL);
     
     },() => {
      
     });
    
  }


  private removeRentRoll(rentRoll:RentRoll)
  {

    const title = 'Remove Rent Roll File';
    const message = `Are you sure you want to remove Rent Roll file?`;
    this.dialogsService.confirmation(title, message)
      .then(() => {
        return this.rentRollService.removeRentRoll(rentRoll.rentRollFileId).subscribe(() => {
          this.getRentRolls(this.propertyId);
        },(err)=>{
          this.showErrorMessage(err.error.msg);
        });
      }, () => { });
   
  }



  onFilterChanged(params: FilterChangedEvent) {
    const filterModel = params.api.getFilterModel();
    this.hasFilters = Object.keys(filterModel).length > 0;
  }

  clearFilters() {
    this.gridApi.setFilterModel({});
  }

  showErrorMessage(message:string) {
    return this.dialogsService.error(message)
  }

}