<div class="modal-header">
    <h4 class="modal-title">{{ dialogTitle }}</h4>
    <button type="button" class="close close-margin" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-row">
           
    
            <div class="form-group required col-md-12">
              <mat-form-field class="full-width">
                <input type="text" placeholder="Company Name:" aria-label="Company Name:" class="form-control matautocompleteDisable" formControlName="name" matInput [matAutocomplete]="auto"
                       [class.error]="(isFailedForm ||form.get('name')!.touched) && form.get('name')!.invalid" (ngModelChange)="onCompanyChange($event)"/>
                <mat-autocomplete requireSelection #auto="matAutocomplete" > 
                  <mat-option *ngFor="let option of filteredCompanyList | async" [value]="option.name">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('name')!.touched) && form.get('name')!.hasError('required')">
                  This field is required
                </span>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('name')!.touched) && form.get('name')!.hasError('minlength')">
                  Should be from the list
                </span>
                <span class="error-text" *ngIf="form.get('name').errors?.requireMatch">
                  Company name should be from the list
                </span>
              </mat-form-field>
            </div>
            <div class="form-group required col-md-6">
                <label class="control-label">Address:</label>
                <input class="form-control" formControlName="address"   [readonly]="true"
                [class.error]="(isFailedForm ||form.get('address')!.touched) && form.get('address')!.invalid"/>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('address')!.touched) && form.get('address')!.hasError('required')">
                    This field is required</span>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('address')!.touched) && form.get('address')!.hasError('minlength')">
                    Should contain at least 2 characters</span>
            </div>

            <div class="form-group required col-md-6">
                <label class="control-label">State:</label>

                <select class="form-control" formControlName="state" [disabled]="true" 
                    [class.error]="(isFailedForm ||form.get('address')!.touched) && form.get('state')!.invalid">
                    <option value="" disabled>-</option>
                    <option *ngFor="let state of states" [ngValue]="state.stateName">{{state.stateName}}</option>
                </select>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('state')!.touched) && form.get('state')!.hasError('required')">
                    This field is required</span>
            </div>

            <div class="form-group required col-md-6">
                <label class="control-label">City:</label>
                <input class="form-control" formControlName="city"  [readonly]="true" 
                [class.error]="(isFailedForm ||form.get('city')!.touched) && form.get('city')!.invalid"/>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('city')!.touched) && form.get('city')!.hasError('required')">
                    This field is required</span>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('city')!.touched) && form.get('city')!.hasError('minlength')">
                    Should contain at least 2 characters</span>
            </div>
            <div class="form-group required col-md-6">
                <label class="control-label">Zip Code:</label>
                <input class="form-control" formControlName="zip"  [readonly]="true" 
                [class.error]="(isFailedForm ||form.get('zip')!.touched) && form.get('zip')!.invalid"/>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('zip')!.touched) && form.get('zip')!.hasError('required')">
                    This field is required</span>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('zip')!.touched) && form.get('zip')!.hasError('minlength')">
                    Should contain at least 2 characters</span>
            </div>

            <div class="form-group col-md-7 row">
              <div class="control-label col-md-3">
                Entity:<i class="question-space1 fa fa-question-circle hover-pointer" [ngbPopover]="entityPopover" triggers="mouseenter:mouseleave" placement="bottom"></i>
              </div>
              <div class="col-md-9">
      
              </div>
              <div class="form-group col-md-4">
                <div class="custom-checkbox custom-control col-md-12">
                  <input class="custom-control-input" id="wncFlag" type="checkbox" formControlName="wncFlag" />
                  <label class="custom-control-label d-block" for="wncFlag">WNC</label>
                </div>
              </div>
              <div class="form-group col-md-4">
                <div class="custom-checkbox custom-control col-md-12">
                  <input class="custom-control-input" id="cppFlag" type="checkbox" formControlName="cppFlag" />
                  <label class="custom-control-label d-block" for="cppFlag">CPP</label>
                </div>
              </div>
              <div class="form-group col-md-4">
                <div class="custom-checkbox custom-control col-md-12">
                  <input class="custom-control-input" id="pefFlag" type="checkbox" formControlName="pefFlag" />
                  <label class="custom-control-label d-block" for="pefFlag">PEF</label>
                </div>
              </div>
          </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div *ngIf="!isAdd" class="w-100">
        <div class="row">
            <div class="col-3">Created:</div>
            <div class="col"><small>{{form.get("createdByName")!.value}},
                    {{ form.get("createdDate")!.value | date : "short" }}</small></div>
        </div>
        <div class="row">
            <div class="col-3">Modified:</div>
            <div class="col"><small>{{form.get("updatedByName")!.value}},
                    {{ form.get("updatedDate")!.value | date : "short" }}</small></div>
        </div>
    </div>
    <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    <button ngbAutofocus type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>