<div id="wrapper" class="d-flex">
  <div id="sidebar-wrapper" class="bg-light border-right">
    <app-sidebar></app-sidebar>
  </div>
  <div id="page-wrapper" class="d-flex flex-column">
    <app-navbar></app-navbar>
    
    <div class="container-fluid flex-grow-1">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
