import { Component } from "@angular/core";
import { ICellRendererAngularComp } from 'ag-grid-angular';


@Component({
  selector: "app-button-renderer",
  templateUrl: "./buttonRenderer.component.html",
  styleUrls: ["./buttonRenderer.component.css"]
})
// template: `
// <button type="button" *ngIf="unitLocked" (click)="onClick($event)"><i class="fa fa-lock" aria-hidden="true"></i></button>
// `
export class ButtonRendererComponent implements ICellRendererAngularComp {

  params:any;
  label: any;
  unitLocked: any;

  agInit(params: any): void {
    this.params = params;
    this.label = this.params.label || null;
    this.unitLocked = this.params.data.unitLocked || null;
  }

  refresh(_params?: any): boolean {
    return true;
  }

  onClick($event: any) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);
    }
  }
}