import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, Company } from '@app/core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CompaniesService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<{list:Company[]}>(`${AppConfig.apiEndpoint}/companies/1/10000`)
            .pipe(
                map(response => {
                    response.list.forEach(company => {                        
                        if(company.updatedDate ) company.updatedDate = new Date(company.updatedDate);
                    });
                    return response;
                })
            );
    }

    add(company: Company) {
        return this.http.post(`${AppConfig.apiEndpoint}/companies`, company, { observe: 'response' });
    }

    update(company: Company) {
        return this.http.put(`${AppConfig.apiEndpoint}/companies`, company);
    }

    delete(id: number) {
        return this.http.delete(`${AppConfig.apiEndpoint}/companies/${id}`);
    }

    getEPIQCompanies() {
        return this.http.get<any>(`${AppConfig.apiEndpoint}/companies/allEPIQ`);
    }

    getEPIQCompanyDropDownListAll(){
        return this.http.get<any>(`${AppConfig.apiEndpoint}/companies/getEPIQCompnayDropDownList`);
    }

    getCompanyDataOnCompanySelect(id: number) {
        return this.http.get<any>(`${AppConfig.apiEndpoint}/companies/getCompanyDataOnCompanySelect/${id}`);
      }
    
}