import { Component, TemplateRef, Output, EventEmitter, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DialogsService } from '@app/core/services/dialogs.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { AppConfig } from '@app/core';
import { finalize } from 'rxjs';
import { error } from 'console';
//import { ProgressComponent } from '@app/shared/components/progress/progress.component';



@Component({
  selector: 'unitFile-upload-dialog',
  templateUrl: './unitFile-upload-dialog.component.html',
  styleUrls: ['./unitFile-upload-dialog.component.scss'],
  providers: [DatePipe]
})
export class UnitFileUploadDialogComponent implements OnInit { 

  @Input()
  propertyId!: number;
  @Output() closeModalEvent: EventEmitter<any> = new EventEmitter();
 
  public eventCloseModal: EventEmitter<any> = new EventEmitter();
  
  
  public newTrackingItemShowDiv: boolean = false;
  public repeatedForms: any;
  public params:any;
  public isUploadStarted:boolean = false;
 
  public context: any;
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  public files: any[] = [];
  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    public activeModal: NgbActiveModal,
    public ngbModal: NgbModal,
    private dialogsService: DialogsService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private http: HttpClient
   // private progressComponent:ProgressComponent,
    ) { 

      this.context = {
        componentParent: this
      }

  }
 
  
  ngOnInit() {
    
   
  }

 
 

  
  public closeModal(){
    
    this.activeModal.dismiss();
    this.eventCloseModal.emit({});
  }
 


  
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

   /**
   * on file drop handler
   */
   onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      console.log("Upload in progress.");
      return;
    }
    this.files.splice(index, 1);
  }

  public submitFilesForUpload()
  {
    if (this.isUploadStarted) {
      return;
    }
    this.isUploadStarted = true;
    if(this.files.length>0)
    {
      this.uploadFiles(0);
    }
    
  }

  /**
   * Simulate the upload process
   */
  uploadFiles(index: number) {
   
      if (index === this.files.length) {

      
        this.closeModal();
        return;
      } else {

        const formData = new FormData();
        formData.append('propertyId',this.propertyId.toString());
        formData.append('file',this.files[index]);
        const request = new HttpRequest('POST',`${AppConfig.apiEndpoint}/unitFile/upload`,formData,{reportProgress: true});

        this.http.request<any>(request)
        .pipe(
          finalize(()=>{
           
            this.uploadFiles(index + 1);
          })
        )
        .subscribe(
          (event)=>{
            if(event.type=== HttpEventType.UploadProgress){
              this.files[index].progress = Math.round((100*event.loaded)/event.total);
            }
          }, (error)=>{throw error;}
        );
        
    
  }
}

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    
    var startIndex = this.files.length;
    for (const item of files) {
      
     // item.progress = 0;
     if(item.type.toString().toLowerCase() === 'application/pdf')
     {
    
      this.files.push(item);
     }
    }
    this.fileDropEl.nativeElement.value = "";
    
  }
 /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
 formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return "0 Bytes";
  }
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

}