import { Component, OnInit, Inject, Input, Injectable, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter, NgbModal, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Building } from '@app/core/models/building';
import { Property } from '@app/core/models/property';
import { UserService } from '@app/users/user.service';
import { PropertyService } from '@app/properties/properties.service';
import { dateValidator, dateValidator2 } from '@app/shared/validators';
import { UnitDetailsService } from '@app/unitDetails/unitDetails.service';
import { DialogsService } from '@app/core/services/dialogs.service';
import { AuthService } from "@app/core";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct {
    let result: NgbDateStruct = {year:0, month:0, day:0};
    if (value) {
      let date = value.split(this.DELIMITER);
      result = {
        day : parseInt(date[1], 10),
        month : parseInt(date[0], 10),
        year : parseInt(date[2], 10)
      };
    }
    return result;
  }

  format(date: NgbDateStruct): string {
    let result: string = '';
    if (date) {
      result = date.month + this.DELIMITER + date.day + this.DELIMITER + date.year;
    }
    return result;
  }
}

@Component({
  selector: 'app-building-add-dialog',
  templateUrl: './building-add-dialog.component.html',
  styleUrls: ['./building-add-dialog.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
              {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
               DatePipe]
})
export class BuildingAddDialogComponent implements OnInit {
  dialogTitle = '';
  isAdd = true;
  propertyList: Property[];
  form: UntypedFormGroup;
  form2: UntypedFormGroup;
  isAdmin = false;
  @Input()
  building!: Building;
  @Output()
  saveChange = new EventEmitter();
  model: any;
  public isFailedForm: boolean = false;
  public complianceAssgiendTo: any[] = [];
  public companies: any;
  public properties: any;
  public certOfOccupancyPopover: string = 'Certification of Occupancy Date = New Construction | Placed in Service Date = Acquisition'
  public seniorHousingTypes: any[] = [];

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private dialogsService: DialogsService,
    private unitDetailsService: UnitDetailsService,
    public propertyService: PropertyService,
    public userService: UserService) {
    //
    this.form = this.fb.group({
      propertyID: ['', []],
      buildingNumber: ['', Validators.required],
      lihtcProject: ['', []],
      bin: ['', []],
      certOfOccupancy: [null, dateValidator2],
      creditingYear:[null, Validators.pattern('[0-9]{4}')],
      seniorHousingTypeID: ['', Validators.required],
      createdDate: ['', []],
      updatedDate: ['', []],
      createdByName: ['', []],
      updatedByName: ['', []],
    });
    this.form2= this.fb.group({
      buildingLocked: [false, []]
    })
    this.propertyList = [];
  }

  ngOnInit() {
    this.getSeniorHousingTypes();
    this.propertyService.getAllComplianceAssignedTo()
    .subscribe((data) => {
      this.complianceAssgiendTo = data;
    });
    if (this.building) {
      this.form.patchValue(this.building);
      this.form2.patchValue(this.building);
    }
    this.isAdd = !(this.building && this.building.buildingID);
    this.dialogTitle = this.isAdd ? 'Add Building' : 'Update Building';
    this.propertyService.getAll().subscribe((res) => {
      this.propertyList = res.list;
    });
    this.isAdmin = this.authService.isAuthUserInRole("Admin");
  }

  public getSeniorHousingTypes() {
    this.unitDetailsService.getSeniorHousingTypes()
    .subscribe((data) => {
      this.seniorHousingTypes = data;
    });

  }

  save() {
    const title = 'Add Building';
    const message = 'Saving will lock this building and you will not be able to edit the information unless unlocked by WNC. Please ensure all of the information is correct before saving. Do you want to proceed?'
    if(this.isAdmin === true){
      if (this.form.invalid) {
        this.isFailedForm = true;
        } else {
        const formvalue = this.form.getRawValue();
        const formvalue2 = this.form2.getRawValue();
        let replaceddata: any = {};
        Object.keys(formvalue).forEach((key: string) => {
          formvalue[key] == null ? replaceddata[key] = '' : replaceddata[key] = formvalue[key]
        })
        Object.keys(formvalue2).forEach((key: string) => {
          formvalue2[key] == null ? replaceddata[key] = replaceddata[key] : replaceddata[key] = formvalue2[key];
        })
        const report = Object.assign({}, this.building, replaceddata);
        
        this.activeModal.close(report);
      }
    } else {
      this.dialogsService.confirmation(title, message)
        .then(() => {
          if (this.form.invalid) {
            this.isFailedForm = true;
            } else {
            const formvalue = this.form.getRawValue();
            const formvalue2 = this.form2.getRawValue();
            let replaceddata: any = {};
            Object.keys(formvalue).forEach((key: string) => {
              formvalue[key] == null ? replaceddata[key] = '' : replaceddata[key] = formvalue[key]
            })
            Object.keys(formvalue2).forEach((key: string) => {
              formvalue2[key] == null ? replaceddata[key] = replaceddata[key] : replaceddata[key] = formvalue2[key];
            })
            const report = Object.assign({}, this.building, replaceddata);
            
            this.activeModal.close(report);
          }
    
        },() => { });
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  lock(){
    this.form2.controls['buildingLocked'].patchValue(true);
  }
  unlock(){
    this.form2.controls['buildingLocked'].patchValue(false);
  }
  public email(){
    var data = {
      name: 'Compliance',
      toAddress: '',
      unit: '',
      building: this.form.controls['bin'].value,
      property: '',
      user: '',
      emailType: 'Building'
    }
    this.propertyList.forEach(property => {
      if (property.id === this.form.controls['propertyID'].value){
        data.property = property.name;
      }
    });
    var user = this.authService.getAuthUser();
    if(user){
      if(user.email){
        data.user = user.email;
      }
    }
    this.unitDetailsService.notifyCompliance(data).subscribe((_res) => {
      const title = 'Email sent';
      const message = `The compliance coordinator has been notified and will respond at their earliest opportunity.`; //${ property.name }?
      this.dialogsService.alert(title, message)
        .then(() => {

        }, () => { });

    });
  }
}
