import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUploaderService } from '@app/shared/components/file-uploader/file-uploader.service';
import { Files } from '@app/core/models/files';
import { AppConfig } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-files-dialog',
  templateUrl: './files-dialog.component.html',
  styleUrls: ['./files-dialog.component.scss']
})
export class FilesDialogComponent implements OnInit {
  @Input() file!: File;
  @Output() saveChange = new EventEmitter();
  // static instance: any;

  public dialogTitle: string = 'File';
  public fileList: Files[] = [];
  public canDelete: boolean = true;
  public isDeleting: boolean[] = [];
  public isDownloading: boolean[] = [];
  public ChildCurrentVal: any;

  constructor(
    public activeModal: NgbActiveModal,
    private fileUploaderService: FileUploaderService
  ) { 
    // if (!FilesDialogComponent.instance) {
    //   FilesDialogComponent.instance = this;
    // }
    // return FilesDialogComponent.instance;
  }

  ngOnInit() {
    console.log('%c init on files-dialog 1726531876253981726409164092837094182734981234', 'background: yellow; color: black', this.file);
    
    //this.getFiles()
  }

  // public getFiles () {
  //   this.fileUploaderService.getAll().subscribe((res) => {
  //     //this.fileList = res.reverse();
  //     this.fileList = res;
  //     console.log('%c this.fileList', 'background: yellow; color: black', this.fileList);

  //   }, ()=>{

  //   })
  // }

  // public getFilesByReportID (id: number) {
  //   this.fileUploaderService.getAllByReportId(id).subscribe((res) => {
  //     //this.fileList = res.reverse();
  //     this.fileList = res;
  //     console.log('%c this.fileList', 'background: yellow; color: black', this.fileList);

  //   }, ()=>{

  //   })
  // }

 public getDownloadURL(id: number, name: string) {
   this.isDownloading[id] = true;
    this.fileUploaderService.download(id).subscribe((res: any) => {
      const fileType = res.headers.get('Content-Type')
      const blob = new Blob([res.body], { type: fileType});
      const fileURL = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.cssText = "display:none";
      a.href = fileURL;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(fileURL);
      this.isDownloading = [];
    },() => {
      this.isDownloading = [];
    });
  }

  public getFileFromServer(filePath: string) {
    var filename =filePath.split('/').pop()
     this.fileUploaderService.downloadFile(filePath).subscribe((res: any) => {
       const fileType = res.headers.get('Content-Type')
       const blob = new Blob([res.body], { type: fileType});
       const fileURL = window.URL.createObjectURL(blob);
       const a = document.createElement("a");
       document.body.appendChild(a);
       a.style.cssText = "display:none";
       a.href = fileURL;
       a.setAttribute('download', filename);
      
       a.click();
       window.URL.revokeObjectURL(fileURL);
     
     },() => {
      
     });
   }

  public deleteFile(id: number) {
    this.canDelete = false;
    this.isDeleting[id] = true;
    this.fileUploaderService.delete(id).subscribe(() => {
      this.canDelete = true;
      this.isDeleting = [];
      this.fileList = this.fileList.filter((obj) => {
        return obj.fileId !== id;
      });
    }, () => {
      this.canDelete = true;
      this.isDeleting = [];
    });
  }

  //BFS: 9/16/2022 - 01.11 - this is the funciton thats attached to the files-dialog.component.html
  //the file that comes back from the child is then set to this.file so i can send that back to the parent component to this component
  public successFileUpload(file: any) {
    console.log('%c successFileUpload file', 'background: yellow; color: black', file);
    this.file = file;
    // const found = this.fileList.find(function (element) {
    //   return element.name === file.name;
    // });

    // if (found) {
    //   for (var i in this.fileList) {
    //     if (this.fileList[i].name == file.name) {
    //       this.fileList[i] = file;
    //       break;
    //     }
    //   }
    // } else {
    //   this.fileList.unshift(file)
    // }
  }

  //BFS: 00/16/2022 - 01.11 - now when you click save on the file dialoge modal, then it sends the data (this.file) back to the parent component of reports-add-dialgog.comonent.
  save(){
    console.log('%c save on add dialog', 'background: yellow; color: black', this.file);
    
    this.activeModal.close(this.file);
  }

  close() {
    this.activeModal.dismiss();
  }
}
