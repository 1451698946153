import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, Report, Unit, Building, CPPNotification, User } from '@app/core';
import { map } from 'rxjs/operators';
import { UnitLockAndUnlock } from '@app/core/models/UnitLockAndUnlock';
import { BuildingCPP } from '@app/core/models/buildingCPP';
import { UnitCPP } from '@app/core/models/unitCPP';
import { Role } from '@app/core/models/role';
import { RoleUserUpdateResult } from '@app/core/models/RoleUserUpdateResult';
import { RoleUserList } from '@app/core/models/RoleUserList';

@Injectable({ providedIn: 'root' })
export class RoleManagementService {
  constructor(private http: HttpClient) { }


  getAllUsers(isActive?: boolean) {
    var active = isActive == null ? '' : '/' + (isActive ? "true" : "false");
    return this.http.get<{ list: User[] }>(`${AppConfig.apiEndpoint}/users/1/10000${active}`)
      .pipe(                map(response => {
                  response.list.forEach(user => {
                      if (user.lockoutEnd) user.lockoutEnd = new Date(user.lockoutEnd);
                      if (user.createdDate) user.createdDate = new Date(user.createdDate);
                      if (user.updatedDate) user.updatedDate = new Date(user.updatedDate);
                  });
                  return response;
              })
          );
  }

  getRoleUsers(roleName:string) {
    
    return this.http.get<{ list: User[] }>(`${AppConfig.apiEndpoint}/Roles/getUsersByRole/${roleName}/1/10000`)
      .pipe(                map(response => {
                  response.list.forEach(user => {
                      if (user.lockoutEnd) user.lockoutEnd = new Date(user.lockoutEnd);
                      if (user.createdDate) user.createdDate = new Date(user.createdDate);
                      if (user.updatedDate) user.updatedDate = new Date(user.updatedDate);
                  });
                  return response;
              })
          );
  }


  getRoles() {
    return this.http.get<Role[]>(`${AppConfig.apiEndpoint}/Roles`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  
  removeRoleUsers(removedUsers: RoleUserList) {
    return this.http.post<RoleUserUpdateResult[]>(`${AppConfig.apiEndpoint}/Roles/removeUserRoles`, removedUsers);
  }

  addRoleUsers(removedUsers: RoleUserList) {
    return this.http.post<RoleUserUpdateResult[]>(`${AppConfig.apiEndpoint}/Roles/addUserRoles`, removedUsers);
  }

  addRole(role:Role){
    return this.http.post(`${AppConfig.apiEndpoint}/Roles/addRole`, role);
  }

  deleteRole(id: number) {
    return this.http.delete(`${AppConfig.apiEndpoint}/Roles/deleteRole/${id}`);
}
 

}
