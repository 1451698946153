<div class="modal-header">
  <h4 class="modal-title col-md-9">{{ dialogTitle }}</h4>
  <button type="button" class="btn col-md-1 lock-notify-button-margin" (click)="lock()" *ngIf="!formLock.controls['propertyLocked'].value && Admin">
    <i class="fa fa-unlock-alt" aria-hidden="true"></i>
  </button>
  <button type="button" class="btn col-md-1 lock-notify-button-margin" (click)="unlock()" *ngIf="formLock.controls['propertyLocked'].value && Admin">
    <i class="fa fa-lock" aria-hidden="true"></i>
  </button>
  <button type="button" class="btn col-md-2 lock-notify-button-margin" (click)="notify()" *ngIf="formLock.controls['propertyLocked'].value && !Admin">
    NOTIFY
  </button>
  <button type="button" class="close col-md-1 close-margin" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="formWNC" *ngIf="showWnc">
    <div class="form-row">
      <!-- entity ALL-->
      <div class="form-group col-md-7 row">
        <div class="control-label col-md-3">
          Entity:<i class="question-space1 fa fa-question-circle hover-pointer" [ngbPopover]="entityPopover" triggers="mouseenter:mouseleave" placement="bottom"></i>
        </div>
        <div class="col-md-9">

        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="wncFlag" type="checkbox" formControlName="wncFlag"  />
            <label class="custom-control-label d-block" for="wncFlag">WNC</label>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="cppFlag" type="checkbox" formControlName="cppFlag"  />
            <label class="custom-control-label d-block" for="cppFlag">CPP</label>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="pefFlag" type="checkbox" formControlName="pefFlag"  />
            <label class="custom-control-label d-block" for="pefFlag">PEF</label>
          </div>
        </div>
      </div>
      <!-- assigned to WNC -->
      <div class="form-group col-md-5">
        <label class="control-label">Compliance Assigned To:</label>
        <select class="form-control" formControlName="contactID" [class.error]="(isFailedForm ||formWNC.get('contactID')!.touched) && formWNC.get('contactID')!.invalid" >
          <option value=""></option>
          <option *ngFor="let option of complianceAssigendTo" [ngValue]="option.contactID">{{option.name}}</option>
        </select>
        <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('contactID')!.touched) && formWNC.get('contactID')!.hasError('required')">
          This field is required
        </span>
      </div>
      <!-- EMPTY -->
      <div class="col-md-12">

      </div>
      <!-- PROPERTY NAME ALL -->
      <div class="form-group col-md-4 required">
        <label class="control-label">Property Name:</label>
        <input class="form-control" formControlName="name"
               [class.error]="(isFailedForm ||formWNC.get('name')!.touched) && formWNC.get('name')!.invalid"  />
        <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('name')!.touched) && formWNC.get('name')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('name')!.touched) && formWNC.get('name')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <!-- PARTNERSHIP ALL -->
      <div class="form-group col-md-4">
        <mat-form-field class="full-width">
          <input type="text" placeholder="Partnership Name:" aria-label="Partnership Name:" class="form-control matautocompleteDisable" formControlName="partnershipName" matInput [matAutocomplete]="auto"
                 [class.error]="(isFailedForm ||formWNC.get('partnershipName')!.touched) && formWNC.get('partnershipName')!.invalid" (ngModelChange)="onPartnershipChange($event)"/>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredPartnershipListWNC | async" [value]="option.partnershipName">
              {{option.partnershipName}}
            </mat-option>
          </mat-autocomplete>
          <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('partnershipName')!.touched) && formWNC.get('partnershipName')!.hasError('required')">
            This field is required
          </span>
          <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('partnershipName')!.touched) && formWNC.get('partnershipName')!.hasError('minlength')">
            Should be from the list
          </span>
        </mat-form-field>
      </div>
      <!-- ADDRESS ALL -->
      <div class="form-group col-md-4 required">
        <label class="control-label">Address:</label>
        <input class="form-control" formControlName="address"
               [class.error]="(isFailedForm ||formWNC.get('address')!.touched) && formWNC.get('address')!.invalid"  />
        <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('address')!.touched) && formWNC.get('address')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('address')!.touched) && formWNC.get('address')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <!-- old Management company -->
      <!-- <div class="form-group col-md-6 required">
        <label class="control-label">Management Company:</label>
        <select class="form-control" formControlName="companyId"
                [class.error]="(isFailedForm ||form.get('companyId')!.touched) && form.get('companyId')!.invalid" [attr.disabled]="(!Admin == true ? true : null) || (form2.controls['propertyLocked'].value ? '' : null)">
          <option value="" disabled>-</option>
          <option *ngFor="let company of companies" [ngValue]="company.id">{{company.name}}</option>
        </select>
        <span class="error-text" *ngIf="(isFailedForm ||form.get('companyId')!.touched) && form.get('companyId')!.hasError('required')">
          This field is required
        </span>
      </div> -->
      <!-- MANAGEMENT COMPANY ALL -->
      <div class="form-group col-md-6 required">
        <mat-form-field class="full-width">
          <input type="text" placeholder="Management Company:" class="form-control matautocompleteDisable" formControlName="companyId" 
                 matInput [matAutocomplete]="autoCompany" [class.error]="(isFailedForm ||formWNC.get('companyId')!.touched) && formWNC.get('companyId')!.invalid" />
          <mat-autocomplete #autoCompany="matAutocomplete" [displayWith]="displayFnCompany(filteredCompanyListWNC | async)">
            <mat-option *ngFor="let company of filteredCompanyListWNC | async" [value]="company.companyId">
              {{company.name}}
            </mat-option>
          </mat-autocomplete>
          <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('companyId')!.touched) && formWNC.get('companyId')!.hasError('required')">
            This field is required
          </span>
          <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('companyId')!.touched) && formWNC.get('companyId')!.hasError('minlength')">
            Should be from the list
          </span>
        </mat-form-field>
      </div>
      <!-- CITY ALL -->
      <div class="form-group col-md-6 required">
        <label class="control-label">City:</label>
        <input class="form-control" formControlName="city"
               [class.error]="(isFailedForm ||formWNC.get('city')!.touched) && formWNC.get('city')!.invalid" />
        <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('city')!.touched) && formWNC.get('city')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('city')!.touched) && formWNC.get('city')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <!-- STATE ALL -->
      <div class="form-group col-md-2 required">
        <label class="control-label">State:</label>
        <select class="form-control" formControlName="state" (ngModelChange)="onStateChange($event)"
                [class.error]="(isFailedForm ||formWNC.get('state')!.touched) && formWNC.get('state')!.invalid" >
          <option value="">-</option>
          <option *ngFor="let state of states" [ngValue]="state.stateName">{{state.stateName}}</option>
        </select>
        <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('state')!.touched) && formWNC.get('state')!.hasError('required')">
          This field is required
        </span>
      </div>
      <!-- ZIP ALL -->
      <div class="form-group col-md-2 required">
        <label class="control-label">Zip Code:</label>
        <input class="form-control" formControlName="zip"
               [class.error]="(isFailedForm ||formWNC.get('zip')!.touched) && formWNC.get('zip')!.invalid"  />
        <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('zip')!.touched) && formWNC.get('zip')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('zip')!.touched) && formWNC.get('zip')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <!-- COUNTY WNC -->
      <div class="form-group col-md-2">
        <label class="control-label">County:</label>
        <select class="form-control" formControlName="countyID">
          <option value="">-</option>
          <option *ngFor="let county of counties" [ngValue]="county.countyID">{{county.countyName}}</option>
        </select>
      </div>
      <!-- Residential Bldgs WNC -->
      <div class="form-group col-md-2">
        <label class="control-label">Res. Bldgs:</label>
        <input class="form-control" formControlName="residentialBuildings" type="number"/>
      </div>
      <!-- Non Residential Bldgs WNC -->
      <div class="form-group col-md-2">
        <label class="control-label">Non Res. Bldgs:</label>
        <input class="form-control" formControlName="nonResidentialBuildings" type="number" />
      </div>
      <!-- Total # of Bldgs WNC -->
      <div class="form-group col-md-2">
        <label class="control-label">Total # of Bldgs:</label>
        <input class="form-control" formControlName="totalNumberOfBuildings" />
      </div>
      <!-- Age Restriction WNC -->
      <div class="form-group col-md-2">
        <label class="control-label">Age Restriction:</label>
        <!-- <input class="form-control" formControlName="ageRestriction" [attr.disabled]="(form2.controls['propertyLocked'].value ? '' : null)" /> -->
        <select class="form-control" formControlName="ageRestriction" >
          <option value="">-</option>
          <option value="55+">55+</option>
          <option value="62+">62+</option>
        </select>
        <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('ageRestriction')!.touched) && formWNC.get('ageRestriction')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <!-- Set Aside Type WNC -->
      <div class="form-group col-md-2">
        <label class="control-label">Set Aside Type:</label>
        <select class="form-control" formControlName="setAsideTypeID">
          <option value="">-</option>
          <option *ngFor="let sType of setAsideTypes" [ngValue]="sType.setAsideTypeID">{{sType.setAsideTypeName}}</option>
        </select>
      </div>
      <!-- Tenant Type ALL -->
      <div class="form-group col-md-2">
        <label class="control-label">Tenant Type:</label>
        <select class="form-control" formControlName="tenantTypeID" >
          <option value="">-</option>
          <option *ngFor="let tType of tenantTypes" [ngValue]="tType.tenantTypeID">{{tType.tenantTypeName}}</option>
        </select>
      </div>
      <!-- Construction Type WNC -->
      <div class="form-group col-md-2">
        <label class="control-label">Construction Type:</label>
        <select class="form-control" formControlName="constructionTypeID" >
          <option value="">-</option>
          <option *ngFor="let cType of constructionTypes" [ngValue]="cType.constructionTypeID">{{cType.constructionTypeName}}</option>
        </select>
      </div>
      <!-- Rehab Type WNC -->
      <div class="form-group col-md-2">
        <label class="control-label">Rehab Type:</label>
        <select class="form-control" formControlName="rehabTypeID">
          <option value="">-</option>
          <option *ngFor="let rType of rehabTypes" [ngValue]="rType.rehabTypeID">{{rType.rehabTypeName}}</option>
        </select>
      </div>
      <!-- BLANK WNC -->
      <div class="form-group col-md-1">

      </div>
      <!-- LIHTC UNITS ALL -->
      <div class="form-group col-md-2">
        <label class="control-label">LIHTC Units:</label>
        <input class="form-control" formControlName="lihtcUnits" type="number"  />
      </div>
      <!-- Market Rate Units ALL -->
      <div class="form-group col-md-2">
        <label class="control-label">Market Rate Units:</label>
        <input class="form-control" formControlName="marketUnits" type="number"  />
      </div>
      <!-- Exempt Mgmt Units ALL -->
      <div class="form-group col-md-2">
        <label class="control-label">Exempt Mgmt Units:</label>
        <input class="form-control" formControlName="exemptMgmtUnits" type="number"  />
      </div>
      <!-- HOME Units WNC -->
      <div class="form-group col-md-2">
        <label class="control-label">HOME Units:</label>
        <input class="form-control" formControlName="homeUnits" type="number"  />
      </div>
      <!-- Total Units ALL -->
      <div class="form-group col-md-2">
        <label class="control-label">Total Units:</label>
        <input class="form-control" formControlName="totalUnits" type="number"  />
      </div>
      <!-- Total Units 2 ALL -->
      <!-- <div class="form-group col-md-2" *ngIf="calculatedTotalUnits">
        <label class="control-label">Total Units:</label>
        <input class="form-control" formControlName="totalUnits1" type="number"  />
      </div> -->
      <!-- BLANK WNC -->
      <div class="form-group col-md-2">

      </div>
      <!-- WNC ONLY FROM HERE ON -->
      <div class="form-group col-md-12 underline">
        Additional Financing/Subsidy Programs Layered:
      </div>
      <div class="form-group col-md-2">
        <div class="custom-checkbox custom-control col-md-12">
          <input class="custom-control-input" id="rd" type="checkbox" formControlName="rd"  />
          <label class="custom-control-label d-block" for="rd">RD</label>
        </div>
      </div>
      <div class="form-group col-md-2">
        <div class="custom-checkbox custom-control col-md-12">
          <input class="custom-control-input" id="hud" type="checkbox" formControlName="hud" />
          <label class="custom-control-label d-block" for="hud">HUD</label>
        </div>
      </div>
      <div class="form-group col-md-2">
        <div class="custom-checkbox custom-control col-md-12">
          <input class="custom-control-input" id="home" type="checkbox" formControlName="home" />
          <label class="custom-control-label d-block" for="home">HOME</label>
        </div>
      </div>
      <div class="form-group col-md-2">
        <div class="custom-checkbox custom-control col-md-12">
          <input class="custom-control-input" id="teBond" type="checkbox" formControlName="teBond" />
          <label class="custom-control-label d-block" for="teBond">TE Bond</label>
        </div>
      </div>
      <div class="form-group col-md-2">
        <div class="custom-checkbox custom-control col-md-12">
          <input class="custom-control-input" id="cbdg" type="checkbox" formControlName="cbdg" />
          <label class="custom-control-label d-block" for="cbdg">CBDG</label>
        </div>
      </div>
      <div class="form-group col-md-2">
        <div class="custom-checkbox custom-control col-md-12">
          <input class="custom-control-input" id="hodag" type="checkbox" formControlName="hodag"  />
          <label class="custom-control-label d-block" for="hodag">HODAG</label>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">Other:</label>
        <input placeholder="Other financing subsidy programs" class="form-control" formControlName="additionalFinancingSubsidyProgramsLayeredOther" type="text" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">IsActive:</label>
        <select class="form-control" formControlName="isActive">
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <div class="custom-checkbox custom-control col-md-11">
          <input class="custom-control-input" id="additionalTargeting" type="checkbox" formControlName="additionalTargeting" (ngModelChange)="onAdditionalTargetingChecked()"  />
          <label class="custom-control-label d-block" for="additionalTargeting">
            Additional Targeting: <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="additionalTargetingPopover"
                                     triggers="mouseenter:mouseleave" placement="right"></i>
          </label>
        </div>
      </div>
      <div class="form-group col-md-2">
        <button *ngIf="additionalTargeting" type="button" class="btn-sm btn-info" (click)="addAdditionTargeting()">Add</button>
      </div>
      <div class="form-group col-md-4">
        <div class="custom-checkbox custom-control col-md-8">
          <input class="custom-control-input" id="deepRentSkewing" type="checkbox" formControlName="deepRentSkewing" (ngModelChange)="onDeepRentSkewingChecked()"  />
          <label class="custom-control-label d-block" for="deepRentSkewing">
            Deep Rent Skewing: <i class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="deepRentSkewingPopover"
                                  triggers="mouseenter:mouseleave" placement="right"></i>
          </label>
        </div>
      </div>
      <div class="form-group col-md-2" >
        <button Id="deepRentButton" *ngIf="deepRentSkewing" type="button" class="btn-sm btn-info" (click)="addDeepRentSkewing()">Add</button>
      </div>
    </div>
  </form>
  <div class="form-group col-md-12 no-pad full-width-table row" *ngIf="showWnc">
    <div class="col-md-6">
      <div class="col-md-12 table-header row" *ngIf="additionalTargeting">
        <div class="col-md-5 align-center no-pad">
          <span>Number of Units</span>
        </div>
        <div class="col-md-5 align-center no-pad">
          <span>Percent</span>
        </div>
        <div class="col-md-2 align-center no-pad">
          <span>Action</span>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="col-md-12 table-header row" *ngIf="deepRentSkewing">
        <div class="col-md-5 align-center no-pad">
          <span>Number of Units</span>
        </div>
        <div class="col-md-5 align-center no-pad">
          <span>Percent</span>
        </div>
        <div class="col-md-2 align-center no-pad">
          <span>Action</span>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="col-md-12 table-content no-pad" *ngIf="additionalTargeting">
        <div class="col-md-12 row" *ngFor="let addTarget of propertyAdditionalTargetings; let ndx = index">
          <div class="col-md-5">
            <input type="number" class="form-control align-center " [(ngModel)]="addTarget.numberOfUnits" [disabled]="formLock.controls['propertyLocked'].value === true" />
          </div>
          <div class="col-md-5">
            <input type="number" class="form-control text-right " [(ngModel)]="addTarget.propertyAdditionalTargetingPercent" [disabled]="formLock.controls['propertyLocked'].value === true" />
          </div>
          <div class="col-md-2 row left-pad top-pad">
            <div class="col-xs-6 col-md-6 no-pad atc-save-btn text-right" *ngIf="!formLock.controls['propertyLocked'].value">
              <img class="cursor-img" src="../../../../assets/image/ic_save.png" alt="" (click)="saveAdditionalTargeting(addTarget)" />
            </div>
            <div class="col-xs-6 col-md-6 left-pad text-center"  *ngIf="!formLock.controls['propertyLocked'].value && Admin">
              <img class="cursor-img" src="../../../../assets/image/ic_delete.png" alt="" (click)="deleteAdditionalTargeting(ndx, addTarget.propertyAdditionalTargetingID)" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 table-content no-pad" *ngIf="newAdditionalTargetingForm">
        <div class="col-md-12 row" *ngFor="let newAddTarget of newAdditionalTargetings; let ndx = index">
          <div class="col-md-5">
            <input type="number" class="form-control align-center " [(ngModel)]="newAddTarget.numberOfUnits" [disabled]="formLock.controls['propertyLocked'].value === true"/>
          </div>
          <div class="col-md-5">
            <input type="number" class="form-control text-right " [(ngModel)]="newAddTarget.propertyAdditionalTargetingPercent" [disabled]="formLock.controls['propertyLocked'].value === true" />
          </div>
          <div class="col-md-2" *ngIf="formLock.controls['propertyLocked'].value === false">
            <img class="cursor-img" src="../../../../assets/image/ic_delete.png" alt="" (click)="deleteNewAdditionalTargeting(ndx)" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="col-md-12 table-content no-pad" *ngIf="deepRentSkewing">
        <div class="col-md-12 row" *ngFor="let deepRent of propertyDeepRentSkewings; let ndx = index">
          <div class="col-md-5">
            <input type="number" class="form-control align-center " [(ngModel)]="deepRent.numberOfUnits" [disabled]="formLock.controls['propertyLocked'].value === true" />
          </div>
          <div class="col-md-5">
            <input type="number" class="form-control text-right" [(ngModel)]="deepRent.propertyDeepRentSkewingPercent" [disabled]="formLock.controls['propertyLocked'].value === true" />
          </div>
          <div class="col-md-2 row left-pad top-pad">
            <div class="col-xs-6 col-md-6 no-pad atc-save-btn text-right" *ngIf="!formLock.controls['propertyLocked'].value">
              <img class="cursor-img" src="../../../../assets/image/ic_save.png" alt="" (click)="saveDeepRentSkewing(deepRent)" />
            </div>
            <div class="col-xs-6 col-md-6 left-pad text-center" *ngIf="!formLock.controls['propertyLocked'].value && Admin">
              <img class="cursor-img" src="../../../../assets/image/ic_delete.png" alt="" (click)="deleteDeepRentSkewing(ndx, deepRent.propertyDeepRentSkewingID)" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 table-content no-pad" *ngIf="newDeepRentSkewingForm">
        <div class="col-md-12 row" *ngFor="let newDeepRent of newDeepRentSkewings; let ndx = index">
          <div class="col-md-5">
            <input type="number" class="form-control align-center "  [(ngModel)]="newDeepRent.numberOfUnits" [disabled]="formLock.controls['propertyLocked'].value === true"/>
          </div>
          <div class="col-md-5">
            <input type="number" class="form-control text-right "  [(ngModel)]="newDeepRent.propertyDeepRentSkewingPercent" [disabled]="formLock.controls['propertyLocked'].value === true"/>
          </div>
          <div class="col-md-2" *ngIf="formLock.controls['propertyLocked'].value === false">
            <img class="cursor-img" src="../../../../assets/image/ic_delete.png" alt="" (click)="deleteNewDeepRentSkewing(ndx)" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="formCPP" *ngIf="showCpp">
    <div class="form-row">
      <div class="form-group col-md-7 row">
        <div class="control-label col-md-3">
          Entity:<i class="question-space1 fa fa-question-circle hover-pointer" [ngbPopover]="entityPopover" triggers="mouseenter:mouseleave" placement="bottom"></i>
        </div>
        <div class="col-md-9">

        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <!-- [attr.disabled]="((userEntity == 'CPP' && form2.controls['propertyLocked'].value) || (userEntity == 'WNC') ? '' : null)" -->
            <input class="custom-control-input" id="wncFlag" type="checkbox" formControlName="wncFlag"  />
            <label class="custom-control-label d-block" for="wncFlag">WNC</label>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <!--(ngModelChange)="onCppFlagChecked($event)"-->
            <input class="custom-control-input" id="cppFlag" type="checkbox" formControlName="cppFlag"  />
            <label class="custom-control-label d-block" for="cppFlag">CPP</label>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="pefFlag" type="checkbox" formControlName="pefFlag"  />
            <label class="custom-control-label d-block" for="pefFlag">PEF</label>
          </div>
        </div>
      </div>
      <div class="col-md-5 form-group required" *ngIf="!hideWNCStuff">
        <label class="control-label">CPP Asset Mngr:</label>
        <select class="form-control" formControlName="cppAssetManagerContactID" [class.error]="(isFailedForm ||formCPP.get('cppAssetManagerContactID')!.touched) && formCPP.get('cppAssetManagerContactID')!.invalid" >
          <option value=""></option>
          <option *ngFor="let option of cppAssignedTo" [ngValue]="option.cppAssetManagerContactID">{{option.name}}</option>
        </select>
        <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('cppAssetManagerContactID')!.touched) && formCPP.get('cppAssetManagerContactID')!.hasError('required')">
          This field is required
        </span>
      </div>
      <div class="col-md-5" *ngIf="hideWNCStuff">
        <label class="control-label">Compliance Assigned To:</label>
        <select class="form-control" formControlName="contactID" [class.error]="(isFailedForm ||formWNC.get('contactID')!.touched) && formWNC.get('contactID')!.invalid" >
          <option value=""></option>
          <option *ngFor="let option of complianceAssigendTo" [ngValue]="option.contactID">{{option.name}}</option>
        </select>
        <span class="error-text" *ngIf="(isFailedForm ||formWNC.get('contactID')!.touched) && formWNC.get('contactID')!.hasError('required')">
          This field is required
        </span>
      </div>

      <div class="form-group col-md-4 required" *ngIf="!hideWNCStuff">
        <label class="control-label">Region:</label>
        <select class="form-control" formControlName="region"  [class.error]="(isFailedForm ||formCPP.get('region')!.touched) && formCPP.get('region')!.invalid">
          <option value=""></option>
          <option value="CPP-West">CPP-West</option>
          <option value="CPP-East">CPP-East</option>
        </select>
        <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('cppAssetManagerContactID')!.touched) && formCPP.get('cppAssetManagerContactID')!.hasError('required')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-4" *ngIf="!hideWNCStuff">
        <mat-form-field class="full-width">
          <input type="text" placeholder="Joint Venture Partner:" class="form-control matautocompleteDisable" formControlName="propertyJointVenturePartnerID"
            matInput [matAutocomplete]="autoJointVenture" />
          <mat-autocomplete #autoJointVenture="matAutocomplete" [displayWith]="displayFn(filteredPropertyJointVenturePartnerList | async)">
            <mat-option *ngFor="let jointVenture of filteredPropertyJointVenturePartnerList | async" [value]="jointVenture.propertyJointVenturePartnerID">
              {{jointVenture.propertyJointVenturePartnerName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-group col-md-4" *ngIf="!hideWNCStuff">
        <mat-form-field class="full-width">
          <input type="text" placeholder="Investor:" class="form-control matautocompleteDisable" formControlName="investorID"
            matInput [matAutocomplete]="autoInvestor" />
          <mat-autocomplete #autoInvestor="matAutocomplete" [displayWith]="displayFnInvestor(filteredInvestorList | async)">
            <mat-option *ngFor="let option of filteredInvestorList | async" [value]="option.investorID">
              {{option.investorName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-group col-md-4 required">
        <label class="control-label">Property Name:</label>
        <input class="form-control" formControlName="name"
               [class.error]="(isFailedForm ||formCPP.get('name')!.touched) && formCPP.get('name')!.invalid"  />
        <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('name')!.touched) && formCPP.get('name')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('name')!.touched) && formCPP.get('name')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <div class="form-group col-md-4">
        <mat-form-field class="full-width">
          <input type="text" placeholder="Partnership Name:" aria-label="Partnership Name:" class="form-control matautocompleteDisable" formControlName="partnershipName" matInput [matAutocomplete]="auto"
                 [class.error]="(isFailedForm ||formCPP.get('partnershipName')!.touched) && formCPP.get('partnershipName')!.invalid" />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredPartnershipListCPP | async" [value]="option.partnershipName">
              {{option.partnershipName}}
            </mat-option>
          </mat-autocomplete>
          <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('partnershipName')!.touched) && formCPP.get('partnershipName')!.hasError('required')">
            This field is required
          </span>
          <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('partnershipName')!.touched) && formCPP.get('partnershipName')!.hasError('minlength')">
            Should be from the list
          </span>
        </mat-form-field>
      </div>
      <div class="form-group col-md-4 required">
        <label class="control-label">Address:</label>
        <input class="form-control" formControlName="address"
               [class.error]="(isFailedForm ||formCPP.get('address')!.touched) && formCPP.get('address')!.invalid"  />
        <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('address')!.touched) && formCPP.get('address')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('address')!.touched) && formCPP.get('address')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <div class="form-group col-md-6 required">
        <mat-form-field class="full-width">
          <input type="text" placeholder="Management Company:" class="form-control matautocompleteDisable" formControlName="companyId" 
                  matInput [matAutocomplete]="autoCompany" [class.error]="(isFailedForm ||formCPP.get('companyId')!.touched) && formCPP.get('companyId')!.invalid" />
          <mat-autocomplete #autoCompany="matAutocomplete" [displayWith]="displayFnCompany(filteredCompanyListCPP | async)">
            <mat-option *ngFor="let company of filteredCompanyListCPP | async" [value]="company.companyId">
              {{company.name}}
            </mat-option>
          </mat-autocomplete>
          <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('companyId')!.touched) && formCPP.get('companyId')!.hasError('required')">
            This field is required
          </span>
          <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('companyId')!.touched) && formCPP.get('companyId')!.hasError('minlength')">
            Should be from the list
          </span>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6 required">
        <label class="control-label">City:</label>
        <input class="form-control" formControlName="city"
                [class.error]="(isFailedForm ||formCPP.get('city')!.touched) && formCPP.get('city')!.invalid" />
        <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('city')!.touched) && formCPP.get('city')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('city')!.touched) && formCPP.get('city')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <label class="control-label">State:</label>
        <select class="form-control" formControlName="state" (ngModelChange)="onStateChange($event)"
                [class.error]="(isFailedForm ||formCPP.get('state')!.touched) && formCPP.get('state')!.invalid" >
          <option value="">-</option>
          <option *ngFor="let state of states" [ngValue]="state.stateName">{{state.stateName}}</option>
        </select>
        <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('state')!.touched) && formCPP.get('state')!.hasError('required')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-2 required">
        <label class="control-label">Zip Code:</label>
        <input class="form-control" formControlName="zip"
                [class.error]="(isFailedForm ||formCPP.get('zip')!.touched) && formCPP.get('zip')!.invalid"  />
        <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('zip')!.touched) && formCPP.get('zip')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formCPP.get('zip')!.touched) && formCPP.get('zip')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <div class="form-group col-md-2">
        <label class="control-label">Tenant Type:</label>
        <select class="form-control" formControlName="tenantTypeID" >
          <option value="">-</option>
          <option *ngFor="let tType of tenantTypes" [ngValue]="tType.tenantTypeID">{{tType.tenantTypeName}}</option>
        </select>
      </div>
      <div class="form-group col-md-2" *ngIf="!hideWNCStuff">
        <label class="control-label">Subsidy Type:</label>
        <select class="form-control" formControlName="subsidyTypeID" >
          <option value="">-</option>
          <option *ngFor="let sType of subsidyTypes" [ngValue]="sType.subsidyTypeID">{{sType.subsidyTypeName}}</option>
        </select>
      </div>
      <div class="form-group col-md-2" *ngIf="hideWNCStuff">
        
      </div>
      <div class="form-group col-md-4"></div>
      <div class="form-group col-md-2">
        <label class="control-label">LIHTC Units:</label>
        <input class="form-control" formControlName="lihtcUnits" type="number"  />
      </div>
      <div class="form-group col-md-2">
        <label class="control-label">Market Rate Units:</label>
        <input class="form-control" formControlName="marketUnits" type="number"  />
      </div>
      <div class="form-group col-md-2">
        <label class="control-label">Exempt Mgmt Units:</label>
        <input class="form-control" formControlName="exemptMgmtUnits" type="number"  />
      </div>
      <div class="form-group col-md-2">
        <label class="control-label">Total Units:</label>
        <input class="form-control" formControlName="totalUnits" type="number"  />
      </div>
      <div class="form-group col-md-2" *ngIf="calculatedTotalUnits">
        <label class="control-label">Total Units:</label>
        <input class="form-control" formControlName="totalUnits1" type="number"  />
      </div>
      <div class="form-group col-md-2">
        <label class="control-label">IsActive:</label>
        <select class="form-control" formControlName="isActive">
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
    </div>
  </form>

  <form [formGroup]="formPEF" *ngIf="showPef">
    <div class="form-row">
      <div class="form-group col-md-7 row">
        <div class="control-label col-md-3">
          Entity:<i class="question-space1 fa fa-question-circle hover-pointer" [ngbPopover]="entityPopover" triggers="mouseenter:mouseleave" placement="bottom"></i>
        </div>
        <div class="col-md-9">

        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="wncFlag" type="checkbox" formControlName="wncFlag"  />
            <label class="custom-control-label d-block" for="wncFlag">WNC</label>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="cppFlag" type="checkbox" formControlName="cppFlag"  />
            <label class="custom-control-label d-block" for="cppFlag">CPP</label>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="custom-checkbox custom-control col-md-12">
            <input class="custom-control-input" id="pefFlag" type="checkbox" formControlName="pefFlag"  />
            <label class="custom-control-label d-block" for="pefFlag">PEF</label>
          </div>
        </div>
      </div>
      <div class="col-md-5">
      </div>
      <!-- property name -->
      <div class="form-group col-md-4 required">
        <label class="control-label">Property Name:</label>
        <input class="form-control" formControlName="name"
               [class.error]="(isFailedForm ||formPEF.get('name')!.touched) && formPEF.get('name')!.invalid"  />
        <span class="error-text" *ngIf="(isFailedForm ||formPEF.get('name')!.touched) && formPEF.get('name')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formPEF.get('name')!.touched) && formPEF.get('name')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <!-- partnership name -->
      <div class="form-group col-md-4">
        <mat-form-field class="full-width">
          <input type="text" placeholder="Partnership Name:" aria-label="Partnership Name:" class="form-control matautocompleteDisable" formControlName="partnershipName" matInput [matAutocomplete]="auto"
                 [class.error]="(isFailedForm ||formPEF.get('partnershipName')!.touched) && formPEF.get('partnershipName')!.invalid"  (ngModelChange)="onPartnershipChangePEF($event)"/>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredPartnershipListPEF | async" [value]="option.partnershipName">
              {{option.partnershipName}}
            </mat-option>
          </mat-autocomplete>
          <span class="error-text" *ngIf="(isFailedForm ||formPEF.get('partnershipName')!.touched) && formPEF.get('partnershipName')!.hasError('required')">
            This field is required
          </span>
          <span class="error-text" *ngIf="(isFailedForm ||formPEF.get('partnershipName')!.touched) && formPEF.get('partnershipName')!.hasError('minlength')">
            Should be from the list
          </span>
        </mat-form-field>
      </div>
      <!-- address -->
      <div class="form-group col-md-4 required">
        <label class="control-label">Address:</label>
        <input class="form-control" formControlName="address"
               [class.error]="(isFailedForm ||formPEF.get('address')!.touched) && formPEF.get('address')!.invalid"  />
        <span class="error-text" *ngIf="(isFailedForm ||formPEF.get('address')!.touched) && formPEF.get('address')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formPEF.get('address')!.touched) && formPEF.get('address')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <!-- management company -->
      <div class="form-group col-md-6 required">
        <mat-form-field class="full-width">
          <input type="text" placeholder="Management Company:" class="form-control matautocompleteDisable" formControlName="companyId" 
                  matInput [matAutocomplete]="autoCompany" [class.error]="(isFailedForm ||formPEF.get('companyId')!.touched) && formPEF.get('companyId')!.invalid" />
          <mat-autocomplete #autoCompany="matAutocomplete" [displayWith]="displayFnCompany(filteredCompanyListPEF | async)">
            <mat-option *ngFor="let company of filteredCompanyListPEF | async" [value]="company.companyId">
              {{company.name}}
            </mat-option>
          </mat-autocomplete>
          <span class="error-text" *ngIf="(isFailedForm ||formPEF.get('companyId')!.touched) && formPEF.get('companyId')!.hasError('required')">
            This field is required
          </span>
          <span class="error-text" *ngIf="(isFailedForm ||formPEF.get('companyId')!.touched) && formPEF.get('companyId')!.hasError('minlength')">
            Should be from the list
          </span>
        </mat-form-field>
      </div>
      <!-- city -->
      <div class="form-group col-md-6 required">
        <label class="control-label">City:</label>
        <input class="form-control" formControlName="city"
                [class.error]="(isFailedForm ||formPEF.get('city')!.touched) && formPEF.get('city')!.invalid" />
        <span class="error-text" *ngIf="(isFailedForm ||formPEF.get('city')!.touched) && formPEF.get('city')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formPEF.get('city')!.touched) && formPEF.get('city')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <!-- state -->
      <div class="form-group col-md-2 required">
        <label class="control-label">State:</label>
        <select class="form-control" formControlName="state" (ngModelChange)="onStateChange($event)"
                [class.error]="(isFailedForm ||formPEF.get('state')!.touched) && formPEF.get('state')!.invalid" >
          <option value="">-</option>
          <option *ngFor="let state of states" [ngValue]="state.stateName">{{state.stateName}}</option>
        </select>
        <span class="error-text" *ngIf="(isFailedForm ||formPEF.get('state')!.touched) && formPEF.get('state')!.hasError('required')">
          This field is required
        </span>
      </div>
      <!-- zip -->
      <div class="form-group col-md-2 required">
        <label class="control-label">Zip Code:</label>
        <input class="form-control" formControlName="zip"
                [class.error]="(isFailedForm ||formPEF.get('zip')!.touched) && formPEF.get('zip')!.invalid"  />
        <span class="error-text" *ngIf="(isFailedForm ||formPEF.get('zip')!.touched) && formPEF.get('zip')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text" *ngIf="(isFailedForm ||formPEF.get('zip')!.touched) && formPEF.get('zip')!.hasError('minlength')">
          Should contain at least 2 characters
        </span>
      </div>
      <div class="form-group col-md-2">
        <label class="control-label">IsActive:</label>
        <select class="form-control" formControlName="isActive">
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
      <!-- tenant Type -->
      <!-- <div class="form-group col-md-2">
        <label class="control-label">Tenant Type:</label>
        <select class="form-control" formControlName="tenantTypeID" >
          <option value="">-</option>
          <option *ngFor="let tType of tenantTypes" [ngValue]="tType.tenantTypeID">{{tType.tenantTypeName}}</option>
        </select>
      </div> -->
      <!-- subsity type -->
      <!-- <div class="form-group col-md-2">
        <label class="control-label">Subsidy Type:</label>
        <select class="form-control" formControlName="subsidyTypeID" >
          <option value="">-</option>
          <option *ngFor="let sType of subsidyTypes" [ngValue]="sType.subsidyTypeID">{{sType.subsidyTypeName}}</option>
        </select>
      </div> -->
    </div>
  </form>
</div>

<div class="modal-footer" *ngIf="showWnc">
  <div *ngIf="!isAdd" class="w-100">
    <div class="row">
      <div class="col-3">Created:</div>
      <div class="col"><small>{{formWNC.get("createdByName")!.value}}, {{ formWNC.get("createdDate")!.value | date : "short" }}</small></div>
    </div>
    <div class="row">
      <div class="col-3">Modified:</div>
      <div class="col"><small>{{formWNC.get("updatedByName")!.value}}, {{ formWNC.get("updatedDate")!.value | date : "short" }}</small></div>
    </div>
  </div>
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  <button [disabled]="!formWNC.valid" type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>
<div class="modal-footer" *ngIf="showCpp">
  <div *ngIf="!isAdd" class="w-100">
    <div class="row">
      <div class="col-3">Created:</div>
      <div class="col"><small>{{formCPP.get("createdByName")!.value}}, {{ formCPP.get("createdDate")!.value | date : "short" }}</small></div>
    </div>
    <div class="row">
      <div class="col-3">Modified:</div>
      <div class="col"><small>{{formCPP.get("updatedByName")!.value}}, {{ formCPP.get("updatedDate")!.value | date : "short" }}</small></div>
    </div>
  </div>
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  <button [disabled]="!formCPP.valid" type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>
<div class="modal-footer" *ngIf="showPef">
  <div *ngIf="!isAdd" class="w-100">
    <div class="row">
      <div class="col-3">Created:</div>
      <div class="col"><small>{{formPEF.get("createdByName")!.value}}, {{ formPEF.get("createdDate")!.value | date : "short" }}</small></div>
    </div>
    <div class="row">
      <div class="col-3">Modified:</div>
      <div class="col"><small>{{formPEF.get("updatedByName")!.value}}, {{ formPEF.get("updatedDate")!.value | date : "short" }}</small></div>
    </div>
  </div>
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  <button [disabled]="!formPEF.valid" type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>
