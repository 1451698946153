import { Component, OnInit, Inject, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { NgbDatepicker, NgbActiveModal, NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Property } from '@app/core/models/property';
import { PropertyService } from '@app/properties/properties.service';
import { UnitDetailsService } from '@app/unitDetails/unitDetails.service';
import { UserService } from '@app/users/user.service';
import { CheckboxSelectionComponent, ColumnGroup } from 'ag-grid-community';
//import { validateConfig } from '@angular/router/src/config';
import { PropertyAdditionalTargeting } from '@app/core/models/propertyAdditionalTargeting'; 
import { ComplianceNotification } from '@app/core/models/complianceNotification';
import { PropertyDeepRentSkewing } from '@app/core/models/propertyDeepRentSkewing';
import { PartnershipList } from '@app/core/models/partnershipList';
import { DialogsService } from '@app/core/services/dialogs.service';
import { ServerErrors } from '@app/core/models/server-errors';
import { AuthService, Credentials } from "@app/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { PropertyJointVenturePartnerList } from '@app/core/models/propertyJointVenturePartnerList';
import { CompanySimple } from '@app/core/models/companySimple';
import { InvestorList } from '@app/core/models/investorList';


@Component({
  selector: 'app-property-add-dialog',
  templateUrl: './property-add-dialog.component.html',
  styleUrls: ['./property-add-dialog.component.css']
})
export class PropertyAddDialogComponent implements OnInit {
  @ViewChild('d', { read: NgbDatepicker }) d: NgbDatepicker | undefined;
  dialogTitle = '';
  isAdd = true;
  newAdditionalTargetingForm = false;
  additionalTargeting = false;
  newDeepRentSkewingForm = false;
  deepRentSkewing = false;
  disableInputs = true;
  formWNC: UntypedFormGroup;
  formCPP: UntypedFormGroup;
  formPEF: UntypedFormGroup;
  formLock: UntypedFormGroup;
  @Input()
  //BFS: 1.02 - to send the array of objects back to the parent for posting you need this line (and then look at properties.component.ts)
  @Output() passEntry = new EventEmitter<any>();
  @Output() passEntryDeep = new EventEmitter<any>();
  property!: Property;
  //BFS: 1.02 - just save this
  //public states = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'];
  public isFailedForm: boolean = false;
  public companies: CompanySimple[] = [];
  public filteredCompanyListWNC: Observable<CompanySimple[]> | undefined;
  public filteredCompanyListCPP: Observable<CompanySimple[]> | undefined;
  public filteredCompanyListPEF: Observable<CompanySimple[]> | undefined;
  public constructionTypes: any;
  public rehabTypes: any;
  public tenantTypes: any;
  public setAsideTypes: any;
  public states: any;
  public partnershipList: PartnershipList[] = [];
  public filteredPartnershipListWNC: Observable<PartnershipList[]> | undefined;
  public filteredPartnershipListCPP: Observable<PartnershipList[]> | undefined;
  public filteredPartnershipListPEF: Observable<PartnershipList[]> | undefined;
  public propertyJointVenturePartnerList: PropertyJointVenturePartnerList[] = [];
  public filteredPropertyJointVenturePartnerList: Observable<PropertyJointVenturePartnerList[]> | undefined;
  public propertyOnPartnershipSelect: any[] = [];
  public propertyRentSubsidyOnPartnershipSelect: any[] = [];
  public propertyLoanAndSubsidyGrantTypeOnPartnershipSelect: any[] = [];
  public Admin: boolean = false;
  //BFS: 1.02 - this is when the type calls were called on the open of the modal
  // the top is where its getting from the property page and passed into the modal
  //public constructionTypes: { constructionTypeID: number, constructionTypeName: string, displayOrder: number }[] = [];
  //public rehabTypes: { rehabTypeID: number, rehabTypeName: string, displayOrder: number }[] = [];
  //public tenantTypes: { tenantTypeID: number, tenantTypeName: string, module: string, displayOrder: number }[] = [];
  //public setAsideTypes: { setAsideTypeID: number, setAsideTypeName: string, displayOrder: number }[] = [];
  //public states: { stateID: number, stateName: string }[] = [];
  public counties: { countyID: number, countyName: string, state: string }[] = [];
  public propertyAdditionalTargetings: PropertyAdditionalTargeting[] = [];
  public propertyDeepRentSkewings: PropertyDeepRentSkewing[] = [];
  public newAdditionalTargetings: any[] = [];
  public newDeepRentSkewings: any[] = [];
  public additionalTargetingPopover: string = 'Check this box to add multiple additional targetings by pushing the "Add" button then the "Save" button on the bottom right to save all entries.';
  public deepRentSkewingPopover: string = 'Check this box to add multiple deep rent skewings by pushing the "Add" button then the "Save" button on the bottom right to save all entries.';
  public entityPopover: string = "At least one of the entity checkboxes need to be checked.";
  public notifyText: string = "Click this button to email an admin to unlock fields to update";
  public isAdmin: boolean = false;
  public isUser: boolean = false;
  public PropertyID: number | null | undefined;
  public showWnc: boolean = false;
  public showCpp: boolean = false;
  public showPef: boolean = false;
  public calculatedTotalUnits: boolean = false;
  public userEntity: string[] | null | undefined;
  public subsidyTypes: any;
  public complianceAssigendTo: any;
  public cppAssignedTo: any;
  public investorList: InvestorList[] = [];
  public filteredInvestorList: Observable<InvestorList[]> | undefined;
  public totalBuildings: number = 0;
  public totalUnits: number = 0;
  public disableWNC: boolean = true;
  public lockWNC: boolean = false;
  public lockCPP: boolean = false;
  public lockPEF: boolean = false;
  public rd: boolean = false;
  public hud: boolean = false;
  public other: string | null | undefined;
  public teBond: boolean = false;
  public home: boolean = false;
  public onSelectPropertyID: number = 0;
  public hideWNCStuff: boolean = false;
  
  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private propertyService: PropertyService,
    private unitDetailService: UnitDetailsService,
    private userService: UserService,
    private dialogsService: DialogsService,
    public activeModal: NgbActiveModal,
    private ref: ChangeDetectorRef) {

    this.formWNC = this.fb.group({
      propertyID: ['', []],
      wncFlag: [{value: false, disabled: this.disableWNC}, []],
      cppFlag: [{value: false, disabled: this.disableWNC}, []],
      pefFlag: [{value: false, disabled: this.disableWNC}, []],
      contactID: [{value: '', disabled: this.disableWNC}, []],
      name: [{value: '', disabled: this.disableWNC}, Validators.compose([Validators.required, Validators.minLength(2)])],
      partnershipName: [{value: '',  disabled: this.disableWNC}, []],
      address: [{value: '', disabled: this.disableWNC}, Validators.compose([Validators.required, Validators.minLength(2)])],
      companyId: [{value: '', disabled: this.disableWNC}, []],
      city: [{value: '', disabled: this.disableWNC}, Validators.compose([Validators.required, Validators.minLength(2)])],
      state: [{value: '', disabled: this.disableWNC}, Validators.compose([Validators.required])],
      zip: [{value: '', disabled: this.disableWNC}, Validators.compose([Validators.required, Validators.minLength(2)])],
      countyID: [{value: '', disabled: this.disableWNC}, []],
      residentialBuildings: [{value: '', disabled: this.disableWNC}, []],
      nonResidentialBuildings: [{value: '', disabled: this.disableWNC}, []],
      totalNumberOfBuildings: [{ value: '', disabled: true }],
      ageRestriction: [{value: '', disabled: this.lockWNC}, []],
      setAsideTypeID: [{value: '', disabled: this.disableWNC}, []],
      rehabTypeID: [{value: '', disabled: this.disableWNC}, []],
      tenantTypeID: [{value: '', disabled: this.disableWNC}, []],
      constructionTypeID: [{value: '', disabled: this.disableWNC}, []],
      lihtcUnits: [{value: '', disabled: this.disableWNC}, []],
      marketUnits: [{value: '', disabled: this.disableWNC}, []],
      exemptMgmtUnits: [{value: '', disabled: this.disableWNC}, []],
      homeUnits: [{value: '', disabled: this.lockWNC}, []],
      totalUnits: [{value: '', disabled: true}],
      rd: [{value: false, disabled: this.disableWNC}],
      hud: [{value: false, disabled: this.disableWNC}],
      home: [{value: false, disabled: this.disableWNC}],
      teBond: [{value: false, disabled: this.disableWNC}],
      cbdg: false,
      hodag: false,
      additionalFinancingSubsidyProgramsLayeredOther: ['', []],
      additionalTargeting: false,
      deepRentSkewing: false,
      createdById: ['', []],
      createdByName: ['', []],
      createdDate: ['', []],
      updatedById: ['', []],
      updatedByName: ['', []],
      updatedDate: ['', []],
      isActive:true
    });
    this.formCPP = this.fb.group({
      propertyID: ['', []],
      wncFlag: [{value: false, disabled: this.lockCPP}, []],
      cppFlag: [{value: false, disabled: this.lockCPP}, []],
      pefFlag: [{value: false, disabled: this.lockCPP}, []],
      cppAssetManagerContactID: [{value: '', disabled: this.lockCPP}, Validators.required],
      //contactID: [{value: '', disabled: this.lockCPP}, Validators.required],
      region: [{value: '', disabled: this.lockCPP}, Validators.required],
      propertyJointVenturePartnerID: [{value: '', disabled: this.lockCPP}, []],
      investorID: [{value: '', disabled: this.lockCPP}, []],
      name: [{value: '', disabled: this.lockCPP}, Validators.compose([Validators.required, Validators.minLength(2)])],
      partnershipName: [{value: '', disabled: this.lockCPP}, []],
      address: [{value: '', disabled: this.lockCPP}, Validators.compose([Validators.required, Validators.minLength(2)])],
      companyId: [{value: '', disabled: this.lockCPP}, Validators.required],
      city: [{value: '', disabled: this.lockCPP}, Validators.compose([Validators.required, Validators.minLength(2)])],
      state: [{value: '', disabled: this.lockCPP}, Validators.compose([Validators.required])],
      zip: [{value: '', disabled: this.lockCPP}, Validators.compose([Validators.required, Validators.minLength(2)])],
      tenantTypeID: [{value: '', disabled: this.lockCPP}, []],
      subsidyTypeID: [{value: '', disabled: this.lockCPP}, []],
      lihtcUnits: [{value: 0, disabled: this.lockCPP}, []],
      marketUnits: [{value: 0, disabled: this.lockCPP}, []],
      exemptMgmtUnits: [{value: 0, disabled: this.lockCPP}, []],
      totalUnits: [{ value: '', disabled: true }],
      createdById: ['', []],
      createdByName: ['', []],
      createdDate: ['', []],
      updatedById: ['', []],
      updatedByName: ['', []],
      updatedDate: ['', []],
      isActive:true
    }); 
    this.formPEF = this.fb.group({
      propertyID: ['', []],
      wncFlag: [{value: false, disabled: this.lockPEF}, []],
      cppFlag: [{value: false, disabled: this.lockPEF}, []],
      pefFlag: [{value: false, disabled: this.lockPEF}, []],
      name: [{value: '', disabled: this.lockPEF}, Validators.compose([Validators.required, Validators.minLength(2)])],
      partnershipName: [{value: '', disabled: this.lockPEF}, []],
      address: [{value: '', disabled: this.lockPEF}, Validators.compose([Validators.required, Validators.minLength(2)])],
      companyId: [{value: '', disabled: this.lockPEF}, Validators.required],
      city: [{value: '', disabled: this.lockPEF}, Validators.compose([Validators.required, Validators.minLength(2)])],
      state: [{value: '', disabled: this.lockPEF}, Validators.compose([Validators.required])],
      zip: [{value: '', disabled: this.lockPEF}, Validators.compose([Validators.required, Validators.minLength(2)])],
      tenantTypeID: [{value: '', disabled: this.lockPEF}, []],
      subsidyTypeID: [{value: '', disabled: this.lockPEF}, []],
      createdById: ['', []],
      createdByName: ['', []],
      createdDate: ['', []],
      updatedById: ['', []],
      updatedByName: ['', []],
      updatedDate: ['', []],
      isActive:true
    }); 
    this.formLock = this.fb.group({
      propertyLocked: [false, []],
    }); 
  }

  //BFS: 1.05 - initializing form into functions because of the disabling columns (erase this later)
  public wncForm() {
    this.formWNC = this.fb.group({
      propertyID: ['', []],
      wncFlag: [{value: false, disabled: this.disableWNC}, []],
      cppFlag: [{value: false, disabled: this.disableWNC}, []],
      pefFlag: [{value: false, disabled: this.disableWNC}, []],
      contactID: [{value: '', disabled: this.disableWNC}, []],
      name: [{value: '', disabled: this.disableWNC}, Validators.compose([Validators.required, Validators.minLength(2)])],
      partnershipName: [{value: '',  disabled: this.disableWNC}, []],
      address: [{value: '', disabled: this.disableWNC}, Validators.compose([Validators.required, Validators.minLength(2)])],
      companyId: [{value: '', disabled: this.disableWNC}, []],
      city: [{value: '', disabled: this.disableWNC}, Validators.compose([Validators.required, Validators.minLength(2)])],
      state: [{value: '', disabled: this.disableWNC}, Validators.compose([Validators.required])],
      zip: [{value: '', disabled: this.disableWNC}, Validators.compose([Validators.required, Validators.minLength(2)])],
      countyID: [{value: '', disabled: this.disableWNC}, []],
      residentialBuildings: [{value: '', disabled: this.disableWNC}, []],
      nonResidentialBuildings: [{value: '', disabled: this.disableWNC}, []],
      totalNumberOfBuildings: [{ value: '', disabled: true }],
      ageRestriction: [{value: '', disabled: this.lockWNC}, []],
      setAsideTypeID: [{value: '', disabled: this.disableWNC}, []],
      rehabTypeID: [{value: '', disabled: this.disableWNC}, []],
      tenantTypeID: [{value: '', disabled: this.disableWNC}, []],
      constructionTypeID: [{value: '', disabled: this.disableWNC}, []],
      lihtcUnits: [{value: '', disabled: this.disableWNC}, []],
      marketUnits: [{value: '', disabled: this.disableWNC}, []],
      exemptMgmtUnits: [{value: '', disabled: this.disableWNC}, []],
      homeUnits: [{value: '', disabled: this.lockWNC}, []],
      totalUnits: [{value: '', disabled: true}],
      rd: false,
      hud: false,
      home: false,
      teBond: false,
      cbdg: false,
      hodag: false,
      additionalFinancingSubsidyProgramsLayeredOther: ['', []],
      additionalTargeting: false,
      deepRentSkewing: false,
      createdById: ['', []],
      createdByName: ['', []],
      createdDate: ['', []],
      updatedById: ['', []],
      updatedByName: ['', []],
      updatedDate: ['', []],
      isActive:true
    });
  }

  //BFS: 1.05 - i thought i could enable and disable inputs like this by setting a var true or false but what happens is tht the value gets emptired out as well
  ////if i could find a way to just disable but leave the input values as is, then i could use this, else, this is useless (but just keeing it here anways just in case)
  public cppForm() {
    this.formCPP = this.fb.group({
      propertyID: ['', []],
      wncFlag: [{value: false, disabled: this.lockCPP}, []],
      cppFlag: [{value: false, disabled: this.lockCPP}, []],
      pefFlag: [{value: false, disabled: this.lockCPP}, []],
      contactID: [{value: false, disabled: this.lockCPP}, Validators.required],
      region: [{value: false, disabled: this.lockCPP}, Validators.required],
      propertyJointVenturePartnerID: [{value: false, disabled: this.lockCPP}, []],
      investorID: [{value: false, disabled: this.lockCPP}, []],
      name: [{value: false, disabled: this.lockCPP}, Validators.compose([Validators.required, Validators.minLength(2)])],
      partnershipName: [{value: false, disabled: this.lockCPP}, []],
      address: [{value: false, disabled: this.lockCPP}, Validators.compose([Validators.required, Validators.minLength(2)])],
      companyId: [{value: false, disabled: this.lockCPP}, Validators.required],
      city: [{value: false, disabled: this.lockCPP}, Validators.compose([Validators.required, Validators.minLength(2)])],
      state: [{value: false, disabled: this.lockCPP}, Validators.compose([Validators.required])],
      zip: [{value: false, disabled: this.lockCPP}, Validators.compose([Validators.required, Validators.minLength(2)])],
      tenantTypeID: [{value: false, disabled: this.lockCPP}, []],
      subsidyTypeID: [{value: false, disabled: this.lockCPP}, []],
      lihtcUnits: [{value: false, disabled: this.lockCPP}, []],
      marketUnits: [{value: false, disabled: this.lockCPP}, []],
      exemptMgmtUnits: [{value: false, disabled: this.lockCPP}, []],
      totalUnits: [{ value: '', disabled: true }],
      createdById: ['', []],
      createdByName: ['', []],
      createdDate: ['', []],
      updatedById: ['', []],
      updatedByName: ['', []],
      updatedDate: ['', []],
      isActive:true
    }); 
  }

  public pefForm() {
    this.formPEF = this.fb.group({
      propertyID: ['', []],
      wncFlag: [{value: false, disabled: this.lockPEF}, []],
      cppFlag: [{value: false, disabled: this.lockPEF}, []],
      pefFlag: [{value: false, disabled: this.lockPEF}, []],
      name: [{value: false, disabled: this.lockPEF}, Validators.compose([Validators.required, Validators.minLength(2)])],
      partnershipName: [{value: false, disabled: this.lockPEF}, []],
      address: [{value: false, disabled: this.lockPEF}, Validators.compose([Validators.required, Validators.minLength(2)])],
      companyId: [{value: false, disabled: this.lockPEF}, Validators.required],
      city: [{value: false, disabled: this.lockPEF}, Validators.compose([Validators.required, Validators.minLength(2)])],
      state: [{value: false, disabled: this.lockPEF}, Validators.compose([Validators.required])],
      zip: [{value: false, disabled: this.lockPEF}, Validators.compose([Validators.required, Validators.minLength(2)])],
      tenantTypeID: [{value: false, disabled: this.lockPEF}, []],
      subsidyTypeID: [{value: false, disabled: this.lockPEF}, []],
      createdById: ['', []],
      createdByName: ['', []],
      createdDate: ['', []],
      updatedById: ['', []],
      updatedByName: ['', []],
      updatedDate: ['', []],
      isActive:true
    }); 
  }
  //////////////////////////////////////////////////////////////////////////////////

  ngOnInit() {
    // console.log('%c this.property', 'background: yellow; color: black', this.property);
    // console.log('%c this.partnershiplist', 'background: yellow; color: black', this.partnershipList);
    // console.log('%c this.companyList', 'background: yellow; color: black', this.companies);
    this.formWNC.patchValue(this.property);
    this.formCPP.patchValue(this.property);
    this.formPEF.patchValue(this.property);
    this.formLock.patchValue(this.property);
    this.isAdd = !(this.property && this.property.id);
    this.dialogTitle = this.isAdd
      ? 'Add Property'
      : 'Update Property';

    //BFS: 1.04 - gets deep rent and additional
    this.getPropertyAdditionalDeepRent();
    this.startingConditions();
    this.filters();
  }

  public showWNCForm(){
    this.showWnc = true;
    this.showCpp = false;
    this.showPef = false;
  }

  public showCPPForm(){
    this.showWnc = false;
    this.showCpp = true;
    this.showPef = false;
    var totalUnitsVal = this.formCPP.controls['lihtcUnits'].value + this.formCPP.controls['marketUnits'].value + this.formCPP.controls['exemptMgmtUnits'].value;
    this.formCPP.controls.totalUnits.patchValue(totalUnitsVal, { emitEvent: false }); //, { emitEvent: false }
  }

  public showPEFForm(){
    this.showWnc = false;
    this.showCpp = false;
    this.showPef = true;
  }

  
  public startingConditions() {
    //BFS: 1.02 - this checks if ur a user or an admin. admins are both admins AND users so if you are only a user it disables certain inputs
    //but the admin can mess with the rest of the inputs
    this.isAdmin = this.authService.isAuthUserInRole("Admin");
    this.isUser = this.authService.isAuthUserInRole("User");
    this.userEntity = this.authService.authUserEntity();
    //console.log('this.isAdmin', this.isAdmin);
    if (this.isAdmin === true) {
      this.Admin = true;
    } else {
      this.Admin = false;
    }

    //BFS: 1.05 - for every entity make sure to have what happens if you open up a 1. wnc property 2. cpp property 3. pef property 4. wnc and a cpp 5. wnc and pef ****4 and 5 might not happen
    //4 and 5 might not happen because when creating a wnc property, its autofilled on selecting a partnership and the data is pulled. cpp are on its own, and pef r on its own

    //BFS: 1.05 - IF YOU ARE A WNC ENTITY************************************************************************************************
    if(this.userEntity){
      if (this.userEntity.indexOf('wnc') === 0) {
        //and you are opening a WNC property ADMIN OR NOT
        if((this.formWNC.controls["wncFlag"].value === true && this.formWNC.controls["cppFlag"].value === false && this.formWNC.controls["pefFlag"].value === false) ||
           (this.formWNC.controls["wncFlag"].value === true && this.formWNC.controls["cppFlag"].value === true && this.formWNC.controls["pefFlag"].value === false) || 
           (this.formWNC.controls["wncFlag"].value === true && this.formWNC.controls["cppFlag"].value === false && this.formWNC.controls["pefFlag"].value === true)){
          //whether its a WNC prop or a WNC&CPP prop, wnc admins get to see all of the data so its gotta show the WNC form
          this.showWNCForm();
          //because the form is getting disabled on when the form is set, just on the open we need to fill the total inputs with math
          this.totalBuildings = this.formWNC.controls['residentialBuildings'].value + this.formWNC.controls['nonResidentialBuildings'].value;
          this.formWNC.controls['totalNumberOfBuildings'].patchValue(this.totalBuildings); 
          this.totalUnits = this.formWNC.controls['lihtcUnits'].value + this.formWNC.controls['marketUnits'].value + this.formWNC.controls['exemptMgmtUnits'].value;
          this.formWNC.controls['totalUnits'].patchValue(this.totalUnits);
          
          
          //check to see if the property is locked. if it is disable the remaining inputs
          if(this.formLock.controls['propertyLocked'].value === true){
            this.formWNC.controls['ageRestriction'].disable();
            this.formWNC.controls['homeUnits'].disable();
            this.formWNC.controls['cbdg'].disable();
            this.formWNC.controls['hodag'].disable();
            this.formWNC.controls['additionalFinancingSubsidyProgramsLayeredOther'].disable();
            this.formWNC.controls['additionalTargeting'].disable();
            this.formWNC.controls['deepRentSkewing'].disable();
          }


        }

        //and you are opening a CPP property
        if(this.formCPP.controls["wncFlag"].value === false && this.formCPP.controls["cppFlag"].value === true && this.formCPP.controls["pefFlag"].value === false){
          //BFS: 1.10.4 - if its a CPP property we didn't pull all the data from EPIQ 2.0 so which means we have to use the CPP form because thats all the data they
          //have for that property
          this.showCPPForm();
          this.disableCPPFormWhenWNCAdminsOpenUpCPPProperties();
          //BFS: 1.10.4 - if you are a WNC admin looking at CPP properties, all fo the fields are disabled anywyas, there shouldn't be a lock and unlock function
        }
        //and you are opening a PEF property
        if(this.formPEF.controls["wncFlag"].value === false && this.formPEF.controls["cppFlag"].value === false && this.formPEF.controls["pefFlag"].value === true){
          this.showPEFForm();
          this.disablePEFFormWhenWNCAdminsOpenUpPEFProperties();
        }
        //and you are opening a property that has both CPP and PEF checked
        if(this.formPEF.controls["wncFlag"].value === false && this.formPEF.controls["cppFlag"].value === true && this.formPEF.controls["pefFlag"].value === true){
          this.showCPPForm();
          //BFS: 1.10.4 - going with the idea that compliance cannot make any changes to their properties except a few inputs, 
          //they shouldn't be able to update anything on either the CPP properties or the PEF properties. so all of the fields will be locked
          //ITS SHARING THIS FUNC TO DISABLE ALL THE INPUTS
          this.disableWNCFormPropertyForCPPUser();
        }
      }
  
      if (this.userEntity.indexOf('cpp') === 0) {
        this.showCPPForm();
        //and you are opening a WNC property
        if(this.formCPP.controls["wncFlag"].value === true && this.formCPP.controls["cppFlag"].value === false && this.formCPP.controls["pefFlag"].value === false){
          //BFS: 1.10.4 - following the WNC logic, if a CPP guy opens up a WNC property all of the fields need to be disabled
          this.disableWNCFormPropertyForCPPUser();
          this.hideWNCStuff = true;
          //for cpp admins, u can't see this form anyways so the lock function is disabled
        }
        //BFS: 1.10.4 - if you are opening up a WNC CPP shared property as a cpp user
        if(this.formCPP.controls["wncFlag"].value === true && this.formCPP.controls["cppFlag"].value === true && this.formCPP.controls["pefFlag"].value === false){
          //BFS: 1.10.4 - following the WNC logic, if a CPP guy opens up a WNC AND CPP property all fo the fields need to be disabled except for the contact and the subsidy
          this.disableWNCCPPFormPropertyForCPPUsers();
          if(this.formLock.controls["propertyLocked"].value === true){
            this.lockCPPWNCFormForCPPUsers();
          }
        }
        //and you are opening a CPP property
        if(this.formCPP.controls["wncFlag"].value === false && this.formCPP.controls["cppFlag"].value === true && this.formCPP.controls["pefFlag"].value === false){
          if(this.formLock.controls['propertyLocked'].value === true){
            //because when u r a cpp user, you get to see the cpp form which is a small portion of the wnc form so for cpp users, wnc form = cpp form
            //if the property is locked u can use this function to disable all of the fields
            this.disableWNCFormPropertyForCPPUser();
          }
        }
        //and you are opening a PEF property
        if(this.formCPP.controls["wncFlag"].value === false && this.formCPP.controls["cppFlag"].value === false && this.formCPP.controls["pefFlag"].value === true){
          this.showPEFForm();
          this.disablePEFFormWhenCPPAdminsOpenUpPEFProperties();
          //BFS: 1.10.4 - for cpp admins, u can't see this form anyways so the lock funtion is disabled
        }
        //and you are opening a property that has both CPP and PEF checked
        if(this.formCPP.controls["wncFlag"].value === false && this.formCPP.controls["cppFlag"].value === true && this.formCPP.controls["pefFlag"].value === true){
          this.disableCPPPEFFormProperty();
          if(this.formLock.controls['propertyLocked'].value === true){
            this.lockCPPPEFFormForCPPUsers();
          }
        }
      }
  
      if (this.userEntity.indexOf('pef') === 0) {
        //BFS: 1.10 - as of now PEF isn't involved in PM. therefore, we are setting all of the properties to only show the PEF form for now. this form will
        //change as soon as PEF gets invoved in PM
        this.showPEFForm();
        //and you are opening a WNC property
        if((this.formWNC.controls["wncFlag"].value === true && this.formCPP.controls["cppFlag"].value === false && this.formPEF.controls["pefFlag"].value === false) ||
           (this.formWNC.controls["wncFlag"].value === true && this.formCPP.controls["cppFlag"].value === true && this.formPEF.controls["pefFlag"].value === false)){
          this.disablePEFFormProperty();
        }
        //and you are opening a CPP property
        if(this.formWNC.controls["wncFlag"].value === false && this.formCPP.controls["cppFlag"].value === true && this.formPEF.controls["pefFlag"].value === false){
          this.disablePEFFormProperty();
        }
        //and you are opening a PEF property
        if(this.formWNC.controls["wncFlag"].value === false && this.formCPP.controls["cppFlag"].value === false && this.formPEF.controls["pefFlag"].value === true){
          this.disablePEFFormProperty();
        }
        //and you are opening a property that has both CPP and PEF checked
        if(this.formWNC.controls["wncFlag"].value === false && this.formCPP.controls["cppFlag"].value === true && this.formPEF.controls["pefFlag"].value === true){
          this.disablePEFFormProperty();
        }
      }
    }

    //BFS: 1.02 - if there is no 'name' then its a new form (see if you can check the form and if it IS NOT TOUCHED then do this)
    //start off as wncFlag as checked, if the prop is not wnc then they can change it to whatever they want
    if (!this.formWNC.controls['name'].value) {
      if(this.userEntity){
        if(this.userEntity.indexOf('wnc') === 0){
          this.showWnc = true;
          this.showCpp = false;
          this.showPef = false;
          this.disableWNC = false;
          this.wncForm();
          //BFS: 1.05 - because the function above fills the wnc form again, we need to call this bottom line AFTER the function to get the WNC checkbox checked
          this.formWNC.controls.wncFlag.patchValue(true);
        }
        if(this.userEntity.indexOf('cpp') === 0){
          this.formCPP.controls.cppFlag.patchValue(true);
          this.showWnc = false;
          this.showCpp = true;
          this.showPef = false;
        }
        if(this.userEntity.indexOf('pef') === 0){
          this.formPEF.controls.pefFlag.patchValue(true);
          this.showWnc = false;
          this.showCpp = false;
          this.showPef = true;
        }
      }
    }

    //BFS: 1.02 - if this value is checked true then show the additionalTargeting 
    if (this.formWNC.controls['additionalTargeting'].value === true) {
      this.additionalTargeting = true;
    }

    if (this.formWNC.controls['deepRentSkewing'].value === true) {
      this.deepRentSkewing = true;
    }

    if (this.formWNC.controls['state'].value != '') {
      this.onStateChange(this.formWNC.controls['state'].value);
    }

    //BFS: 1.04 - calculates the inputs
    this.calculateInputs();
  }

  //#region WNC Users for opening up different properties
  private disableCPPFormWhenWNCAdminsOpenUpCPPProperties(){
    this.formCPP.controls['wncFlag'].disable();
    this.formCPP.controls['cppFlag'].disable();
    this.formCPP.controls['pefFlag'].disable();
    this.formCPP.controls['cppAssetManagerContactID'].disable();
    this.formCPP.controls['contactID'].disable();
    this.formCPP.controls['region'].disable();
    if(this.formCPP.controls['propertyJointVenturePartnerID'].value === null){
      this.formCPP.controls['propertyJointVenturePartnerID'].patchValue('');
    }
    this.formCPP.controls['propertyJointVenturePartnerID'].disable();
    this.formCPP.controls['investorID'].disable();
    this.formCPP.controls['name'].disable();
    this.formCPP.controls['partnershipName'].disable();
    this.formCPP.controls['address'].disable();
    this.formCPP.controls['companyId'].disable();
    this.formCPP.controls['city'].disable();
    this.formCPP.controls['state'].disable();
    this.formCPP.controls['zip'].disable();
    this.formCPP.controls['tenantTypeID'].disable();
    this.formCPP.controls['subsidyTypeID'].disable();
    this.formCPP.controls['lihtcUnits'].disable();
    this.formCPP.controls['marketUnits'].disable();
    this.formCPP.controls['exemptMgmtUnits'].disable();
    var totalUnitsVal = this.formCPP.controls['lihtcUnits'].value + this.formCPP.controls['marketUnits'].value + this.formCPP.controls['exemptMgmtUnits'].value;
    this.formCPP.controls.totalUnits.patchValue(totalUnitsVal, { emitEvent: false }); //, { emitEvent: false }
  }

  private disablePEFFormWhenWNCAdminsOpenUpPEFProperties(){
    this.formPEF.controls['wncFlag'].disable();
    this.formPEF.controls['cppFlag'].disable();
    this.formPEF.controls['pefFlag'].disable();
    this.formPEF.controls['name'].disable();
    this.formPEF.controls['partnershipName'].disable();
    this.formPEF.controls['address'].disable();
    this.formPEF.controls['companyId'].disable();
    this.formPEF.controls['city'].disable();
    this.formPEF.controls['state'].disable();
    this.formPEF.controls['zip'].disable();
    this.formPEF.controls['tenantTypeID'].disable();
    this.formPEF.controls['subsidyTypeID'].disable();
  }

  //#endregion

  //#region CPP Users for opening up different properties
  private disableWNCFormPropertyForCPPUser(){
    this.formCPP.controls['wncFlag'].disable();
    this.formCPP.controls['cppFlag'].disable();
    this.formCPP.controls['pefFlag'].disable();
    this.formCPP.controls['cppAssetManagerContactID'].disable();
    this.formCPP.controls['contactID'].disable();
    this.formCPP.controls['region'].disable();
    if(this.formCPP.controls['propertyJointVenturePartnerID'].value === null){
      this.formCPP.controls['propertyJointVenturePartnerID'].patchValue('');
    }
    this.formCPP.controls['propertyJointVenturePartnerID'].disable();
    this.formCPP.controls['investorID'].disable();
    this.formCPP.controls['name'].disable();
    this.formCPP.controls['partnershipName'].disable();
    this.formCPP.controls['address'].disable();
    this.formCPP.controls['companyId'].disable();
    this.formCPP.controls['city'].disable();
    this.formCPP.controls['state'].disable();
    this.formCPP.controls['zip'].disable();
    this.formCPP.controls['tenantTypeID'].disable();
    this.formCPP.controls['subsidyTypeID'].disable();
    this.formCPP.controls['lihtcUnits'].disable();
    this.formCPP.controls['marketUnits'].disable();
    this.formCPP.controls['exemptMgmtUnits'].disable();
    var totalUnitsVal = this.formCPP.controls['lihtcUnits'].value + this.formCPP.controls['marketUnits'].value + this.formCPP.controls['exemptMgmtUnits'].value;
    this.formCPP.controls.totalUnits.patchValue(totalUnitsVal, { emitEvent: false }); //, { emitEvent: false }
  }
  
  private disableWNCCPPFormPropertyForCPPUsers(){
    this.formCPP.controls['wncFlag'].disable();
    this.formCPP.controls['cppFlag'].disable();
    this.formCPP.controls['pefFlag'].disable();
    if(this.formCPP.controls['propertyJointVenturePartnerID'].value === null){
      this.formCPP.controls['propertyJointVenturePartnerID'].patchValue('');
    }
    this.formCPP.controls['name'].disable();
    this.formCPP.controls['partnershipName'].disable();
    this.formCPP.controls['address'].disable();
    this.formCPP.controls['companyId'].disable();
    this.formCPP.controls['city'].disable();
    this.formCPP.controls['state'].disable();
    this.formCPP.controls['zip'].disable();
    this.formCPP.controls['tenantTypeID'].disable();
    this.formCPP.controls['lihtcUnits'].disable();
    this.formCPP.controls['marketUnits'].disable();
    this.formCPP.controls['exemptMgmtUnits'].disable();
    var totalUnitsVal = this.formCPP.controls['lihtcUnits'].value + this.formCPP.controls['marketUnits'].value + this.formCPP.controls['exemptMgmtUnits'].value;
    this.formCPP.controls.totalUnits.patchValue(totalUnitsVal, { emitEvent: false }); //, { emitEvent: false }
  }

  private disablePEFFormWhenCPPAdminsOpenUpPEFProperties(){
    this.formPEF.controls['wncFlag'].disable();
    this.formPEF.controls['cppFlag'].disable();
    this.formPEF.controls['pefFlag'].disable();
    this.formPEF.controls['name'].disable();
    this.formPEF.controls['partnershipName'].disable();
    this.formPEF.controls['address'].disable();
    this.formPEF.controls['companyId'].disable();
    this.formPEF.controls['city'].disable();
    this.formPEF.controls['state'].disable();
    this.formPEF.controls['zip'].disable();
    this.formPEF.controls['tenantTypeID'].disable();
    this.formPEF.controls['subsidyTypeID'].disable();
  }
  //#endregion

  private disablePEFFormProperty(){
    this.formPEF.controls['wncFlag'].disable();
    this.formPEF.controls['cppFlag'].disable();
    this.formPEF.controls['pefFlag'].disable();
    this.formPEF.controls['name'].disable();
    this.formPEF.controls['partnershipName'].disable();
    this.formPEF.controls['address'].disable();
    this.formPEF.controls['companyId'].disable();
    this.formPEF.controls['city'].disable();
    this.formPEF.controls['state'].disable();
    this.formPEF.controls['zip'].disable();
    this.formPEF.controls['tenantTypeID'].disable();
    this.formPEF.controls['subsidyTypeID'].disable();
  }

  private disableCPPPEFFormProperty(){
    this.formCPP.controls['wncFlag'].disable();
    this.formCPP.controls['cppFlag'].disable();
    this.formCPP.controls['pefFlag'].disable();
    //this.formCPP.controls['cppAssetManagerContactID'].disable();
    //this.formCPP.controls['contactID'].disable();
    //this.formCPP.controls['region'].disable();
    if(this.formCPP.controls['propertyJointVenturePartnerID'].value === null){
      this.formCPP.controls['propertyJointVenturePartnerID'].patchValue('');
    }
    //this.formCPP.controls['propertyJointVenturePartnerID'].disable();
    //this.formCPP.controls['investorID'].disable();
    this.formCPP.controls['name'].disable();
    this.formCPP.controls['partnershipName'].disable();
    this.formCPP.controls['address'].disable();
    this.formCPP.controls['companyId'].disable();
    this.formCPP.controls['city'].disable();
    this.formCPP.controls['state'].disable();
    this.formCPP.controls['zip'].disable();
    this.formCPP.controls['tenantTypeID'].disable();
    //this.formCPP.controls['subsidyTypeID'].disable();
    this.formCPP.controls['lihtcUnits'].disable();
    this.formCPP.controls['marketUnits'].disable();
    this.formCPP.controls['exemptMgmtUnits'].disable();
    var totalUnitsVal = this.formCPP.controls['lihtcUnits'].value + this.formCPP.controls['marketUnits'].value + this.formCPP.controls['exemptMgmtUnits'].value;
    this.formCPP.controls.totalUnits.patchValue(totalUnitsVal, { emitEvent: false }); //, { emitEvent: false }
  }

  public calculateInputs() {
    this.formWNC.valueChanges.subscribe(() => {
      var totalUnitsVal = this.formWNC.controls['lihtcUnits'].value + this.formWNC.controls['marketUnits'].value + this.formWNC.controls['exemptMgmtUnits'].value;
      this.formWNC.controls.totalUnits.patchValue(totalUnitsVal, { emitEvent: false }); //, { emitEvent: false }
      this.validateForm();
    });
    this.formCPP.valueChanges.subscribe((val:any) => {
      var totalUnitsVal = val.lihtcUnits + val.marketUnits + val.exemptMgmtUnits;
      this.formCPP.controls.totalUnits.patchValue(totalUnitsVal, { emitEvent: false }); //, { emitEvent: false }
      this.validateForm();
    });
  }

  // public calculateInputsTotalBuildings() {
  //   this.formWNC.valueChanges.subscribe(val => {
  //     var totalBuildingsVal = val.residentialBuildings + val.nonResidentialBuildings;
  //     //var totalBuildingsVal = this.form.controls['residentialBuildings'].value + this.form.controls['nonResidentialBuildings'].value;
  //     this.formWNC.controls.totalNumberOfBuildings.patchValue(totalBuildingsVal, { emitEvent: false })
  //     this.validateForm();
  //   })
  // }

  public validateForm() {
    Object.keys(this.formWNC.controls).forEach(key => {
      this.formWNC.controls[key].markAsTouched();
      this.formWNC.controls[key].updateValueAndValidity({ emitEvent: false });
    });
    Object.keys(this.formCPP.controls).forEach(key => {
      this.formCPP.controls[key].markAsTouched();
      this.formCPP.controls[key].updateValueAndValidity({ emitEvent: false });
    });
  }

  //#region Filters
  public filters() {
    this.filteredPartnershipListWNC = this.formWNC.controls['partnershipName'].valueChanges
      .pipe(
        startWith(''),
        map((value:any) => typeof value === 'string' ? value : value.partnershipName),
        map((partnershipName:string) => partnershipName ? this._partnershipFilter(partnershipName) : this.partnershipList.slice())
      );
    this.filteredPartnershipListCPP = this.formCPP.controls['partnershipName'].valueChanges
      .pipe(
        startWith(''),
        map((value:any) => typeof value === 'string' ? value : value.partnershipName),
        map((partnershipName:string) => partnershipName ? this._partnershipFilter(partnershipName) : this.partnershipList.slice())
      );
    this.filteredPartnershipListPEF = this.formPEF.controls['partnershipName'].valueChanges
      .pipe(
        startWith(''),
        map((value:any) => typeof value === 'string' ? value : value.partnershipName),
        map((partnershipName:string) => partnershipName ? this._partnershipFilter(partnershipName) : this.partnershipList.slice())
      );
    this.filteredPropertyJointVenturePartnerList = this.formCPP.controls['propertyJointVenturePartnerID'].valueChanges
      .pipe(
        startWith<string | PropertyJointVenturePartnerList>(''),
        map((value:any) => typeof value === 'string' ? value : value.propertyJointVenturePartnerName),
        map((propertyJointVenturePartnerName:string) => propertyJointVenturePartnerName ? this._propertyJointVenturePartnerFilter(propertyJointVenturePartnerName) : this.propertyJointVenturePartnerList.slice())
      );
    this.filteredCompanyListWNC = this.formWNC.controls['companyId'].valueChanges
      .pipe(
        startWith<string | CompanySimple>(''),
        map((value:any) => typeof value === 'string' ? value : value.name),
        map((name:string) => name ? this._companyFilter(name) : this.companies.slice())
      );
    this.filteredCompanyListCPP = this.formCPP.controls['companyId'].valueChanges
      .pipe(
        startWith<string | CompanySimple>(''),
        map((value:any) => typeof value === 'string' ? value : value.name),
        map((name:string) => name ? this._companyFilter(name) : this.companies.slice())
      );
    this.filteredCompanyListPEF = this.formPEF.controls['companyId'].valueChanges
      .pipe(
        startWith<string | CompanySimple>(''),
        map((value:any) => typeof value === 'string' ? value : value.name),
        map((name:string) => name ? this._companyFilter(name) : this.companies.slice())
      );
    this.filteredInvestorList = this.formCPP.controls['investorID'].valueChanges
      .pipe(
        startWith<string | InvestorList>(''),
        map((value:any) => typeof value === 'string' ? value : value.investorName),
        map((investorName:string) => investorName ? this._investorFilter(investorName) : this.investorList.slice())
      );
  }

  public displayFn(options: PropertyJointVenturePartnerList[] | null): (propertyJointVenturePartnerID: number) => string | null {
    return (propertyJointVenturePartnerID: number) => {
      const correspondingOption = Array.isArray(options) ? options.find(option => option.propertyJointVenturePartnerID === propertyJointVenturePartnerID) : null;
      return correspondingOption ? correspondingOption.propertyJointVenturePartnerName : '';
    }
  }

  public displayFnCompany(options: CompanySimple[] | null): (companyId: number) => string | null {
    return (companyId: number) => {
      const correspondingOption = Array.isArray(options) ? options.find(option => option.companyId === companyId) : null;
      return correspondingOption ? correspondingOption.name : '';
    }
  }

  public displayFnInvestor(options: InvestorList[] | null): (investorID: number) => string | null {
    return (investorID: number) => {
      const correspondingOption = Array.isArray(options) ? options.find(option => option.investorID === investorID) : null;
      return correspondingOption ? correspondingOption.investorName : '';
    }
  }

  private _partnershipFilter(partnershipName: string): PartnershipList[] {
    const filterValue = partnershipName.toLowerCase();
    return this.partnershipList.filter(option => option.partnershipName.toLowerCase().indexOf(filterValue) === 0);
  }

  private _propertyJointVenturePartnerFilter(propertyJointVenturePartnerName: string): PropertyJointVenturePartnerList[] {
    const filterValue = propertyJointVenturePartnerName.toLowerCase();
    return this.propertyJointVenturePartnerList.filter(option => option.propertyJointVenturePartnerName.toLowerCase().indexOf(filterValue) === 0);
  }

  private _companyFilter(name: string): CompanySimple[] {
    const filterValue = name.toLowerCase();
    return this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _investorFilter(investorName: string): InvestorList[] {
    const filterValue = investorName.toLowerCase();
    return this.investorList.filter(option => option.investorName.toLowerCase().indexOf(filterValue) === 0);
  }
  //#endregion
  
  public getPropertyAdditionalDeepRent() {
    this.propertyService.getPropertyAdditionalTargetings(this.property.id)
      .subscribe((data) => {
        this.propertyAdditionalTargetings = data;
      });
    this.propertyService.getPropertyDeepRentSkewings(this.property.id)
      .subscribe((data) => {
        this.propertyDeepRentSkewings = data;
      });
  }

  public onStateChange(event: string) {
    this.propertyService.getCounties(event)
      .subscribe((data) => {
        this.counties = data;
      });
  }

  //BFS: 1.05 - on a new form, when you click on a partnership that is linked to a compliance analyst and some other parameters then it grabs all the info from EPIQ and returns an object
  //because on change brings in a string, it'll go through and find the propertyID then use the PropertyID to grab the info
  public onPartnershipChange(event: string) {
    for (let i = 0; i < this.partnershipList.length; i++) {
      if(this.partnershipList[i].partnershipName === event){
        this.onSelectPropertyID = this.partnershipList[i].propertyID
        this.getPropertyOnPartnershipSelect(this.onSelectPropertyID);
        console.log('%c this.onSelectPropertyID', 'background: yellow; color: black', this.onSelectPropertyID);
      }
    }
  }

  public onPartnershipChangePEF(event: string) {
    for (let i = 0; i < this.partnershipList.length; i++) {
      if(this.partnershipList[i].partnershipName === event){
        this.onSelectPropertyID = this.partnershipList[i].propertyID
        this.getPropertyOnPartnershipSelectPEF(this.onSelectPropertyID);
      }
    }
  }

  public getPropertyOnPartnershipSelect (id: number) {
    this.propertyService.getPropertyOnPartnershipSelect(id)
      .subscribe((data) => {
        this.propertyOnPartnershipSelect = data;
        if (this.propertyOnPartnershipSelect === null) {
          //BFS: 1.05 - tried with resetting the form but then if the PropertyID doesn't match, it'll reset the form and empty out 
          //the partnership. Clean this up when you get better brian
          // this.form.reset();
          // this.startingConditions();
          // this.filters();
          this.formWNC.controls['wncFlag'].enable();
          this.formWNC.controls['wncFlag'].patchValue(true);
          this.formWNC.controls['cppFlag'].enable();
          this.formWNC.controls['cppFlag'].patchValue(false);
          this.formWNC.controls['pefFlag'].enable();
          this.formWNC.controls['pefFlag'].patchValue(false);
          this.formWNC.controls['contactID'].enable();
          this.formWNC.controls['contactID'].patchValue(null);
          this.formWNC.controls['name'].enable();
          this.formWNC.controls['name'].patchValue(null);
          this.formWNC.controls['address'].enable();
          this.formWNC.controls['address'].patchValue(null);
          this.formWNC.controls['companyId'].enable();
          this.formWNC.controls['companyId'].patchValue('');
          this.formWNC.controls['city'].enable();
          this.formWNC.controls['city'].patchValue(null);
          this.formWNC.controls['state'].enable();
          this.formWNC.controls['state'].patchValue(null);
          this.formWNC.controls['zip'].enable();
          this.formWNC.controls['zip'].patchValue(null);
          this.formWNC.controls['countyID'].enable();
          this.formWNC.controls['countyID'].patchValue(null);
          this.formWNC.controls['residentialBuildings'].enable();
          this.formWNC.controls['residentialBuildings'].patchValue(0);
          this.formWNC.controls['nonResidentialBuildings'].enable();
          this.formWNC.controls['nonResidentialBuildings'].patchValue(0);
          this.formWNC.controls['setAsideTypeID'].enable();
          this.formWNC.controls['setAsideTypeID'].patchValue(null);
          this.formWNC.controls['tenantTypeID'].enable();
          this.formWNC.controls['tenantTypeID'].patchValue(null);
          this.formWNC.controls['constructionTypeID'].enable();
          this.formWNC.controls['constructionTypeID'].patchValue(null)
          this.formWNC.controls['rehabTypeID'].enable();
          this.formWNC.controls['rehabTypeID'].patchValue(null);
          this.formWNC.controls['lihtcUnits'].enable();
          this.formWNC.controls['lihtcUnits'].patchValue(0);
          this.formWNC.controls['marketUnits'].enable();
          this.formWNC.controls['marketUnits'].patchValue(0);
          this.formWNC.controls['exemptMgmtUnits'].enable();
          this.formWNC.controls['exemptMgmtUnits'].patchValue(0);
          this.formWNC.controls['homeUnits'].patchValue(0);
          this.formWNC.controls['rd'].patchValue(false);
          this.formWNC.controls['rd'].enable();
          this.formWNC.controls['hud'].patchValue(false);
          this.formWNC.controls['hud'].enable();
          this.formWNC.controls['home'].patchValue(false);
          this.formWNC.controls['home'].enable();
          this.formWNC.controls['teBond'].patchValue(false);
          this.formWNC.controls['teBond'].enable();
          this.formWNC.controls['additionalFinancingSubsidyProgramsLayeredOther'].patchValue(null);
          this.formWNC.controls['additionalFinancingSubsidyProgramsLayeredOther'].enable();
        } else {
        this.populateNewPropertyForm(this.propertyOnPartnershipSelect);
        this.getPropertyRentSubsidyOnPartnershipSelect(this.onSelectPropertyID);
        this.getPropertyLoanAndSubsidyGrantTypeOnPartnershipSelect(this.onSelectPropertyID);
      }
    })
  }

  public getPropertyOnPartnershipSelectPEF (id: number) {
    this.propertyService.getPropertyOnPartnershipSelect(id)
      .subscribe((data) => {
        data.wncFlag = false;
        this.propertyOnPartnershipSelect = data;
        if (this.propertyOnPartnershipSelect === null) {
          this.formWNC.controls['wncFlag'].enable();
          this.formWNC.controls['wncFlag'].patchValue(false);
          this.formWNC.controls['cppFlag'].enable();
          this.formWNC.controls['cppFlag'].patchValue(false);
          this.formWNC.controls['pefFlag'].enable();
          this.formWNC.controls['pefFlag'].patchValue(true);
          this.formWNC.controls['name'].enable();
          this.formWNC.controls['name'].patchValue(null);
          this.formWNC.controls['address'].enable();
          this.formWNC.controls['address'].patchValue(null);
          this.formWNC.controls['companyId'].enable();
          this.formWNC.controls['companyId'].patchValue('');
          this.formWNC.controls['city'].enable();
          this.formWNC.controls['city'].patchValue(null);
          this.formWNC.controls['state'].enable();
          this.formWNC.controls['state'].patchValue(null);
          this.formWNC.controls['zip'].enable();
          this.formWNC.controls['zip'].patchValue(null);
          //this.formWNC.controls['countyID'].enable();
        } else {
        this.populateNewPropertyFormPEF(this.propertyOnPartnershipSelect);
        // this.getPropertyRentSubsidyOnPartnershipSelect(this.onSelectPropertyID);
        // this.getPropertyLoanAndSubsidyGrantTypeOnPartnershipSelect(this.onSelectPropertyID);
      }
    })
  }

  public getPropertyRentSubsidyOnPartnershipSelect (id: number){
    this.propertyService.getPropertyRentSubsidyOnPartnershipSelect(id)
      .subscribe((data) => {
        this.propertyRentSubsidyOnPartnershipSelect = data;
        this.populateNewPropertyRentSubsidyForm(this.propertyRentSubsidyOnPartnershipSelect);
      })
  }

  public getPropertyLoanAndSubsidyGrantTypeOnPartnershipSelect (id: number){
    this.propertyService.getPropertyLoanAndSubsidyGrantTypeOnPartnershipSelect(id)
      .subscribe((data) => {
        this.propertyLoanAndSubsidyGrantTypeOnPartnershipSelect = data;
        this.populateNewPropertyLoanAndSubsidyGrantForm(this.propertyLoanAndSubsidyGrantTypeOnPartnershipSelect)
      })
  }
  //BFS: 1.05 - after it gets the object, it fills it in the form here
  public populateNewPropertyForm (propertyObject: object) {
    this.formWNC.patchValue(propertyObject);
    // var totalBuildingsVal = this.formWNC.controls['residentialBuildings'].value + this.formWNC.controls['nonResidentialBuildings'].value;
    // this.formWNC.controls.totalNumberOfBuildings.patchValue(totalBuildingsVal, { emitEvent: false })

    this.formWNC.controls['wncFlag'].disable();
    this.formWNC.controls['cppFlag'].disable();
    this.formWNC.controls['pefFlag'].disable();
    this.formWNC.controls['contactID'].disable();
    this.formWNC.controls['name'].disable();
    this.formWNC.controls['address'].disable();
    this.formWNC.controls['companyId'].disable();
    this.formWNC.controls['city'].disable();
    this.formWNC.controls['state'].disable();
    this.formWNC.controls['zip'].disable();
    this.formWNC.controls['countyID'].disable();
    this.formWNC.controls['residentialBuildings'].disable();
    this.formWNC.controls['nonResidentialBuildings'].disable();
    this.formWNC.controls['setAsideTypeID'].disable();
    this.formWNC.controls['tenantTypeID'].disable();
    this.formWNC.controls['constructionTypeID'].disable();
    this.formWNC.controls['rehabTypeID'].disable();
    this.formWNC.controls['lihtcUnits'].disable();
    this.formWNC.controls['marketUnits'].disable();
    this.formWNC.controls['exemptMgmtUnits'].disable();
  }

  public populateNewPropertyFormPEF (propertyObject: object) {
    this.formPEF.patchValue(propertyObject);
    // var totalBuildingsVal = this.formWNC.controls['residentialBuildings'].value + this.formWNC.controls['nonResidentialBuildings'].value;
    // this.formWNC.controls.totalNumberOfBuildings.patchValue(totalBuildingsVal, { emitEvent: false })

    this.formPEF.controls['wncFlag'].disable();
    this.formPEF.controls['cppFlag'].disable();
    this.formPEF.controls['pefFlag'].disable();
    this.formPEF.controls['name'].disable();
    this.formPEF.controls['address'].disable();
    this.formPEF.controls['companyId'].disable();
    this.formPEF.controls['city'].disable();
    this.formPEF.controls['state'].disable();
    this.formPEF.controls['zip'].disable();
  }

  public populateNewPropertyRentSubsidyForm (propertyArray: any) {
    this.rd = false;
    this.hud = false;
    this.other = null;
    this.formWNC.controls['rd'].patchValue(false);
    this.formWNC.controls['rd'].enable();
    this.formWNC.controls['hud'].patchValue(false);
    this.formWNC.controls['hud'].enable();
    this.formWNC.controls['additionalFinancingSubsidyProgramsLayeredOther'].patchValue(null);
    this.formWNC.controls['additionalFinancingSubsidyProgramsLayeredOther'].enable();

    for (let i = 0; i < propertyArray.length; i++) {
      if(propertyArray[i].rentSubsidyType === 'RD'){
        this.rd = true;
      }
      if(propertyArray[i].rentSubsidyType === 'HUD'){
        this.hud = true;
      }
      if(propertyArray[i].rentSubsidyType === 'Other'){
        this.other = propertyArray[i].otherRentSubsidyType;
      }
    }

    if(this.rd === true){
      this.formWNC.controls['rd'].patchValue(true);
      this.formWNC.controls['rd'].disable();
    } else {
      this.formWNC.controls['rd'].patchValue(false);
      this.formWNC.controls['rd'].disable();
    }

    if(this.hud === true){
      this.formWNC.controls['hud'].patchValue(true);
      this.formWNC.controls['hud'].disable();
    } else {
      this.formWNC.controls['hud'].patchValue(false);
      this.formWNC.controls['hud'].disable();
    }

    if(this.other === null || this.other === undefined || this.other === ''){
      this.formWNC.controls['additionalFinancingSubsidyProgramsLayeredOther'].enable();
      this.formWNC.controls['additionalFinancingSubsidyProgramsLayeredOther'].patchValue(null);
    } else {
      this.formWNC.controls['additionalFinancingSubsidyProgramsLayeredOther'].patchValue(this.other);
      this.formWNC.controls['additionalFinancingSubsidyProgramsLayeredOther'].disable();
    }
  }

  public populateNewPropertyLoanAndSubsidyGrantForm (propertyArray: any){
    this.teBond = false;
    this.home = false;
    this.formWNC.controls['teBond'].patchValue(false);
    this.formWNC.controls['teBond'].enable();
    this.formWNC.controls['home'].patchValue(false);
    this.formWNC.controls['home'].enable();

    for (let i = 0; i < propertyArray.length; i++) {
      if(propertyArray[i].lienType === 'Tax Exempt Bond'){
        this.teBond = true;
      }
      if(propertyArray[i].subsidyGrantType === 'HOME'){
        this.home = true;
      }
    }

    if(this.home === true){
      this.formWNC.controls['home'].patchValue(true);
      this.formWNC.controls['home'].disable();
    } else {
      this.formWNC.controls['home'].patchValue(false);
      this.formWNC.controls['home'].disable();
    }

    if(this.teBond === true){
      this.formWNC.controls['teBond'].patchValue(true);
      this.formWNC.controls['teBond'].disable();
    } else {
      this.formWNC.controls['teBond'].patchValue(false);
      this.formWNC.controls['teBond'].enable();
    }
  }

  public lock (){
    if(this.userEntity){
      if (this.userEntity.indexOf('wnc') === 0) {
        if((this.formWNC.controls["wncFlag"].value === true && this.formWNC.controls["cppFlag"].value === false && this.formWNC.controls["pefFlag"].value === false) ||
           (this.formWNC.controls["wncFlag"].value === true && this.formWNC.controls["cppFlag"].value === true && this.formWNC.controls["pefFlag"].value === false)){
            this.lockWNCFormForWNCUsers();
        }
      }
      if (this.userEntity.indexOf('cpp') === 0) {
        //BFS: 1.10.4 - if ur just locking cpp properites lock all
        if(this.formCPP.controls["wncFlag"].value === false && this.formCPP.controls["cppFlag"].value === true && this.formCPP.controls["pefFlag"].value === false){
          this.lockCPPFormForCPPUsers();
        }
        //BFS: 1.10.4 - if ur just locking cpp&wnc properites lock some
        if(this.formCPP.controls["wncFlag"].value === true && this.formCPP.controls["cppFlag"].value === true && this.formCPP.controls["pefFlag"].value === false){
          this.lockCPPWNCFormForCPPUsers();
        }
        //BFS: 1.10.4 - if ur just locking cpp&pef properites lock some
        if(this.formCPP.controls["wncFlag"].value === false && this.formCPP.controls["cppFlag"].value === true && this.formCPP.controls["pefFlag"].value === true){
          this.lockCPPPEFFormForCPPUsers();
        }
      }
      if (this.userEntity.indexOf('pef') === 0) {
        //BFS: 1.10.4 - nothing yet
      }
    }
  }
  
  public unlock () {
    if(this.userEntity){
      if (this.userEntity.indexOf('wnc') === 0) {
        if((this.formWNC.controls["wncFlag"].value === true && this.formWNC.controls["cppFlag"].value === false && this.formWNC.controls["pefFlag"].value === false) ||
           (this.formWNC.controls["wncFlag"].value === true && this.formWNC.controls["cppFlag"].value === true && this.formWNC.controls["pefFlag"].value === false)){
            this.unlockWNCFormForWNCUsers();
        }
      }
      if (this.userEntity.indexOf('cpp') === 0) {
        if(this.formCPP.controls["wncFlag"].value === false && this.formCPP.controls["cppFlag"].value === true && this.formCPP.controls["pefFlag"].value === false){
          this.unlockCPPFormForCPPUsers();
        }
        if(this.formCPP.controls["wncFlag"].value === true && this.formCPP.controls["cppFlag"].value === true && this.formCPP.controls["pefFlag"].value === false){
          this.unlockCPPWNCFormForCPPUsers();
        }
        if(this.formCPP.controls["wncFlag"].value === false && this.formCPP.controls["cppFlag"].value === true && this.formCPP.controls["pefFlag"].value === true){
          this.unlockCPPPEFFormForCPPUsers();
        }

      }
      if (this.userEntity.indexOf('pef') === 0) {
        //BFS: 1.10.4 - nothing yet
      }
    }
  }
  
  //#region Locking and unlocking for WNC users
  private lockWNCFormForWNCUsers(){
    this.formWNC.controls['ageRestriction'].disable();
    this.formWNC.controls['homeUnits'].disable();
    this.formWNC.controls['cbdg'].disable();
    this.formWNC.controls['hodag'].disable();
    this.formWNC.controls['additionalFinancingSubsidyProgramsLayeredOther'].disable();
    this.formWNC.controls['additionalTargeting'].disable();
    this.formWNC.controls['deepRentSkewing'].disable();
    this.formLock.controls['propertyLocked'].patchValue(true);
  }

  private unlockWNCFormForWNCUsers(){
    this.formWNC.controls['ageRestriction'].enable();
    this.formWNC.controls['homeUnits'].enable();
    this.formWNC.controls['cbdg'].enable();
    this.formWNC.controls['hodag'].enable();
    this.formWNC.controls['additionalFinancingSubsidyProgramsLayeredOther'].enable();
    this.formWNC.controls['additionalTargeting'].enable();
    this.formWNC.controls['deepRentSkewing'].enable();
    this.formLock.controls['propertyLocked'].patchValue(false);
  }
  //#endregion

  //#region Locking and unlocking for CPP users
  private lockCPPFormForCPPUsers(){
    this.formCPP.controls['wncFlag'].disable();
    this.formCPP.controls['cppFlag'].disable();
    this.formCPP.controls['pefFlag'].disable();
    this.formCPP.controls['cppAssetManagerContactID'].disable();
    this.formCPP.controls['region'].disable();
    if(this.formCPP.controls['propertyJointVenturePartnerID'].value === null){
      this.formCPP.controls['propertyJointVenturePartnerID'].patchValue('');
    }
    this.formCPP.controls['propertyJointVenturePartnerID'].disable();
    this.formCPP.controls['investorID'].disable();
    this.formCPP.controls['name'].disable();
    this.formCPP.controls['partnershipName'].disable();
    this.formCPP.controls['address'].disable();
    this.formCPP.controls['companyId'].disable();
    this.formCPP.controls['city'].disable();
    this.formCPP.controls['state'].disable();
    this.formCPP.controls['zip'].disable();
    this.formCPP.controls['tenantTypeID'].disable();
    this.formCPP.controls['subsidyTypeID'].disable();
    this.formCPP.controls['lihtcUnits'].disable();
    this.formCPP.controls['marketUnits'].disable();
    this.formCPP.controls['exemptMgmtUnits'].disable();
    this.formLock.controls['propertyLocked'].patchValue(true);
    var totalUnitsVal = this.formCPP.controls['lihtcUnits'].value + this.formCPP.controls['marketUnits'].value + this.formCPP.controls['exemptMgmtUnits'].value;
    this.formCPP.controls.totalUnits.patchValue(totalUnitsVal, { emitEvent: false }); //, { emitEvent: false }
  }

  private unlockCPPFormForCPPUsers(){
    this.formCPP.controls['wncFlag'].enable();
    this.formCPP.controls['cppFlag'].enable();
    this.formCPP.controls['pefFlag'].enable();
    this.formCPP.controls['cppAssetManagerContactID'].enable();
    this.formCPP.controls['region'].enable();
    this.formCPP.controls['propertyJointVenturePartnerID'].enable();
    this.formCPP.controls['investorID'].enable();
    this.formCPP.controls['name'].enable();
    this.formCPP.controls['partnershipName'].enable();
    this.formCPP.controls['address'].enable();
    this.formCPP.controls['companyId'].enable();
    this.formCPP.controls['city'].enable();
    this.formCPP.controls['state'].enable();
    this.formCPP.controls['zip'].enable();
    this.formCPP.controls['tenantTypeID'].enable();
    this.formCPP.controls['subsidyTypeID'].enable();
    this.formCPP.controls['lihtcUnits'].enable();
    this.formCPP.controls['marketUnits'].enable();
    this.formCPP.controls['exemptMgmtUnits'].enable();
    this.formLock.controls['propertyLocked'].patchValue(false);
  }

  private lockCPPWNCFormForCPPUsers(){
    this.formCPP.controls['cppAssetManagerContactID'].disable();
    this.formCPP.controls['region'].disable();
    if(this.formCPP.controls['propertyJointVenturePartnerID'].value === null){
      this.formCPP.controls['propertyJointVenturePartnerID'].patchValue('');
    }
    this.formCPP.controls['propertyJointVenturePartnerID'].disable();
    this.formCPP.controls['investorID'].disable();
    this.formCPP.controls['subsidyTypeID'].disable();
    this.formLock.controls['propertyLocked'].patchValue(true);
    var totalUnitsVal = this.formCPP.controls['lihtcUnits'].value + this.formCPP.controls['marketUnits'].value + this.formCPP.controls['exemptMgmtUnits'].value;
    this.formCPP.controls.totalUnits.patchValue(totalUnitsVal, { emitEvent: false }); //, { emitEvent: false }
  }

  private unlockCPPWNCFormForCPPUsers(){
    this.formCPP.controls['cppAssetManagerContactID'].enable();
    this.formCPP.controls['region'].enable();
    this.formCPP.controls['propertyJointVenturePartnerID'].enable();
    this.formCPP.controls['investorID'].enable();
    this.formCPP.controls['subsidyTypeID'].enable();
    this.formLock.controls['propertyLocked'].patchValue(false);
    var totalUnitsVal = this.formCPP.controls['lihtcUnits'].value + this.formCPP.controls['marketUnits'].value + this.formCPP.controls['exemptMgmtUnits'].value;
    this.formCPP.controls.totalUnits.patchValue(totalUnitsVal, { emitEvent: false }); //, { emitEvent: false }
  }

  private lockCPPPEFFormForCPPUsers(){
    this.formCPP.controls['cppAssetManagerContactID'].disable();
    this.formCPP.controls['region'].disable();
    if(this.formCPP.controls['propertyJointVenturePartnerID'].value === null){
      this.formCPP.controls['propertyJointVenturePartnerID'].patchValue('');
    }
    this.formCPP.controls['propertyJointVenturePartnerID'].disable();
    this.formCPP.controls['investorID'].disable();
    this.formCPP.controls['subsidyTypeID'].disable();
    this.formLock.controls['propertyLocked'].patchValue(true);
    var totalUnitsVal = this.formCPP.controls['lihtcUnits'].value + this.formCPP.controls['marketUnits'].value + this.formCPP.controls['exemptMgmtUnits'].value;
    this.formCPP.controls.totalUnits.patchValue(totalUnitsVal, { emitEvent: false }); //, { emitEvent: false }
  }

  private unlockCPPPEFFormForCPPUsers(){
    this.formCPP.controls['cppAssetManagerContactID'].enable();
    this.formCPP.controls['region'].enable();
    this.formCPP.controls['propertyJointVenturePartnerID'].enable();
    this.formCPP.controls['investorID'].enable();
    this.formCPP.controls['subsidyTypeID'].enable();
    this.formLock.controls['propertyLocked'].patchValue(false);
    var totalUnitsVal = this.formCPP.controls['lihtcUnits'].value + this.formCPP.controls['marketUnits'].value + this.formCPP.controls['exemptMgmtUnits'].value;
    this.formCPP.controls.totalUnits.patchValue(totalUnitsVal, { emitEvent: false }); //, { emitEvent: false }
  }
  //#endregion

  public notify (){

    this.userService.getMe().subscribe(val =>{
      var fullname = this.property.assignedTo.split(' ')
      var email = fullname[0].charAt(0) + fullname[1] + '@wncinc.com'
      var notifyModel: ComplianceNotification = {emailType: 'Property', user: val.email, name: this.property.assignedTo, toAddress: email, property: this.property.name, building:'', unit:''}
      this.unitDetailService.notifyCompliance(notifyModel).subscribe(()=>{
      })
    });

    
  }

  save() {
    if(this.showWnc === true){
      if (this.formWNC.invalid) {
        this.isFailedForm = true;
      } else {
        const formValue = this.formWNC.getRawValue();
        const formValue2 = this.formLock.getRawValue();
        let replacedData: any = {};

        Object.keys(formValue).forEach((key: string) => {
          formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
        })
        Object.keys(formValue2).forEach((key: string) => {
          formValue2[key] == null ? replacedData[key] = '' : replacedData[key] = formValue2[key]
        })

        const property = Object.assign({}, this.property, replacedData);

        //BFS: 1.02 - when clicking "save" on the modal it sends the array of objects back to the parent only if there are stuff in the array
        if (this.newAdditionalTargetings.length > 0) {
          this.passEntry.emit(this.newAdditionalTargetings);
        }
        if (this.newDeepRentSkewings.length > 0) {
          this.passEntryDeep.emit(this.newDeepRentSkewings);
        }

        this.PropertyID = null;

        for (var i = 0; i < this.partnershipList.length; i++) {
          if (this.partnershipList[i].partnershipName === property.partnershipName) {
            this.PropertyID = this.partnershipList[i].propertyID;
          }
        }

        property.propertyID = this.PropertyID;

        if (property.propertyID === null) {
          const title = 'Partnerhip list';
          const message = `Please pick a partnerhip from the list.`; //${ property.name }?
          this.dialogsService.confirmation(title, message)
            .then(() => {

            }, () => { });
        } else {
          this.activeModal.close(property);
        }
      }
    }

    if(this.showCpp === true){
      if (this.formCPP.invalid) {
        this.isFailedForm = true;
      } else {
        const formValue = this.formCPP.getRawValue();
        const formValue2 = this.formLock.getRawValue();

        let replacedData: any = {};
        Object.keys(formValue).forEach((key: string) => {
          formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
        })
        Object.keys(formValue2).forEach((key: string) => {
          formValue2[key] == null ? replacedData[key] = '' : replacedData[key] = formValue2[key]
        })

        const property = Object.assign({}, this.property, replacedData);

        this.PropertyID = null;
        
        for (var i = 0; i < this.partnershipList.length; i++) {
          if (this.partnershipList[i].partnershipName === property.partnershipName) {
            this.PropertyID = this.partnershipList[i].propertyID;
          }
        }

        property.propertyID = this.PropertyID;
        //BFS: 1.06 - 7.15.2020 this closes the model and goes to the parent page to save
        this.activeModal.close(property);

        //BFS: 1.06 - 7.15.2020 what it did b4 was to check to make sure the propertyID isn't null then it'll send the info to the parent page.
        //if there is no propertyID (meaning there was no partnership that was on the list that was checked) then it would throw an error saying
        //to pick a partnership from the list. now it just sends the text of the partnersihp and saves it 
        // if (property.propertyID === null) {
        //   const title = 'Partnerhip list';
        //   const message = `Please pick a partnerhip from the list.`; //${ property.name }?
        //   this.dialogsService.confirmation(title, message)
        //     .then(() => {

        //     }, () => { });
        // } else {
        //   this.activeModal.close(property);
        // }
      }
    }

    if(this.showPef === true){
      if (this.formPEF.invalid) {
        this.isFailedForm = true;
      } else {
        const formValue = this.formPEF.getRawValue();
        const formValue2 = this.formLock.getRawValue();

        let replacedData: any = {};
        Object.keys(formValue).forEach((key: string) => {
          formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
        })
        Object.keys(formValue2).forEach((key: string) => {
          formValue2[key] == null ? replacedData[key] = '' : replacedData[key] = formValue2[key]
        })

        const property = Object.assign({}, this.property, replacedData);

        this.PropertyID = null;

        for (var i = 0; i < this.partnershipList.length; i++) {
          if (this.partnershipList[i].partnershipName === property.partnershipName) {
            this.PropertyID = this.partnershipList[i].propertyID;
          }
        }

        property.propertyID = this.PropertyID;

        if (property.propertyID === null) {
          const title = 'Partnerhip list';
          const message = `Please pick a partnerhip from the list.`; //${ property.name }?
          this.dialogsService.confirmation(title, message)
            .then(() => {

            }, () => { });
        } else {
          this.activeModal.close(property);
        }
      }
    }

    // if (this.form.invalid) {
    //   this.isFailedForm = true;
    // } else {
    //   const formValue = this.form.getRawValue();
    //   let replacedData: any = {};
    //   Object.keys(formValue).forEach((key: string) => {
    //     formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
    //   })
    //   const property = Object.assign({}, this.property, replacedData);

    //   //BFS: 1.02 - when clicking "save" on the modal it sends the array of objects back to the parent only if there are stuff in the array
    //   if (this.newAdditionalTargetings.length > 0) {
    //     this.passEntry.emit(this.newAdditionalTargetings);
    //   }
    //   if (this.newDeepRentSkewings.length > 0) {
    //     this.passEntryDeep.emit(this.newDeepRentSkewings);
    //   }

    //   this.PropertyID = null;

    //   for (var i = 0; i < this.partnershipList.length; i++) {
    //     if (this.partnershipList[i].partnershipName === property.partnershipName) {
    //       this.PropertyID = this.partnershipList[i].propertyID;
    //     }
    //   }

    //   property.propertyID = this.PropertyID;

    //   if (property.propertyID === null) {
    //     const title = 'Partnerhip list';
    //     const message = `Please pick a partnerhip from the list.`; //${ property.name }?
    //     this.dialogsService.confirmation(title, message)
    //       .then(() => {

    //       }, () => { });
    //   } else {
    //     this.activeModal.close(property);
    //   }
    // }
  }

  close() {
    this.activeModal.dismiss();
  }

  //#region Additional Targeting
  public onAdditionalTargetingChecked() {
    if (this.formWNC.value.additionalTargeting === true) {
      this.additionalTargeting = true;
      this.newAdditionalTargetingForm = true;
      var obj = {
        propertyID: this.property.id,
        numberOfUnits: 0,
        propertyAdditionalTargetingPercent: 0
      }
      this.newAdditionalTargetings.push(obj);
    }

    if (this.isAdmin === true && this.formWNC.value.additionalTargeting === false) {
      if (this.propertyAdditionalTargetings.length > 0) {
        const title = 'Delete Property Additional Targeting';
        const message = `Are you sure you want to delete all property additional targetings?`;
        this.dialogsService.confirmation(title, message)
          .then(() => {
            for (var i = 0; i < this.propertyAdditionalTargetings.length; i++) {
              this.deleteAdditionalTargetingsOnUncheck(this.propertyAdditionalTargetings[i].propertyAdditionalTargetingID);
            }
            this.additionalTargeting = false;
          }, () => {
            this.formWNC.get('additionalTargeting')!.setValue(true);
            this.newAdditionalTargetings = [];
          });
      }

      if (this.newAdditionalTargetings.length >= 0) {
        this.newAdditionalTargetings = [];
        this.additionalTargeting = false;
      }
    }

    if (this.isAdmin === false && this.formWNC.value.additionalTargeting === false) {
      this.additionalTargeting = false;
      this.newAdditionalTargetings = [];
    }
  }

  public deleteAdditionalTargetingsOnUncheck(id: number) {
    return this.propertyService.deletePropertyAdditionalTargeting(id).subscribe(() => {
      this.propertyAdditionalTargetings.splice(0, 1);
    }, () => { });
  }

  public addAdditionTargeting() {
    if (this.formWNC.value.additionalTargeting === true) {
      this.newAdditionalTargetingForm = true;
      var obj = {
        propertyID: this.property.id,
        numberOfUnits: 0,
        propertyAdditionalTargetingPercent: 0,
      }
      this.newAdditionalTargetings.push(obj);
    }
  }

  public deleteNewAdditionalTargeting(ndx: number) {
    this.newAdditionalTargetings.splice(ndx, 1);
    if (this.newAdditionalTargetings.length === 0 && this.propertyAdditionalTargetings.length === 0) {
      this.formWNC.get('additionalTargeting')!.setValue(false);
      this.newAdditionalTargetings = [];
      this.newAdditionalTargetingForm = false;
      this.additionalTargeting = false;
    }
  }

  public saveAdditionalTargeting(model: PropertyAdditionalTargeting) {
    model.propertyAdditionalTargetingPercent = model.propertyAdditionalTargetingPercent / 100;
    return this.propertyService.updatePropertyAdditionalTargeting(model).subscribe(() => {
      var num = model.propertyAdditionalTargetingPercent * 100;
      model.propertyAdditionalTargetingPercent = parseInt(num.toFixed());
    });
  }

  public deleteAdditionalTargeting(ndx: number, id: number) {
    const propertyAdditionalTargeting = this.propertyAdditionalTargetings.find(u => u.propertyAdditionalTargetingID === id);
    if (!propertyAdditionalTargeting) return;

    const title = 'Delete Property Additional Targeting';
    const message = `Are you sure you want to delete a property additional targeting?`; //${ property.name }?
    this.dialogsService.confirmation(title, message)
      .then(() => {
        return this.propertyService.deletePropertyAdditionalTargeting(id).subscribe(() => {
          this.propertyAdditionalTargetings.splice(ndx, 1);
          if (this.propertyAdditionalTargetings.length === 0) {
            this.formWNC.get('additionalTargeting')!.setValue(false);
            this.newAdditionalTargetings = [];
            this.newAdditionalTargetingForm = false;
            this.additionalTargeting = false;
          }
        }, (error) => {
          if (error.error === ServerErrors.CANNOT_BE_DELETED_ADDITIONAL_TARGETINGS) {
            this.showErrorMessage(error.error)
          }
        });
      }, () => { });
  }
  //#endregion
  
  //#region Deep Rent Skewing
  public onDeepRentSkewingChecked() {
    if (this.formWNC.value.deepRentSkewing === true) {
      this.deepRentSkewing = true;
      this.newDeepRentSkewingForm = true;
      var obj = {
        propertyID: this.property.id,
        numberOfUnits: 0,
        propertyDeepRentSkewingPercent: 0
      }
      this.newDeepRentSkewings.push(obj);
    }

    if (this.isAdmin === true && this.formWNC.value.deepRentSkewing === false) {
      if (this.propertyDeepRentSkewings.length > 0) {
        const title = 'Delete Property Deep Rent Skewing';
        const message = `Are you sure you want to delete all property deep rent skewings?`;
        this.dialogsService.confirmation(title, message)
          .then(() => {
            for (var i = 0; i < this.propertyDeepRentSkewings.length; i++) {
              this.deleteDeepRentSkewingsOnUncheck(this.propertyDeepRentSkewings[i].propertyDeepRentSkewingID);
            }
            this.deepRentSkewing = false;
          }, () => {
            this.formWNC.get('deepRentSkewing')!.setValue(true);
            this.newDeepRentSkewings = [];
          });
      }

      if (this.newDeepRentSkewings.length >= 0) {
        this.newDeepRentSkewings = [];
        this.deepRentSkewing = false;
      }
    }

    if (this.isAdmin === false && this.formWNC.value.deepRentSkewing === false) {
      this.deepRentSkewing = false;
      this.newDeepRentSkewings = [];
    }
  }

  public deleteDeepRentSkewingsOnUncheck(id: number) {
    return this.propertyService.deletePropertyDeepRentSkewing(id).subscribe(() => {
      this.propertyDeepRentSkewings.splice(0, 1);
    }, () => { });
  }

  public addDeepRentSkewing() {
    if (this.formWNC.value.deepRentSkewing === true) {
      this.newDeepRentSkewingForm = true;
      var obj = {
        propertyID: this.property.id,
        numberOfUnits: 0,
        propertyDeepRentSkewingPercent: 0,
      }
      this.newDeepRentSkewings.push(obj);
    }
  }

  public deleteNewDeepRentSkewing(ndx: number) {
    this.newDeepRentSkewings.splice(ndx, 1);
    if (this.newDeepRentSkewings.length === 0 && this.propertyDeepRentSkewings.length === 0) {
      this.formWNC.get('deepRentSkewing')!.setValue(false);
      this.newDeepRentSkewings = [];
      this.newDeepRentSkewingForm = false;
      this.deepRentSkewing = false;
    }
  }

  public saveDeepRentSkewing(model: PropertyDeepRentSkewing) {
    model.propertyDeepRentSkewingPercent = model.propertyDeepRentSkewingPercent / 100;
    return this.propertyService.updatePropertyDeepRentSkewing(model).subscribe(() => {
      var num = model.propertyDeepRentSkewingPercent * 100;
      model.propertyDeepRentSkewingPercent = parseInt(num.toFixed());
    });

  }

  public deleteDeepRentSkewing(ndx: number, id: number) {
    const propertyDeepRentSkewing = this.propertyDeepRentSkewings.find(u => u.propertyDeepRentSkewingID === id);
    if (!propertyDeepRentSkewing) return;

    const title = 'Delete Property Deep Rent Skewing';
    const message = `Are you sure you want to delete a property deep rent skewing?`; //${ property.name }?
    this.dialogsService.confirmation(title, message)
      .then(() => {
        return this.propertyService.deletePropertyDeepRentSkewing(id).subscribe(() => {
          this.propertyDeepRentSkewings.splice(ndx, 1);
          if (this.propertyDeepRentSkewings.length === 0) {
            this.formWNC.get('deepRentSkewing')!.setValue(false);
            this.newDeepRentSkewings = [];
            this.newDeepRentSkewingForm = false;
            this.deepRentSkewing = false;
          }
        }, (error) => {
          if (error.error === ServerErrors.CANNOT_BE_DELETED_DEEP_RENT_SKEWINGS) {
            this.showErrorMessage(error.error)
          }
        });
      }, () => { });
  }
  //#endregion

  public showErrorMessage(message: string) {
    return this.dialogsService.error(message)
  }

  //BFS: 1.02 - keep this just for ref  
  //onExistingLIHTCProjectChecked() {
  //  if (this.form.value.existingLIHTCProjectFlag === true) {
  //    this.form.controls['existingLIHTCProject'].enable();
  //  } else {
  //    this.form.controls['existingLIHTCProject'].setValue('');
  //    this.form.controls['existingLIHTCProject'].disable();
  //  }
  //}
}
