<div class="wncModal">
    <div class="panelHeader">
      <div class="row">
        <div class="col-md-9 panelTitle">
          <div class="col-md-12 no-pad">
            <h3 class="white-text header-padding-left">Property Role Mapping</h3>
          </div>
        </div>      
        <div class="col-md-3 panelButton">
          <button type="button" class="btn btn-outline-danger white-background" (click)="closeModal()"><i class="fa fa-times" aria-hidden="true"></i> &#160;Close</button>
        </div>
      </div>
    </div>  
    <div class="panelBody">
      <div style="padding-top: 10px;">
        <nav class="navbar navbar-expand-lg navbar-light bg-light border-top border-right border-left" style="padding-right: 0px;">
          <div class="col-md-6" style="padding-right: 0px;">
            <span class="navbar-brand mb-0 h6">Role: </span>
          </div>
          <div class="col-md-6" style="padding-right: 0px;">
            <span class="navbar-brand mb-0 h6">{{roleName}}</span>
          </div>
        
          <ng-content></ng-content>
        </nav>
        <mat-radio-group [(ngModel)]="propertyList" (ngModelChange)="onPropertyListChange($event)">
          <mat-radio-button value="wnc">WNC Properties</mat-radio-button>
          <mat-radio-button value="cpp">CPP Properties</mat-radio-button>
          <mat-radio-button value="pef">PEF Properties</mat-radio-button>
          <mat-radio-button value="all">All Properties</mat-radio-button>
        </mat-radio-group>
      <ag-grid-angular class="ag-theme-balham"
                       style="height:225px"
                       [pagination]="true"
                       [gridOptions]="gridOptions"
                       [columnDefs]="unitFileListColumns"
                       [defaultColDef]="defaultColDef"
                       [sideBar]="false"
                       [context]="context"
                       [components]="frameworkComponents"
                       rowSelection= "multiple"
                       (columnVisible)="onColumnVisible($event)"
                       (gridReady)="onGridReady($event)" 
                       (rowSelected)="onRowSelected($event)"
                       [rowData]="filteredPropertyList"
                       [getRowId]="getRowId">
      </ag-grid-angular>

    </div>
  
    </div>
  </div>

  <div class="modal-footer">
    
    
    <button  type="button" class="btn btn-primary" (click)="save()">Save</button>
  </div>
  