<div class="wncModal">
    <div class="panelHeader">
      <div class="row">
        <div class="col-md-9 panelTitle">
          <div class="col-md-12 no-pad">
            <h3 class="white-text header-padding-left">Property Notification Tracker</h3>
          </div>
        </div>      
        <div class="col-md-3 panelButton">
          <button type="button" class="btn btn-outline-danger white-background" (click)="closeModal()"><i class="fa fa-times" aria-hidden="true"></i> &#160;Close</button>
        </div>
      </div>
    </div>  
    <div class="panelBody">
      <div style="padding-top: 10px;">
        <nav class="navbar navbar-expand-lg navbar-light bg-light border-top border-right border-left" style="padding-right: 0px;">
          <div class="col-md-12" style="padding-right: 0px;">
            <span class="navbar-brand mb-0 h6">Notification Scheduler</span>
          </div>
        
          <ng-content></ng-content>
        </nav>
      <ag-grid-angular class="ag-theme-balham"
                       style="height:225px"
                       [pagination]="true"
                       [gridOptions]="gridOptions"
                       [columnDefs]="notificationSchedulerColumns"
                       [defaultColDef]="defaultColDef"
                       [sideBar]="false"
                       [context]="context"
                       [components]="frameworkComponents"
                       rowSelection= "single"
                       (columnVisible)="onColumnVisible($event)"
                       (gridReady)="onGridReady($event)" 
                       [rowData]="notificationScheduleList"
                       [getRowId]="getRowId">
      </ag-grid-angular>

    </div>
    <div style="padding-top: 10px;">
      <nav class="navbar navbar-expand-lg navbar-light bg-light border-top border-right border-left" style="padding-right: 0px;">
        <div class="col-md-8" style="padding-right: 0px;">
          <span class="navbar-brand mb-0 h6">Notification Log</span>
        </div>
        <div class="col-md-3">
        <button class="btn btn-outline-secondary" *ngIf="hasFilters" (click)="clearFilters()">
          Clear filters
        </button>
      </div>
        <ng-content></ng-content>
      </nav>
    <ag-grid-angular class="ag-theme-balham"
                     style="height:225px"
                     [pagination]="true"
                     [gridOptions]="gridOptions"
                     [columnDefs]="notificationLogColumns"
                     [defaultColDef]="defaultColDef"
                     [sideBar]="false"
                     [context]="context"
                     [components]="frameworkComponents"
                     rowSelection= "single"
                     (columnVisible)="onColumnVisible($event)"
                     (gridReady)="onLogGridReady($event)" 
                     (filterChanged)="onFilterChanged($event)"
                     [rowData]="notificationLogList"
                     [getRowId]="getRowId">
    </ag-grid-angular>

  </div>
    </div>
  </div>