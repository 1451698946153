import { Component, OnInit, Inject, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, ValidationErrors, FormArray, FormControl } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter, NgbModal, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Report } from '@app/core/models/report';
import { UserService } from '@app/users/user.service';
import { VacantReadyCheck,TotalApplicantValueCheck, dateValidator, dateValidator2, dateValidatorAllowBlank } from '@app/shared/validators';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomDateParserFormatter } from '../building-add-dialog/building-add-dialog.component';
import { ReportsService } from '@app/reports/reports.service';
import { DialogsService } from '@app/core/services/dialogs.service';
//import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-report-add-dialog',
  templateUrl: './report-add-dialog.component.html',
  styleUrls: ['./report-add-dialog.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
     DatePipe]
})
export class ReportAddDialogComponent implements OnInit {
  dialogTitle = '';
  isAdd = true;
  form: UntypedFormGroup;
  @Input()
  report!: Report;
  @Output()
  saveChange = new EventEmitter();
  model: any;
  public states = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'];
  public isFailedForm: boolean = false;
  public companies: any;
  public properties: any;
  public propertyNamePopover: string = 'This should be a drop down field for properties with that specific management company.'
  public startDatePopover: string = 'The report should only start on Saturdays.'
  public occupiedUnitsPopover: string = 'Number of Units Occupied - This does not include any office, model or employee units. This is as of the end of the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public vacantUnitsPopover: string = 'Number of units vacant as of the end of the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public nonRevenueUnitsPopover: string = 'This would include any models, offices, off-line units only. Include the office if it is considered a unit in the community. This is as of the end of the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public employeeUnitsPopover: string = 'Number of Employee Units at the end of the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public delinquentCurrentPopover: string = 'Total dollar amount of the delinquencies for the current reporting month. This includes subsidy and resident payments. ***PLEASE ONLY USE NUMBERS AND THE "-" SIGN FOR NEGATIVES***'
  public delinquentPeriodPopover: string = 'Total dollar amount of the delinquencies for the past reporting month. This includes subsidy and resident payments. ***PLEASE ONLY USE NUMBERS AND THE "-" SIGN FOR NEGATIVES***'
  public delinquentBeyondPopover: string = 'Total dollar amount of the all delinquencies greater than 90 days . This includes subsidy and resident payments. ***PLEASE ONLY USE NUMBERS AND THE "-" SIGN FOR NEGATIVES***'
  public vacantReadyPopover: string = 'Vacant number of units that are ready for occupancy. This is as of the end of the report period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public maxDaysVacantPopover: string = 'This is the number of days that the longest vacant has not been occupied. This is as of the end of the report period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public moveInsPopover: string = 'Number of move-ins for the reporting period. This includes unit transfers. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public moveOutsPopover: string = 'Number of move-outs for the reporting period. This includes unit transfers. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public totalApplicantsPopover: string = 'Total number of applications on hand at the end of the period. This is applications that residents have left an application and holding deposit. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public totalApprovedPopover: string = 'Total number of applications on hand that are approved by compliance at the end of the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public totalNoticePopover: string = 'Total number of notices to vacate at the end of the period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public newApplicationPopover: string = 'Number of new applicants received during the reporting period. Application = Someone has left an application and paid the application fee. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public cancelledApplicationPopover: string = 'Number of applications cancelled during the reporting period. This could be from a prospective resident cancelling or management denying a application. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public newNoticePopover: string = 'Number of new notices received during the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public cancelledNoticePopover: string = 'Number of notices cancelled during the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public phoneTrafficPopover: string = 'Number of phone calls received during the week for apartment inquiries. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public walkInTrafficPopover: string = 'Number of walk-in traffic received during the week for apartment inquiries. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public reportCommentsPopover: string = 'Enter free form comments that add value to knowledge to the data being entered above that you want the asset manager to be aware of.'
  public moneyMask = ['^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$'];
  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  repeatedForms: any;
  constructor(
    //private config: NgbDatepickerConfig,
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private reportsService: ReportsService,
    public userService: UserService,
    public dialogsService: DialogsService) {

    this.form = this.fb.group({
      name: ['', []],
      propertyId: ['', Validators.required],
      userName: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      occupiedUnit: ['', []],
      vacantUnit: ['', [Validators.required]],
      employeeUnit: ['', []],
      nonRevenueUnit: ['', []],
      currentDelinquent: ['', []],
      priorDelinquent: ['', []],
      beyondDelinquent: ['', []],
      vacantReady: ['', []],
      totalNotice: ['', [Validators.required]],
      totalApplicant: ['', []],
      totalApproved: ['', []],
      maxDaysVacant: ['', []],
      moveIns: [null, []],
      moveOuts: [null, []],
      newApplication: ['', []],
      cancelledApplication: ['', []],
      newNotice: ['', []],
      cancelledNotice: ['', []],
      phoneTraffic: ['', []],
      walkInTraffic: ['', []],
      reportComment: ['', []],
      unitsWithBedBugs: [0, []],
      pestControlEngaged: [false, []],
      unitsWithBedBugsComment: ['', []],
      createdDate: ['', []],
      updatedDate: ['', []],
      createdByName: ['', []],
      updatedByName: ['', []],
    },{
      validator:Validators.compose( [TotalApplicantValueCheck('totalApplicant', 'vacantUnit','totalNotice'),VacantReadyCheck('vacantReady','vacantUnit')])
      
    });
  }

  ngOnInit() {
    this.form.patchValue(this.report);
    this.form.addValidators(dateValidator());
    this.isAdd = !(this.report && this.report.id);
    this.dialogTitle = this.isAdd
      ? 'Add Report'
      : 'Update Report';
    //BFS: 1.02 - as you open up the modal to edit or make a new report. your name gets populated (took this for the enddate)
    this.userService.getMe().subscribe((res) => {
      this.form.get('userName')!.setValue(res.displayName);
    });

  }

  save() {
    
    if (this.form.invalid) {
      this.isFailedForm = true;
    }
    else if(this.report.totalUnits < (parseInt(this.form.value.occupiedUnit) + parseInt(this.form.value.vacantUnit)  + parseInt(this.form.value.employeeUnit) + parseInt(this.form.value.nonRevenueUnit)))
    {
      const vacantUnitControl = this.form.controls['vacantUnit'];
      this.form.setErrors({ totalUnitCheck: true });
      
      this.isFailedForm = true;

    } else {
      const formValue = this.form.getRawValue();
      let replacedData: any = {};
      Object.keys(formValue).forEach((key: string) => {
        formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
      })
      const report = Object.assign({}, this.report, replacedData);
      this.activeModal.close(report);
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  //BFS: 1.02 - disables every day except for saturdays
  isDisabled(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 1 || d.getDay() === 2 || d.getDay() === 3 || d.getDay() === 4 || d.getDay() === 5;
  }

  onDateChange(event: Date) {
    var nextFriday = new Date(event);
    //BFS: 1.02 - the '6' in the beginning is how many days you are adding (in this case adding 6 days to saturday will give you next friday)
    nextFriday.setTime(nextFriday.getTime() + (6 * 24 * 60 * 60 * 1000));
    //BFS: 1.02 - the endDate form input gets populated with nextFriday's date
    this.form.get('endDate')!.setValue(nextFriday);
  }

  unitsWithPestsSectionVisible(){
    var propertyID = this.form.value.propertyId;
    var startDate = this.form.value.startDate;
    if(propertyID && startDate){
      return true;
    } else {
      return false;
    }
  }
  unitsWithPestsCheckBoxEnabled(){
    var unitsWithBedBugs = this.form.value.unitsWithBedBugs;
    if(unitsWithBedBugs && unitsWithBedBugs != ''){
      return true;
    } else {
      return false;
    }
  }
  public showErrorMessage(message: string) {
    return this.dialogsService.error(message)
  }
}
