import { Input, Directive, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import {NgControl} from '@angular/forms';
@Directive({
  // https://angular.io/guide/styleguide#style-02-08
  // Do use a custom prefix for the selector of directives (e.g, the prefix toh from Tour of Heroes).
  // Do spell non-element selectors in lower camel case unless the selector is meant to match a native HTML attribute.

  // the directive matches elements that have the attribute appValidateEqual and one of the formControlName or formControl or ngModel
  selector:
    "[inputMask][formControlName]",
  host:{
    '(ngModelChange)': 'onInputChange($event)',
    '(keydown.backspace)':'onInputChange($event.target.value, true)'
    }
})
export class InputMaskDirective implements OnInit, OnDestroy  {
  constructor(public model: NgControl) { }

  @Input() decimalCount?: number = 2;
  @Input() decimal?: string = ".";
  @Input() thousands?: string = ",";
  @Input() symbol?: string = "$";
  @Input() orientation?: string = "front";

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.onInputChange(this.model.value, null)
  }
  onInputChange(event: any, backspace: any){
    
    let amount = event;
    const negativeSign = Number(amount) < 0 ? "-" : "";
      
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(this.decimalCount)).toString();

    let j = (i.length > 3) ? i.length % 3 : 0;
    let newVal = negativeSign + '$' + (j ? i.substr(0, j) + this.thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + this.thousands) + (this.decimalCount ? this.decimal + Math.abs(Number(amount) - Number(i)).toFixed(this.decimalCount).slice(2) : "");
    if(backspace){
      newVal = newVal.substring(0, newVal.length - 1);
    }
//
    if(newVal.length == 0) {
      newVal = '';
    }
    else  {
      newVal = newVal;
    }
    if(this.model.valueAccessor){
      this.model.valueAccessor.writeValue(newVal);
    }
  }

}
