import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AuthService } from '@app/core/services/auth.service';


@Component({
  selector: 'app-grid-action',
  templateUrl: './grid-action.component.html',
  styleUrls: ['./grid-action.component.css']
})
export class GridActionComponent implements ICellRendererAngularComp {

  public params: any;
  public isSaveVisible: boolean = false;
  public isDownloadVisible:boolean = false;
  public isDeleteVisible:boolean = false;
  public isAdmin: boolean = false;
  public isSpecialAdmin: boolean = false;

  constructor(
    private authService: AuthService,
   
    ) {
  }
   
  agInit(params: any): void {
    this.params = params;
    this.isAdmin = this.authService.isAuthUserInRole("Admin");
    this.isSpecialAdmin = this.authService.isAuthUserInRole("Special");

    this.isSaveVisible = (this.isAdmin || this.isSpecialAdmin)&& (this.params.isSaveVisible || false);
    this.isDownloadVisible = (this.isAdmin || this.isSpecialAdmin)&& (this.params.isDownloadVisible || false);
    this.isDeleteVisible = (this.isAdmin || this.isSpecialAdmin)&& (this.params.isDeleteVisible || false);
    
    
   
  }

  refresh(_params?: any): boolean {
    return true;
  }

 
  public onSave($event: any) {
   
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        actionType:  'save'
      }
      this.params.onClick(params);
    }
  }

  public onDownload($event: any) {
   
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        actionType:  'download'
      }
      this.params.onClick(params);
    }
  }


  

  public onDelete($event: any) {
   
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        actionType:  'delete'
      }
      this.params.onClick(params);
    }
  }


  

  
}
