import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, Report, Unit, Building, CPPNotification } from '@app/core';
import { map } from 'rxjs/operators';
import { UnitLockAndUnlock } from '@app/core/models/UnitLockAndUnlock';
import { BuildingCPP } from '@app/core/models/buildingCPP';
import { UnitCPP } from '@app/core/models/unitCPP';

@Injectable({ providedIn: 'root' })
export class UnitDetailsCPPService {
  constructor(private http: HttpClient) { }

  getUnits(propertyID: number) {
    return this.http.get<{ list: UnitCPP[] }>(`${AppConfig.apiEndpoint}/unitDetailsCPP/1/10000/${propertyID}`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  getAllBuildings(propertyID: number) {
    return this.http.get<{ list: BuildingCPP[] }>(`${AppConfig.apiEndpoint}/unitDetailsCPP/getBuildings/1/10000/${propertyID}`)
      .pipe(
      map(response => {
        return response;
        })
      );
  }

  updateBuilding(building: BuildingCPP) {
    return this.http.put(`${AppConfig.apiEndpoint}/unitDetailsCPP/updateBuilding`, building);
  }

  addBuilding(building: BuildingCPP) {
    console.log('tsService Building', building)
    return this.http.put(`${AppConfig.apiEndpoint}/unitDetailsCPP/addBuilding`, building);
  }

  addUnit(unit: UnitCPP) {
    console.log('%c unit servce ui', 'background: yellow; color: black', unit);
    return this.http.post(`${AppConfig.apiEndpoint}/unitDetailsCPP`, unit);
  }

  update(unit: UnitCPP) {
    if(unit.moveInDate){
      unit.moveInDate = unit.moveInDate.toLocaleString();
    }
    if(unit.moveOutDate){
      unit.moveOutDate = unit.moveOutDate.toLocaleString()
    }
    if(unit.certEffectDate){
      unit.certEffectDate =unit.certEffectDate.toLocaleString()
    }
    if(unit.tempDateVacated){
      unit.tempDateVacated = unit.tempDateVacated.toLocaleString()
    }
    if(unit.tempDateReturned){
      unit.tempDateReturned = unit.tempDateReturned.toLocaleString()
    }
    if(unit.previousCertEffectDate){
      unit.previousCertEffectDate =unit.previousCertEffectDate.toLocaleString()
    }
    if(unit.dateOfTransfer){
      unit.dateOfTransfer =unit.dateOfTransfer.toLocaleString()
    }
    return this.http.put(`${AppConfig.apiEndpoint}/unitDetailsCPP`, unit);
  }

  notifyCPPAssetManager(model: CPPNotification){
    return this.http.post(`${AppConfig.apiEndpoint}/unitDetailsCPP/Notify`, model)
  }

}
