<div class="wncModal">
    <div class="panelHeader">
      <div class="row">
        <div class="col-md-9 panelTitle">
          <div class="col-md-12 no-pad">
            <h3 class="white-text header-padding-left">Frequency Types</h3>
          </div>
        </div>      
        <div class="col-md-3 panelButton">
          <button type="button"  class="btn btn-outline-primary white-background"  (click)="showNewFrequencyTypeDiv()"><i class="fa fa-plus" aria-hidden="true"></i> &#160;Add</button>
          <button type="button" class="btn btn-outline-danger white-background" (click)="closeModal()"><i class="fa fa-times" aria-hidden="true"></i> &#160;Close</button>
        </div>
      </div>
    </div>  
    <div class="panelBody">
      <div class="col-md-12 table-header">
        <div class="row">
            <div class="col-md-4 align-center bold-header header-text-size">
                <span>Frequencty</span>
            </div>
            <div class="col-md-6 no-pad row">
                <div class="col-md-3 align-center bold-header header-text-size">
                    <span>Year</span>
                </div>
                <div class="col-md-3 align-center bold-header header-text-size">
                    <span>Month</span>
                </div>
                <div class="col-md-3 align-center bold-header header-text-size">
                    <span>Day</span>
                </div>
                <div class="col-md-3 align-center bold-header header-text-size">
                    <span>Active</span>
                </div>
            </div>
          <div class="col-md-2 align-center bold-header header-text-size">
            <span>Action</span>
          </div>
        </div>
      </div>

      <div class="col-md-12 table-content">
        <div class="row formInput" *ngIf="newFrequencyTypeShowDiv" style="padding-bottom: 4px;">
            <div class="col-md-4" style="padding-right: 3px;">
                <input type="text" class="form-control align-right" [(ngModel)]="newFrequencyType.frequencyTypeName" />
            </div>
            <div class="col-md-6 no-pad row">
                <div class="col-md-3 no-pad">
                    <input type="number" class="form-control align-right " [(ngModel)]="newFrequencyType.year" />
                </div>
                <div class="col-md-3 slight-left-pad">
                    <input type="number" class="form-control align-right " [(ngModel)]="newFrequencyType.month" />
                </div>
                <div class="col-md-3 slight-left-pad">
                    <input type="number" class="form-control align-right " [(ngModel)]="newFrequencyType.day" />
                </div>
                <div class="col-md-3 no-pad">
                    <input class="form-control checkbox-padding checkbox-margin" id="bold" type="checkbox" [(ngModel)]="newFrequencyType.active"/>
                </div>
            </div>

            <div class="col-md-2 row left-pad top-pad">
                <div class="col-xs-6 col-md-6 no-pad atc-save-btn text-right" style="padding-left: 35px;">
                    <img class="cursor-img" src="../../../../assets/image/ic_save.png" alt="" (click)="addFrequencyType(newFrequencyType)" />
                </div>
                <div class="col-xs-6 col-md-6 no-pad atc-save-btn text-right" style="padding-right: 30px;">
                    <img class="cursor-img" src="../../../../assets/image/ic_delete.png" alt="" (click)="closeNewFrequencyTypeDiv()" />
                </div>
            </div>
        </div>

        <div class="row formInput" *ngFor="let item of frequencyTypeList; let ndx = index" style="padding-bottom: 4px;">
          <div class="col-md-4" style="padding-right: 3px;">
            <input type="text" class="form-control align-right " [(ngModel)]="item.frequencyTypeName" />
          </div>
          <div class="col-md-6 no-pad row">
            <div class="col-md-3 no-pad">
                <input type="number" class="form-control align-right " [(ngModel)]="item.year" />
            </div>
            <div class="col-md-3 slight-left-pad">
                <input type="number" class="form-control align-right " [(ngModel)]="item.month" />
            </div>
            <div class="col-md-3 slight-left-pad">
                <input type="number" class="form-control align-right " [(ngModel)]="item.day" />
            </div>
            <div class="col-md-3 no-pad">
                <input class="form-control checkbox-padding checkbox-margin" id="bold" type="checkbox" [(ngModel)]="item.active"/>
            </div>
          </div>
          <div class="col-md-2 row left-pad top-pad">
            <div class="col-xs-6 col-md-6 no-pad atc-save-btn text-right" style="padding-left: 35px;">
              <img class="cursor-img" src="../../../../assets/image/ic_save.png" alt="" (click)="saveFrequencyType(item, ndx)" />
            </div>
            <div class="col-xs-6 col-md-6 no-pad atc-save-btn text-right" style="padding-right: 30px;">
              <img class="cursor-img" src="../../../../assets/image/ic_delete.png" alt="" (click)="deleteFrequencyType(item, ndx)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>