import { Component, TemplateRef, Output, EventEmitter, OnInit, Input, ViewChild } from '@angular/core';
import { AgGridEvent, GridApi, ColumnApi, ColDef, RowClickedEvent, FilterChangedEvent, GridOptions, RowNode, RowSelectedEvent, GetRowIdFunc, GetRowIdParams, GridReadyEvent, IRowNode } from "ag-grid-community";
import { FormGroup, UntypedFormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DialogsService } from '@app/core/services/dialogs.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationManagementDialogService } from '../notification-management-dialog/notification-management-dialog.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ButtonRendererComponent} from "@app/core/component/buttonRenderer/buttonRenderer.component"
import { DropDownRendererComponent } from '@app/core/component/dropDownRenderer/dropDownRenderer.component';
import { DropDownOption } from '@app/core/models/dropDownOption';
import { GridActionComponent } from '@app/shared/components/grid-action/grid-action.component';
import { ToastrService } from 'ngx-toastr';
import { NotificationLog } from '@app/core/models/notificationLog';
import { DatePipe } from '@angular/common';
import { UnitFile } from '@app/core/models/unitFile';
import { Property } from '@app/core/models/property';
import { PropertyService } from '@app/properties/properties.service';
import { RolePropertyMappingDialogService } from './roleProperty-mapping-dialog.service';
import { RoleProperty } from '@app/core/models/roleProperty';
import { RolePropertyList } from '@app/core/models/RolePropertyList';



@Component({
  selector: 'roleProperty-mapping-dialog',
  templateUrl: './roleProperty-mapping-dialog.component.html',
  styleUrls: ['./roleProperty-mapping-dialog.component.css'],
  providers: [DatePipe]
})
export class RolePropertyMappingDialogComponent implements OnInit { 

  @Input()
  roleId!: number;
  roleName!: string;
  @Output() closeModalEvent: EventEmitter<any> = new EventEmitter();
 
  public eventCloseModal: EventEmitter<any> = new EventEmitter();
  
  private gridApi!: GridApi;
  public newTrackingItemShowDiv: boolean = false;
  public unitFileList: UnitFile[] = [];
  public notificationLogList:NotificationLog[]=[];
  public repeatedForms: any;
  public params:any;
  public gridOptions: GridOptions;
  public hasFilters: boolean = false;
  public propertyList: string | null | undefined;
  public properties: Property[] = [];
  public propertyListType: any;
  public filteredPropertyList: Property[] = [];
  public roleProperties:RoleProperty[]=[];
  
  
  public defaultColDef = {
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true
  }

 
   public frameworkComponents: any;
  

   public unitFileListColumns: ColDef[] = [    
    { headerName: 'Property Name', field: 'name'},
    { headerName: 'Include', field:'selected', editable:true, headerCheckboxSelection: true }
   
  ];

 
  
  public context: any;

  constructor(
    private modalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    public activeModal: NgbActiveModal,
    public ngbModal: NgbModal,
    private dialogsService: DialogsService,
    private propertyService: PropertyService,
    private rolePropertyMappingDialogService: RolePropertyMappingDialogService,
    private toastr: ToastrService,
    
    ) { 

      this.gridOptions = {
        paginationAutoPageSize: true,
        
      }
      this.frameworkComponents = {
        actions: GridActionComponent,
        dropDownEditor: DropDownRendererComponent, 
      }
      this.context = {
        componentParent: this
      }

  }
 
  
  ngOnInit() {

    this.getRoleProperties();
  
   
  }

  private getRoleProperties(){
    this.rolePropertyMappingDialogService.getRoleProperties(this.roleId)
    .subscribe((data) => {
      this.roleProperties = data.list;
      this.setPropertySelection();
    });
}

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => params.data.id;

  public onHeaderChange(){

  }

  private setPropertySelection()
  {
    const selected: IRowNode[] = [];
    this.properties!.forEach(property=>{
      if (this.roleProperties!.some(roleProperty=> roleProperty.propertyId== property!.id )) {
        property.selected=true;
      } 
    });
    this.gridApi.forEachNode( (node) =>{
      if (this.roleProperties.some(roleProperty=> roleProperty.propertyId== node.data!.id )) {
        selected.push(node);
      } 
    });

    this.gridApi.setNodesSelected({ nodes: selected, newValue: true });
  }


  save() {

    //remove any property which were not currrently in role property
 var removedRolePropertyList:RolePropertyList ={ RoleId: this.roleId,  Properties:[] };
 var addedRolePropertyList:RolePropertyList = { RoleId: this.roleId, Properties:[] };

  this.properties!.forEach( (property) =>{
    if (!property.selected && this.roleProperties.some(roleProperty=> roleProperty.propertyId== property.id )) {
      removedRolePropertyList.Properties.push(property.id);
     
    } 
   
  });

    //add any user which are newly selected
  this.properties!.forEach( (property) =>{
    if (property.selected && (!this.roleProperties.some(roleProperty=> roleProperty.propertyId== property.id ))) {
      addedRolePropertyList.Properties.push(property.id);
      
    }
    
  });

  



    //remove users
    if(!(removedRolePropertyList === undefined || removedRolePropertyList.Properties === undefined|| removedRolePropertyList.Properties.length == 0))
    {
     


      this.rolePropertyMappingDialogService.removeRoleProperties(removedRolePropertyList).subscribe((removeRolePropertyResults) => {
        removeRolePropertyResults.forEach((removeRolePropertyResult)=>{
          if(!(removeRolePropertyResult===undefined))
          {
            if(removeRolePropertyResult.isSuccessful)
              this.toastr.success(removeRolePropertyResult.message, '');
            else
              this.toastr.error(removeRolePropertyResult.message, '');

              
          }
        });
      
        
      },(error)=>{ this.toastr.error('Unexpected error while removing properties from role', 'Failed to remove from role');
      console.log(error);
    });
    }

    //add users
    if(!(addedRolePropertyList === undefined || addedRolePropertyList.Properties=== undefined  || addedRolePropertyList.Properties.length == 0))
    {
      this.rolePropertyMappingDialogService.addRoleProperties(addedRolePropertyList).subscribe((addRolePropertyResults) => {
        addRolePropertyResults.forEach((addRolePropertyResult)=>{
          if(!(addRolePropertyResult===undefined))
          {
            if(addRolePropertyResult.isSuccessful)
              this.toastr.success(addRolePropertyResult.message, '');
            else
              this.toastr.error(addRolePropertyResult.message, '');

             
          }
        });
        this.getRoleProperties();
        
      },(error)=>{ this.toastr.error('Unexpected error while adding property to role', 'Failed to add to role');
      console.log(error);
    });
    }


   
  }
  
  public closeModal(){
    
    this.activeModal.dismiss();
    this.eventCloseModal.emit({});
  }


  onPropertyListChange(event: any) {
    this.filteredPropertyList = this.properties.filter(property => {
      var a = event;
      if(a === 'wnc'){
        var flag = a + 'Flag';
        //var flag = this.userListType!;
        return (<any>property)[flag];
      }
      if(a === 'cpp'){
        var flag = a + 'Flag';
        //var flag = this.userListType!;
        return (<any>property)[flag];
      }
      if(a === 'pef'){
        var flag = a + 'Flag';
        //var flag = this.userListType!;
        return (<any>property)[flag];
      }
      if (a !== 'all') {
        var flag = a + 'Flag';
        return (<any>property)[flag];
      } else {
        return true;
      }
    })
    this.propertyListType = event;
    this.filteredPropertyList = this.properties.filter(property => {
      if (this.propertyListType !== 'all') {
        var flag = this.propertyListType + 'Flag';
        return (<any>property)[flag];
      } else {
        return true;
      }
    })
  }

  
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private getAllProperties(){
    this.propertyService.getAll()
    .subscribe((data) => {
      this.properties = data.list;
      this.startingFilteredList();
      this.setPropertySelection();
      
    });
}

public startingFilteredList() {
  //this.propertyListType
  this.filteredPropertyList = this.properties.filter(property => {
    property.selected=false;
    if (!this.propertyListType) {
      this.propertyListType = 'all';
    }
    if(this.propertyListType.indexOf('wnc') === 0){
      var a = 'wnc'
      var flag = a + 'Flag';
      //var flag = this.userListType!;
      return (<any>property)[flag];
    }
    if(this.propertyListType.indexOf('cpp') === 0){
      var a = 'cpp'
      var flag = a + 'Flag';
      //var flag = this.userListType!;
      return (<any>property)[flag];
    }
    if(this.propertyListType.indexOf('pef') === 0){
      var a = 'pef'
      var flag = a + 'Flag';
      //var flag = this.userListType!;
      return (<any>property)[flag];
    }
    if (this.propertyListType !== 'all') {
      var a = 'all'
      var flag = a + 'Flag';
      return (<any>property)[flag];
    } else {
      return true;
    }
  });
}


 




  


  onColumnVisible(e: any) {
    console.log(e);
  }

  onGridReady(params) { 
    this.gridApi = params.api;
    this.getAllProperties();
    params.api.sizeColumnsToFit(); 
    this.getAllProperties();
  } 


  

  



  onFilterChanged(params: FilterChangedEvent) {
    const filterModel = params.api.getFilterModel();
    this.hasFilters = Object.keys(filterModel).length > 0;
  }

  public onRowSelected(event: RowSelectedEvent) {
  
    if(event.source!='api')
    {
      var property =  this.properties!.find(property=>property.id===event.node.data.id);
      if(!property)
      return;
        if(event.node.isSelected())
        {
          property.selected=true;
         
        }else
        {
          property.selected=false;
  
        }
        
        event.node.updateData(property);
  }
}

  clearFilters() {
    this.gridApi.setFilterModel({});
  }

  showErrorMessage(message:string) {
    return this.dialogsService.error(message)
  }

}