import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, Property } from '@app/core';
import { NotificationSchedule } from '@app/core/models/notificationSchedule';
import { UnitFile } from '@app/core/models/unitFile';
import { RolePropertyList } from '@app/core/models/RolePropertyList';
import { RolePropertyUpdateResult } from '@app/core/models/RolePropertyUpdateResult';

@Injectable({ providedIn: 'root' })
export class RolePropertyMappingDialogService {
 
    constructor(private http: HttpClient) { }

    

    getUnitFiles(unitId:number) {
        return this.http.get<any>(`${AppConfig.apiEndpoint}/unitFile/1/10000/${unitId}`);
      }

      getRoleProperties(roleId:number) {
    
        return this.http.get<any>(`${AppConfig.apiEndpoint}/Roles/getRolePropertiesByRole/${roleId}/1/10000`);
      }
    
      removeRoleProperties(removedProperties: RolePropertyList) {
        return this.http.post<RolePropertyUpdateResult[]>(`${AppConfig.apiEndpoint}/Roles/removeRoleProperties`, removedProperties);
      }
    
      addRoleProperties(addedProperties: RolePropertyList) {
        return this.http.post<RolePropertyUpdateResult[]>(`${AppConfig.apiEndpoint}/Roles/addRoleProperties`, addedProperties);
      }


}
