import { Component, OnInit, Inject, Input, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, ValidationErrors, FormArray, FormControl } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter, NgbModal, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Report } from '@app/core/models/report';
import { UserService } from '@app/users/user.service';
import { dateValidator, dateValidator2, dateValidatorAllowBlank } from '@app/shared/validators';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatGridTileHeaderCssMatStyler } from '@angular/material/grid-list';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomDateParserFormatter } from '../building-add-dialog/building-add-dialog.component';
import { DialogsService } from '@app/core/services/dialogs.service';
import { ReportsPEFService } from '@app/reportsPEF/reportsPEF.service';
import { FilesDialogComponent } from '../files-dialog/files-dialog.component';
import { Files } from '@app/core/models/files';
import { PropertiesComponent } from '@app/properties/properties/properties.component';
import { FileUploaderService } from '@app/shared/components/file-uploader/file-uploader.service';
import { FileUploaderComponent } from '@app/shared/components/file-uploader/file-uploader.component';

@Component({
  selector: 'app-reportPEF-add-dialog',
  templateUrl: './reportPEF-add-dialog.component.html',
  styleUrls: ['./reportPEF-add-dialog.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
     DatePipe, FileUploaderComponent]
})
export class ReportPEFAddDialogComponent implements OnInit {
  dialogTitle = '';
  isAdd!: boolean;
  form: UntypedFormGroup;
  //BFS: 9/16/2022 - 01.11 - these 2 inputs came from the parent of reportPEF.component
  @Input() report!: Report;
  @Input() properties!: any;
  // //BFS: 9/16/2022 - 01.11 - this output is what sends the data back to the parent of reportPEF.component *** not needed any more but keep it just in case
  // @Output() saveChange = new EventEmitter();
  @ViewChild(FileUploaderComponent,{static: true}) child!: FileUploaderComponent;

  public states = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'];
  public isFailedForm: boolean = false;
  public companies: any;
  public propertyNamePopover: string = 'This should be a drop down field for properties with that specific management company.'
  public startDatePopover: string = 'The report should only start on Saturdays.'
  public occupiedUnitsPopover: string = 'Number of Units Occupied - This does not include any office, model or employee units. This is as of the end of the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public vacantUnitsPopover: string = 'Number of units vacant as of the end of the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public evictionsInProcessPopover: string = 'Number of units which are in the process of being evicted. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public nonRevenueUnitsPopover: string = 'This would include any models, offices, off-line units only. Include the office if it is considered a unit in the community. This is as of the end of the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public employeeUnitsPopover: string = 'Number of Employee Units at the end of the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public delinquentCurrentPopover: string = 'Total dollar amount of the delinquencies for the current reporting month. This includes subsidy and resident payments. ***PLEASE ONLY USE NUMBERS AND THE "-" SIGN FOR NEGATIVES***'
  public delinquentPeriodPopover: string = 'Total dollar amount of the delinquencies for 31 to 60 days. This includes subsidy and resident payments. ***PLEASE ONLY USE NUMBERS AND THE "-" SIGN FOR NEGATIVES***'
  public delinquentBeyondPopover: string = 'Total dollar amount of the all delinquencies for 61 to 90 days. This includes subsidy and resident payments. ***PLEASE ONLY USE NUMBERS AND THE "-" SIGN FOR NEGATIVES***'
  public over90DaysDelinquentPopover: string = 'Total dollar amount of the all delinquencies for over 90 days. This includes subsidy and resident payments. ***PLEASE ONLY USE NUMBERS AND THE "-" SIGN FOR NEGATIVES***'
  public vacantReadyPopover: string = 'Vacant number of units that are ready for occupancy. This is as of the end of the report period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public maxDaysVacantPopover: string = 'This is the number of days that the longest vacant has not been occupied. This is as of the end of the report period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public moveInsPopover: string = 'Number of move-ins for the reporting period. This includes unit transfers. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public moveOutsPopover: string = 'Number of move-outs for the reporting period. This includes unit transfers. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public totalApplicantsPopover: string = 'Total number of applications on hand at the end of the period. This is applications that residents have left an application and holding deposit. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public totalApprovedPopover: string = 'Total number of applications on hand that are approved by compliance at the end of the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public totalNoticePopover: string = 'Total number of notices to vacate at the end of the period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public pastDueRecertPopover: string = 'Enter the total number of recerts that are past-due for the property. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public newApplicationPopover: string = 'Number of new applicants received during the reporting period. Application = Someone has left an application and paid the application fee. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public cancelledApplicationPopover: string = 'Number of applications cancelled during the reporting period. This could be from a prospective resident cancelling or management denying a application. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public newNoticePopover: string = 'Number of new notices received during the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public cancelledNoticePopover: string = 'Number of notices cancelled during the reporting period. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public phoneTrafficPopover: string = 'Number of phone calls received during the week for apartment inquiries. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public walkInTrafficPopover: string = 'Number of walk-in traffic received during the week for apartment inquiries. ***PLEASE ONLY USE NUMBERS DO NOT USE CHARACTERS***'
  public reportCommentsPopover: string = 'Enter free form comments that add value to knowledge to the data being entered above that you want the asset manager to be aware of.'
  public moneyMask = ['^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$'];
  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  repeatedForms: any;
 
  @Input() file!: File;
  //BFS: 10/21/2022 - 01.11 - this is the parameters that get passed into the file uploader. *propId, reportId and such
  public params:any;
  public fileList: Files[] = [];
  public canDelete: boolean = true;
  public isDeleting: boolean[] = [];
  public isDownloading: boolean[] = [];
  public ChildCurrentVal: any;
  public sendBackReport: any;

  constructor(
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private reportsPEFService: ReportsPEFService,
    public userService: UserService,
    public dialogsService: DialogsService,
    public modalService: NgbModal,
    public filesDialogComponent: FilesDialogComponent,
    private cd: ChangeDetectorRef) {

    this.form = this.fb.group({
      name: ['', []],
      propertyId: ['', Validators.required],
      userName: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      occupiedUnit: ['', []],
      vacantUnit: ['', []],
      employeeUnit: ['', []],
      nonRevenueUnit: ['', []],
      currentDelinquent: ['', []],
      priorDelinquent: ['', []],
      beyondDelinquent: ['', []],
      vacantReady: ['', []],
      totalApplicant: ['', []],
      totalApproved: ['', []],
      totalNotice: ['', []],
      maxDaysVacant: ['', []],
      moveIns: [null, []],
      moveOuts: [null, []],
      newApplication: ['', []],
      cancelledApplication: ['', []],
      newNotice: ['', []],
      cancelledNotice: ['', []],
      phoneTraffic: ['', []],
      walkInTraffic: ['', []],
      reportComment: ['', []],
      unitsWithBedBugs: [0, []],
      pestControlEngaged: [false, []],
      unitsWithBedBugsComment: ['', []],
      pastDueRecert: ['', []],
      over90DaysDelinquent: ['', []],
      evictionsInProcess: ['', []],
      createdDate: ['', []],
      updatedDate: ['', []],
      createdByName: ['', []],
      updatedByName: ['', []],
    });

  }

  getDownloadURL = this.filesDialogComponent.getDownloadURL;
  
  deleteFile = this.filesDialogComponent.deleteFile;

  ngOnInit() {
    this.form.patchValue(this.report);
    this.form.setValidators(dateValidator());
    this.isAdd = !(this.report && this.report.id);
    this.dialogTitle = this.isAdd
      ? 'Add Report'
      : 'Update Report';
    this.userService.getMe().subscribe((res) => {
      this.form.get('userName')!.setValue(res.displayName);
    });
  }

 


  ngAfterViewInit(){
    //this.child.uploadFile();
  }

  public successFileUpload(file: any) {
    this.file = file;
  }

  save() {
    if (this.form.invalid) {
      this.isFailedForm = true;
      if(this.isAdd === true){
        this.showErrorMessage('Please add a file to start the process.');
      }
    } else {
      const formValue = this.form.getRawValue();
      let replacedData: any = {};
      Object.keys(formValue).forEach((key: string) => {
        formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
      })
      const report = Object.assign({}, this.report, replacedData);

      if(this.isAdd === true){
        this.reportsPEFService.add(report).subscribe((retunredReportId) => {
          var id = retunredReportId.toString();
          var propId = report.propertyId.toString();
          this.params = {};
          this.params.foreignId = id;
          this.params.propertyId = propId;
          this.params.panel = 'report';
          this.sendBackReport = report;
        });
      } else {
        if(this.file){
          this.reportsPEFService.update(report).subscribe((_res) =>{
            var id = report.id.toString();
            var propId = report.propertyId.toString();
            this.params = {};
            this.params.foreignId = id;
            this.params.propertyId = propId;
            this.params.panel = 'report';
            if(this.report.fileId > 0){
              this.params.fileId = this.report.fileId;
            }
            this.sendBackReport = report;
          });
        } else {
          this.activeModal.close(report);
        }
      }

    }
  }

  closeFormModal(){
    this.activeModal.close(this.sendBackReport);
  }

  close() {
    this.activeModal.dismiss();
  }

  isDisabled(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 1 || d.getDay() === 2 || d.getDay() === 3 || d.getDay() === 4 || d.getDay() === 5;
  }

  onDateChange(event: Date) {
    var nextFriday = new Date(event);
    //BFS: 1.02 - the '6' in the beginning is how many days you are adding (in this case adding 6 days to saturday will give you next friday)
    nextFriday.setTime(nextFriday.getTime() + (6 * 24 * 60 * 60 * 1000));
    //BFS: 1.02 - the endDate form input gets populated with nextFriday's date
    this.form.get('endDate')!.setValue(nextFriday);
  }

  unitsWithPestsSectionVisible(){
    var propertyID = this.form.value.propertyId;
    var startDate = this.form.value.startDate;
    if(propertyID && startDate){
      return true;
    } else {
      return false;
    }
  }

  unitsWithPestsCheckBoxEnabled(){
    var unitsWithBedBugs = this.form.value.unitsWithBedBugs;
    if(unitsWithBedBugs && unitsWithBedBugs != ''){
      return true;
    } else {
      return false;
    }
  }

  public showErrorMessage(message: string) {
    return this.dialogsService.error(message)
  }
}