import { Component, OnInit, Inject, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, ValidationErrors, FormArray, FormControl } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter, NgbModal, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Report } from '@app/core/models/report';
import { UserService } from '@app/users/user.service';
import { dateValidator, dateValidator2, dateValidatorAllowBlank } from '@app/shared/validators';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomDateParserFormatter } from '../building-add-dialog/building-add-dialog.component';
import { ReportsService } from '@app/reports/reports.service';
import { DialogsService } from '@app/core/services/dialogs.service';
import { AuthService } from "@app/core";
import { Role } from '@app/core/models/role';


@Component({
  selector: 'app-role-add-dialog',
  templateUrl: './role-add-dialog.component.html',
  styleUrls: ['./role-add-dialog.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
     DatePipe]
})
export class RoleAddDialogComponent implements OnInit {
  dialogTitle = '';
  isAdd = true;
  form: UntypedFormGroup;
  isAdmin = false;
  @Input()
  role!: Role;
  @Output()
  saveChange = new EventEmitter();
  model: any;
  public isFailedForm: boolean = false;
  repeatedForms: any;
  
  constructor(
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    public userService: UserService) {
    //
    this.form = this.fb.group({
        name: ['', Validators.required],
      
    });
  
  }

  ngOnInit() {
   
    if (this.role) {
      this.form.patchValue(this.role);
   
    }
   
    this.dialogTitle =  'Add Role' ;
    this.isAdmin = this.authService.isAuthUserInRole("Admin");
  }

  save() {
    const title = 'Add Role';
    if(this.isAdmin === true){
      if (this.form.invalid) {
        this.isFailedForm = true;
        } else {
        const formvalue = this.form.getRawValue();
        let replaceddata: any = {};
        Object.keys(formvalue).forEach((key: string) => {
          formvalue[key] == null ? replaceddata[key] = '' : replaceddata[key] = formvalue[key]
        })
       
        const report = Object.assign({}, this.role, replaceddata);
        
        this.activeModal.close(report);
      }
    } 
  }

  close() {
    this.activeModal.dismiss();
  }

 
  
}
