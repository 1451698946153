import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '@app/core';
import { NotificationSchedule } from '@app/core/models/notificationSchedule';

@Injectable({ providedIn: 'root' })
export class NotificationManagementDialogService {


  getNotificationRoles(propertyId:number) {
    return this.http.get<any[]>(`${AppConfig.apiEndpoint}/Roles/getNotificationRoles/${propertyId}`);
  }

  getNotificationIntervals() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/notifications/getNotificationIntervals`);
  }
    constructor(private http: HttpClient) { }


    getNotificationScheduleForProperty(propertyId:number) {
        return this.http.get<any>(`${AppConfig.apiEndpoint}/notifications/getNotificationScheduleByPropertyId/${propertyId}`);
      }


      getNotificationTypes() {
        return this.http.get<any>(`${AppConfig.apiEndpoint}/notifications/getNotificationTypes`);
      }
    
      saveNotificationSchedule(notificationSchedule:NotificationSchedule){
        return this.http.post(`${AppConfig.apiEndpoint}/notifications/saveNotificationSchedule`, notificationSchedule);
      }

      getNotificationLogsForProperty(propertyId:number) {
        return this.http.get<any>(`${AppConfig.apiEndpoint}/notifications/getNotificationLogs/${propertyId}`);
      }

}
