import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ChangePasswordComponent } from "./authentication/change-password/change-password.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { SidebarLayoutComponent } from "./core/layouts/sidebar-layout/sidebar-layout.component";
import { PageLayoutComponent } from "./core/layouts/page-layout/page-layout.component";
import { SSOAuthComponent } from "./authentication/ssoAuth/ssoAuth.component";
import { AuthGuardPermission } from "./core/models/auth-guard-permission";
import { AuthGuard } from "./core/services/auth.guard";


const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "dashboard", redirectTo: "users", pathMatch: "full" },
  
  {
    path: "changePassword", component: ChangePasswordComponent,
    // data: {
    //   permission: {
    //     permittedRoles: ["Admin", "User"]
    //   } as AuthGuardPermission
    // },
    // canActivate: [AuthGuard]
  },
  {
    path: "ssoauth", component: SSOAuthComponent},
  {
    path: "",
    component: SidebarLayoutComponent,
    children: [
      {
        path: "users",
        loadChildren: () => import('@app/users/users.module').then(m => m.UsersModule)
      },
      {
        path: "companies",
        loadChildren: () => import('@app/companies/companies.module').then(m => m.CompaniesModule)
      },
      {
        path: "properties",
        loadChildren: () => import('@app/properties/properties.module').then(m => m.PropertiesModule)
      },
      {
        path: "unitDetails",
        loadChildren: () => import('@app/unitDetails/unitDetails.module').then(m => m.UnitDetailsModule)
      },
      {
        path: "unitDetailsCPP",
        loadChildren: () => import('@app/unitDetailsCPP/unitDetailsCPP.module').then(m => m.UnitDetailsCPPModule)
      },
      {
        path: "reports",
        loadChildren: () => import('@app/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: "reportsPEF",
        loadChildren: () => import('@app/reportsPEF/reportsPEF.module').then(m => m.ReportsPEFModule)
      },
      {
        path: "preventiveMaintenance",
        loadChildren: () => import('@app/preventiveMaintenance/preventiveMaintenance.module').then(m => m.PreventiveMaintenanceModule)
      },
      {
        path: "roleManagement",
        loadChildren: () => import('@app/roleManagement/roleManagement.module').then(m => m.RoleManagementModule)
      },
      {
        path: "files",
        loadChildren: () => import('@app/files/files.module').then(m => m.FilesModule)
      },

    ], data: {
         permission: {
           permittedRoles: ["Admin", "User"]
         } as AuthGuardPermission
       },
       canActivate: [AuthGuard]
  },

  // Page Layout
  {
    path: "",
    component: PageLayoutComponent,
    children: [
      {
        path: "login",
        loadChildren: () => import('@app/authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  
  { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
