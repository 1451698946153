<div class="container">
  <div class="row">
    <div class="col-12 offset-md-3 col-md-6">
      <div class="card mt-5">
        <div class="card-header">
          <h2 class="card-title">Reset Password</h2>
        </div>
        <div class="card-body">
          <form [formGroup]="form" >
            <div class="form-row">

              <ng-template #popContent>
                  The password should contain:
                  <ul>
                      <li>At least 6 characters</li>
                      <li>At least one special character</li>
                      <li>At least one uppercase symbol</li>
                      <li>At least one number</li>
                  </ul>
              </ng-template>

              <div class="form-group col-md-12">
                <label class="control-label">New Password: <span [ngbPopover]="popContent" triggers="mouseenter:mouseleave" placement="right"><i class="fa fa-question-circle" aria-hidden="true"></i></span></label>
                <input class="form-control" formControlName="newPassword" type="password"
                  [class.error]="(isFailedForm ||form.get('newPassword')!.touched) && form.get('newPassword')!.invalid" />
                <span class="error-text"
                  *ngIf="(isFailedForm ||form.get('newPassword')!.touched) && form.get('newPassword')!.hasError('passLength')">
                  The new password should contain at least 6 characters</span>
                <span class="error-text"
                  *ngIf="(isFailedForm ||form.get('newPassword')!.touched) && form.get('newPassword')!.hasError('passNotSpecial')">
                  At least one special character is required for the new password</span>
                <span class="error-text"
                  *ngIf="(isFailedForm ||form.get('newPassword')!.touched) && form.get('newPassword')!.hasError('passNotUpper')">
                  At least one uppercase symbol is required for the new password</span>
                <span class="error-text"
                  *ngIf="(isFailedForm ||form.get('newPassword')!.touched) && form.get('newPassword')!.hasError('passNotNumber')">
                  At least one number is required for the new password</span>
              </div>

              <div class="form-group col-md-12">
                <label class="control-label">Confirm New Password:</label>
                <input class="form-control" formControlName="confirmPassword" type="password"
                  [class.error]="(isFailedForm ||form.get('confirmPassword')!.touched) && (form.get('confirmPassword')!.invalid || (form.get('confirmPassword')!.value != form.get('newPassword')!.value))" />
                <span class="error-text"
                  *ngIf="(isFailedForm ||form.get('confirmPassword')!.touched) && form.get('confirmPassword')!.hasError('required')">
                  This field is required</span>
                <span class="error-text"
                  *ngIf="(isFailedForm || form.get('confirmPassword')!.touched) && (form.get('confirmPassword')!.value != form.get('newPassword')!.value)">
                  New Password & Confirm New Password do not match.</span>
              </div>
              
            </div>
          </form>
          <button class="btn btn-primary" [disabled]="form.invalid" (click)="submitForm()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>