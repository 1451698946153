import { InjectionToken } from '@angular/core';
import { environment } from '@env/environment';

export let APP_CONFIG = new InjectionToken<string>("app.config");

export interface IAppConfig {
  apiEndpoint: string;
  apiSettingsPath: string;
}
//
export const AppConfig: IAppConfig = {
  //apiEndpoint: "https://epiqpmapi.wncinc.com/api",
  //apiEndpoint: "http://epiqpmapitest.wncinc.com/api",
  //apiEndpoint: "http://localhost:3381/api",
  apiEndpoint: environment.apiUrl,
  apiSettingsPath: "ApiSettings"
};