//import { CloseScrollStrategyConfig } from "@angular/cdk/overlay/typings/scroll/close-scroll-strategy";

export interface AuthUser {
  userId: string;
  userName: string;
  displayName: string;
  //entity: string;
  email: string;
  roles: string[] | null;
  entity: string[] | null;
}
