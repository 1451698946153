import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent implements OnInit {

  @Input()
  message: string = '';
  title: string = 'Error';
  constructor(
      public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    
  }
  
  save() {
      this.activeModal.close();
  }

  close() {
      this.activeModal.dismiss();
  }

}
